<template>
  <div class="bg-[#F4F4F4]">
    <div
      className="hero min-h-[60vh] md:min-h-[90vh] lg:min-h-[90vh] relative"
      :style="{ backgroundImage: 'url(' + eventsBanner + ')' }">
      <div class="w-[80%] absolute bottom-14">
        <div
          class="w-[87px] h-[17px] bg-[#161616] rounded-[15px] flex items-center justify-center">
          <h1 class="font-bold text-white text-[10px]">Mumbai</h1>
        </div>
        <h1
          class="text-[25px] md:text-[44px] lg:text-[44px] font-bold text-white">
          Mumbai Midnight Cycling - SoBo Circuit on 11th March 2023
        </h1>
        <div class="flex items-center justify-start gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 18 20"
            fill="none">
            <path
              d="M8 12V10H10V12H8ZM4 12V10H6V12H4ZM12 12V10H14V12H12ZM8 16V14H10V16H8ZM4 16V14H6V16H4ZM12 16V14H14V16H12ZM0 20V2H3V0H5V2H13V0H15V2H18V20H0ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z"
              fill="white" />
          </svg>
          <p class="text-[13px] text-white">Mar 03 - 04 | 10:30 PM(IST)</p>
        </div>
      </div>
    </div>
    <div class="p-4">
      <h1 class="text-[12px] text-[#696767] mb-2">
        Home / Events / Mumbai Midnight Cycling - SoBo Circuit on 11th March
        2023
      </h1>
      <div
        class="flex flex-col md:flex-row lg:flex-row items-start gap-6 p-0 md:p-6 lg:p-6 relative">
        <div
          class="flex md:flex-col lg:flex-col items-center gap-2 md:sticky lg:sticky top-24">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="46"
              viewBox="0 0 45 46"
              fill="none">
              <g clip-path="url(#clip0_5283_2974)">
                <path
                  d="M0.960352 22.4043C0.959297 26.2147 1.95492 29.9353 3.84809 33.2146L0.779297 44.4193L12.2459 41.4127C15.4174 43.1393 18.9708 44.0439 22.5818 44.0442H22.5913C34.5119 44.0442 44.2155 34.344 44.2206 22.4213C44.2229 16.6439 41.975 11.2112 37.8909 7.12396C33.8075 3.03704 28.3768 0.785107 22.5904 0.782471C10.6684 0.782471 0.965449 10.4821 0.960527 22.4043"
                  fill="url(#paint0_linear_5283_2974)" />
                <path
                  d="M0.188086 22.3973C0.186855 26.3449 1.21816 30.1985 3.17883 33.5953L0 45.2016L11.8777 42.0873C15.1504 43.8717 18.8351 44.8124 22.5846 44.8138H22.5942C34.9425 44.8138 44.9947 34.7648 45 22.4153C45.0021 16.4303 42.6734 10.8023 38.4434 6.56859C34.2128 2.33543 28.5878 0.00246094 22.5942 0C10.2438 0 0.193008 10.0477 0.188086 22.3973ZM7.26152 33.0103L6.81803 32.3063C4.95369 29.3419 3.96967 25.9163 3.97107 22.3988C3.97512 12.134 12.3289 3.78281 22.6013 3.78281C27.5759 3.78492 32.2509 5.72414 35.7673 9.24258C39.2834 12.7614 41.2182 17.4389 41.217 22.4139C41.2124 32.6786 32.8584 41.0309 22.5942 41.0309H22.5868C19.2447 41.0291 15.9669 40.1316 13.1084 38.4354L12.4281 38.032L5.37961 39.88L7.26152 33.0101V33.0103Z"
                  fill="url(#paint1_linear_5283_2974)" />
                <path
                  d="M16.9939 13.0338C16.5745 12.1016 16.1331 12.0828 15.7343 12.0665C15.4077 12.0524 15.0343 12.0535 14.6613 12.0535C14.288 12.0535 13.6813 12.1939 13.1686 12.7538C12.6553 13.3142 11.209 14.6684 11.209 17.4227C11.209 20.1772 13.2152 22.8391 13.4948 23.213C13.7749 23.5861 17.3678 29.4193 23.0582 31.6633C27.7874 33.5282 28.7498 33.1573 29.7762 33.0637C30.8028 32.9706 33.0887 31.7099 33.555 30.4026C34.0217 29.0955 34.0217 27.975 33.8818 27.7409C33.7419 27.5076 33.3685 27.3675 32.8086 27.0877C32.2486 26.8077 29.4962 25.4533 28.9831 25.2664C28.4698 25.0798 28.0966 24.9866 27.7233 25.5472C27.3499 26.1068 26.2778 27.3675 25.9511 27.7409C25.6246 28.1151 25.2978 28.1617 24.7382 27.8817C24.1779 27.6008 22.375 27.0104 20.2359 25.1033C18.5716 23.6194 17.448 21.7868 17.1214 21.2263C16.7948 20.6666 17.0864 20.3632 17.3671 20.0842C17.6187 19.8334 17.9272 19.4305 18.2074 19.1037C18.4865 18.7768 18.5797 18.5435 18.7663 18.1701C18.9532 17.7964 18.8597 17.4695 18.7199 17.1895C18.5797 16.9094 17.4918 14.1407 16.9939 13.0338Z"
                  fill="white" />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_5283_2974"
                  x1="2172.85"
                  y1="4364.46"
                  x2="2172.85"
                  y2="0.782471"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#1FAF38" />
                  <stop offset="1" stop-color="#60D669" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_5283_2974"
                  x1="2250"
                  y1="4520.16"
                  x2="2250"
                  y2="0"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F9F9F9" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <clipPath id="clip0_5283_2974">
                  <rect width="45" height="45.3516" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="46"
              viewBox="0 0 45 46"
              fill="none">
              <path
                d="M41.25 22.8516C41.25 12.5016 32.85 4.10156 22.5 4.10156C12.15 4.10156 3.75 12.5016 3.75 22.8516C3.75 31.9266 10.2 39.4828 18.75 41.2266V28.4766H15V22.8516H18.75V18.1641C18.75 14.5453 21.6938 11.6016 25.3125 11.6016H30V17.2266H26.25C25.2188 17.2266 24.375 18.0703 24.375 19.1016V22.8516H30V28.4766H24.375V41.5078C33.8438 40.5703 41.25 32.5828 41.25 22.8516Z"
                fill="#0058BF" />
            </svg>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="44"
              viewBox="0 0 39 44"
              fill="none">
              <g clip-path="url(#clip0_5283_2969)">
                <path
                  d="M5.92857 3.06592C2.93438 3.06592 0.5 5.50029 0.5 8.49449V35.6373C0.5 38.6315 2.93438 41.0659 5.92857 41.0659H33.0714C36.0656 41.0659 38.5 38.6315 38.5 35.6373V8.49449C38.5 5.50029 36.0656 3.06592 33.0714 3.06592H5.92857ZM31.129 10.1909L22.3246 20.2507L32.6812 33.9409H24.5723L18.2277 25.6369L10.9585 33.9409H6.92946L16.3446 23.1771L6.41205 10.1909H14.7246L20.467 17.7824L27.1 10.1909H31.129ZM27.9228 31.532L13.5116 12.4726H11.1112L25.6835 31.532H27.9143H27.9228Z"
                  fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_5283_2969">
                  <rect
                    width="38"
                    height="43.4286"
                    fill="white"
                    transform="translate(0.5 0.351562)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none">
              <path
                d="M36.75 1.71743H4.25C3.5126 1.70992 2.80233 1.99526 2.27506 2.51081C1.74778 3.02637 1.44657 3.73004 1.4375 4.46743V37.0987C1.44821 37.835 1.75014 38.537 2.27724 39.0513C2.80434 39.5655 3.51367 39.8499 4.25 39.8424H36.75C37.4874 39.8483 38.1972 39.562 38.7242 39.0461C39.2512 38.5302 39.5526 37.8267 39.5625 37.0893V4.45805C39.5493 3.72283 39.2465 3.02251 38.7199 2.50932C38.1932 1.99613 37.4853 1.71155 36.75 1.71743Z"
                fill="#0076B2" />
              <path
                d="M7.08086 16.0081H12.7402V34.2174H7.08086V16.0081ZM9.91211 6.94556C10.5612 6.94556 11.1957 7.13808 11.7354 7.49876C12.2751 7.85945 12.6956 8.3721 12.9439 8.97185C13.1921 9.5716 13.2569 10.2315 13.1301 10.8681C13.0032 11.5047 12.6904 12.0893 12.2312 12.5481C11.772 13.0069 11.187 13.3191 10.5503 13.4454C9.91363 13.5716 9.25378 13.5062 8.65426 13.2574C8.05475 13.0086 7.5425 12.5875 7.18233 12.0475C6.82216 11.5075 6.63024 10.8728 6.63086 10.2237C6.63169 9.35398 6.97776 8.52019 7.59302 7.90551C8.20828 7.29083 9.04241 6.94556 9.91211 6.94556ZM16.2902 16.0081H21.7152V18.5081H21.7902C22.5465 17.0768 24.3902 15.5674 27.1434 15.5674C32.8746 15.5549 33.9371 19.3268 33.9371 24.2174V34.2174H28.2777V25.3581C28.2777 23.2487 28.2402 20.5331 25.3371 20.5331C22.434 20.5331 21.9402 22.8331 21.9402 25.2206V34.2174H16.2902V16.0081Z"
                fill="white" />
            </svg>
          </div>
        </div>
        <div>
          <h1 class="text-[16px]">
            There is a nice little paradox in the world cycling: ‘Cycling is
            good for weight loss, but cycling can be harmful for overweight
            people!’
            <br />
            <br />
            As a fat cyclist who’s done a solo ride from Pune to Goa while
            weighing over 100 kg and has lost 15 kilos over a couple of years of
            cycling, I’m here to squash this misconception. Forget that lanky
            kid you’ve seen zooming past vehicles in his fitting nylon kit,
            cycling is for all shapes and sizes. <br />
            <br />
            Here are 5 reasons why cycling is great for overweight people:
            <br /><br />
            <span class="font-bold">1. It is low impact</span><br />
            <br />
            Stay with me for some boring science stuff. Running and jumping
            place a high impact on your joints, especially if you’re already
            heavy. Cycling gives you a similar calorie burn while you’re sitting
            comfortably. Ok, maybe not as comfy as a couch, but you get the
            point! All the work comes from your muscles. And not just your legs,
            as I painfully learned when my first long ride that ended with sore
            triceps. Legs propel you forward, core gives you power, upper body
            helps you maneuver, and that alert brain helps you dodge the crazy
            rickshaw approaching you from the wrong side. It is truly a full
            body sport. <br /><br />
            <span class="font-bold"> 2. You’ll find your tribe</span
            ><br /><br />
            Do social occasions mean drinking with colleagues or eating junk
            food with friends? Why not have a party on two wheels instead? My
            wife and I have replaced our pizza dates with Sunday morning cycle
            rides. If you don’t have friends, family or a special one who wants
            to go for a ride – don’t worry, cycling is a very welcoming
            community. Ride the same route a couple of times and you’ll surely
            be added to Whatsapp groups, get invited for cycle tours, or find a
            group that does weekend long rides. While these can be chatty and
            fun, being around experienced cyclists is a bonus. You’ll get tips
            about stretching, strength training, dieting and new routes!
            Warning: These long rides do come with mid-ride junk food, but oh my
            god, a hot, crispy vada pav tastes so much better after an hour of
            cycling!<br /><br />
            <span class="font-bold"> 3. You have zero pressure</span>
            <br /><br />
            Do you like to ride solo? With a fast group who challenges you with
            their speed, or a slower group who prefer to soak in the experience?
            Short loops or long tours? Hilly or flat? Off road or highways? You
            have the power to make these choices! Saying “cycling isn’t for me”,
            is like saying “Subway isn’t for me.” Cycling is for you, because
            you can MAKE it for you. Choose your own
            <span class="underline"
              >adventure and enjoy the cycling in india.</span
            >
            <br /><br />
            <span class="font-bold">4. It’ll help you lose weight</span>
            <br /><br />
            you burn a lot of calories without impacting your joints, what’s not
            to like? Still worried about being too heavy? Alright, story time:On
            the outskirts of Mumbai is a challenging cycling heaven – the steep
            Yeoor hills. Every morning, you’ll find one of the most respected
            members of the Thane cycling community – Mr Ambar Joshi riding
            there. After an illustrious power lifting career, Mr Joshi had an
            accident that drastically changed his life. At one point during his
            recovery, he weighed 150 kilos. If anyone could say “I’m too heavy
            to cycle” it’d be him. Couple of years ago, he decided to take up
            cycling. Today, he’s 36 kgs lighter and climbs up Yeoor hill 7
            times, all the while looking relaxed as ever! What’s your excuse?
            <br /><br />
            <span class="font-bold"> 5. It brings you joy!</span>
            <br /><br />This is the most important point. Struggling with
            weight, hormonal issues or eating disorders can get stressful.
            Sometimes, you just need to escape the troubles of life. What better
            way to do that than on two wheels, with the wind in your face,
            knowing that your own strength has powered you beyond your
            limitations? Think about the confidence that brings. Actually,
            enough thinking. Go buy that cycle, find that cycling group,
            register for a cycling tour and start your cycling journey today!<br /><br />
            I hope I’ve managed to convince you with these reasons. And while
            you’re motivated, all I can think of is that hot, crispy vada pav.
            Oh well, now I know the destination for tomorrow’s ride!
          </h1>
        </div>
        <div>
          <h1 class="text-[20px] font-bold">Featured</h1>
          <div class="bg-[#36314C] w-full h-[0.5px] mt-2 mb-4"></div>
          <div
            v-for="blog in blogs"
            @click="$router.push({ name: 'BlogDetails' })"
            class="p-2 mb-[10px] w-full">
            <div class="flex gap-4">
              <div class="w-[170px] h-[70px]">
                <img
                  :src="blog.img"
                  class="rounded-[6px] h-full w-full object-cover"
                  alt=""
                  srcset="" />
              </div>
              <div class="flex flex-col justify-between">
                <h1 class="text-[12px] link-hover cursor-pointer font-semibold">
                  {{ blog.title }}
                </h1>
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 25 25"
                      fill="none">
                      <path
                        d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                        fill="#36314C" />
                    </svg>
                    <p class="text-[10px]">May 27,2023</p>
                  </div>
                  <div class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 25 25"
                      fill="none">
                      <path
                        d="M12.4902 2.08325C6.74023 2.08325 2.08398 6.74992 2.08398 12.4999C2.08398 18.2499 6.74023 22.9166 12.4902 22.9166C18.2507 22.9166 22.9173 18.2499 22.9173 12.4999C22.9173 6.74992 18.2507 2.08325 12.4902 2.08325ZM12.5007 20.8333C7.89648 20.8333 4.16732 17.1041 4.16732 12.4999C4.16732 7.89575 7.89648 4.16659 12.5007 4.16659C17.1048 4.16659 20.834 7.89575 20.834 12.4999C20.834 17.1041 17.1048 20.8333 12.5007 20.8333Z"
                        fill="#36314C" />
                      <path
                        d="M13.0215 7.29175H11.459V13.5417L16.9277 16.823L17.709 15.5417L13.0215 12.7605V7.29175Z"
                        fill="#36314C" />
                    </svg>
                    <p class="text-[10px]">7 Minutes</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="bg-[#36314C] w-full h-[0.5px] mt-2"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogImg from "../assets/HomeView/sliderImg.jpg";
import eventsBanner from "../assets/HomeView/eventsBanner.jpg";
import cardImg1 from "../assets/HomeView/cardImg1.jpg";
import cardImg3 from "../assets/HomeView/cardImg3.jpg";

export default {
  data() {
    return {
      blogImg,
      eventsBanner,
      blogs: [
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg1,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: eventsBanner,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: blogImg,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg3,
        },
      ],
    };
  },
};
</script>
