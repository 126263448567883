<template>
  <div class="my-10">
    <h1
      class="text-4xl md:text-7xl lg:text-7xl font-medium text-center uppercase">
      Privacy policy
    </h1>
    <div
      class="flex flex-col-reverse md:flex-row lg:flex-row p-8 md:p-24 lg:p-24">
      <div class="w-full md:w-[70%] lg:w-[70%] lg:pr-[8.5rem]">
        <div id="cycleDepo">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            1. Who is CYCLEDEPO?
          </h2>
          <p class="break-words">
            CYCLEDEPO, having its office at A-304, Eastern Business District,
            LBS Marg, Bhandup- West, Mumbai – 400 078, Maharashtra, India (“the
            Company”) offers various modes of bicycles on rent including through
            its website www.cycleonrent.com and other applications
            (“Platforms”), which inter alia facilitates the sale and purchase of
            products related to bicycle and bicycle merchandise (“Products”) by
            users of the Platforms (“Users”).
          </p>
        </div>
        <div id="depoPrivacy" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            2. What is this Privacy Policy?
          </h2>
          <p class="break-words">
            This privacy policy (the “Privacy Policy”), together with the terms
            of use, describes the Company’s policies and procedures on the
            collection, use, and disclosure of the information provided by Users
            and Visitors (as defined herein below) of the Platforms. The Company
            shall not use the User’s information in any manner except as
            provided under this Privacy Policy. Every User who accesses or uses
            the Platforms shall be bound by this Privacy Policy.
          </p>
        </div>
        <div id="privacyPolicy" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            3. Why this Privacy Policy?
          </h2>
          <p>
            <span class="font-medium">3.1.</span> This Privacy Policy is
            published pursuant to:
          </p>
          <p>
            <span class="font-medium">3.1.1.</span> Section 43A of the
            Information Technology Act, 2000;
          </p>
          <p>
            <span class="font-medium">3.1.2.</span> Regulation 4 of the
            Information Technology (Reasonable Security Practices and Procedures
            and Sensitive Personal Information) Rules, 2011 (“SPI Rules”); and
          </p>
          <p>
            <span class="font-medium">3.1.3.</span> Regulation 3(1) of the
            Information Technology (Intermediaries Guidelines) Rules, 2011.
          </p>
          <p class="break-words">
            <span class="font-medium">3.2</span> This Privacy Policy sets out
            the type of information collected from the Users, including the
            nature of the sensitive personal data or information, the purpose,
            means and modes of usage, and how and to whom the Company shall
            disclose such information.
          </p>
        </div>
        <div id="informationType" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            4. What type of information is covered by this Privacy Policy?
          </h2>
          <p>
            The Privacy Policy applies to information collected and processed by
            the Company consisting of the following:
          </p>
          <p class="break-words mt-3">
            <span class="font-medium">4.1</span> Personal information is
            information related to a visitor or a combination of pieces of
            information that could reasonably allow him to be identified.
            Personal information may consist of full name, personal contact
            numbers, residential address, email address, gender, or date of
            birth. While information such as date of birth in isolation may not
            be enough to uniquely identify the visitor, a combination of full
            name and date of birth may be sufficient to do so.
          </p>
          <p class="break-words my-3">
            <span class="font-medium">4.2.</span> Sensitive personal data or
            information is such personal information that is collected,
            received, stored, transmitted or processed by the Company,
            consisting of:
          </p>
          <p><span class="font-extrabold">•</span> Password;</p>
          <p>
            <span class="font-extrabold">•</span> Financial information such as
            bank account credit card debit card or other payment instrument
            details;
          </p>
          <p>
            <span class="font-extrabold">•</span> Physical, physiological, and
            mental health conditions;
          </p>
          <p><span class="font-extrabold">•</span> Sexual orientation;</p>
          <p>
            <span class="font-extrabold">•</span> Medical records and history;
          </p>
          <p><span class="font-extrabold">•</span> Biometric information;</p>
          <p>
            <span class="font-extrabold">•</span> Any detail relating to the
            above personal information categories as provided to the Company for
            providing service; and
          </p>
          <p>
            <span class="font-extrabold">•</span> Any information received under
            the above personal information categories by the Company for
            processing, storing, or processing under lawful contract or
            otherwise.
          </p>
          <p class="break-words mt-2">
            Please note that any information that is freely available or
            accessible in the public domain or furnished under the Right to
            Information Act, 2005, or any other law for the time being in force
            shall not be regarded as sensitive personal information.
          </p>
        </div>
        <div id="userNote" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">5. Users Note</h2>
          <p class="break-words">
            <span class="font-medium">5.1.</span> A condition of each User’s use
            of and access to the Platforms and to other services provided by the
            Company to Users (collectively referred to as the “Services”), is
            his/her/its acceptance of the terms of use which also involves
            acceptance of the terms of this Privacy Policy. Any User who does
            not agree with any provisions of the Terms of Use or this Privacy
            Policy is advised not to accept the Terms of Use and may leave the
            Platforms.
          </p>
          <p class="break-words my-4">
            <span class="font-medium">5.2.</span> While browsing the Platforms,
            a User is not required to provide personal information as set out
            under paragraph 4.1 and paragraph 4.2 until and unless such User
            chooses to avail or sign up for any of the Services. All the
            information provided to the Company by a User, including personal
            information and personally identifiable information, is voluntary.
            The User has the right to request the withdrawal of his/her/its
            consent at any time, in accordance with the terms of this Privacy
            Policy and the Terms of Use. It is the User’s duty to ensure strict
            caution while giving out any personally identifiable information
            about himself/herself/itself or his/her family members in use of any
            of the Services. The Company does not endorse the content, messages
            or information found in any Services and therefore, the Company
            specifically disclaims any liability with regard to the Services and
            any actions resulting from the User’s participation in any Services.
            As a condition to use the Services, you as a User agree to waive any
            claims against the Company relating to the same, and to the extent
            such waiver may be ineffective, you agree to release any claims
            against the Company relating to the same.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.3.</span> Users can access, modify,
            correct and eliminate the data about him/her/it which has been
            collected pursuant to his/her/its decision to become a User. Any
            grievances in relation to the information shared by the User with
            the Company may be brought to the attention of grievance officer in
            accordance with clause 8 of this Privacy Policy. caution while
            giving out any personally identifiable information about
            himself/herself/itself or his/her family members in use of any of
            the Services. The Company does not endorse the content, messages or
            information found in any Services and therefore, the Company
            specifically disclaims any liability with regard to the Services and
            any actions resulting from the User’s participation in any Services.
            As a condition to use the Services, you as a User agree to waive any
            claims against the Company relating to the same, and to the extent
            such waiver may be ineffective, you agree to release any claims
            against the Company relating to the same.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.4.</span> For the use of the Services
            and purchase of Products, you may be required to pay the Company
            with a credit card, wire transfer, or debit card through the
            Company’s third-party payment gateway provider and such third-party
            payment gateway provider may be required to collect certain
            financial information from you including, but not restricted to,
            your credit/debit card number or your bank account details
            (collectively referred to as “Financial Information”). All Financial
            Information collected from the Users by the Company’s third-party
            payment gateway providers will be used only for billing and payment
            processes. The verification of the Financial Information shall be
            accomplished only by the User through a process of authentication in
            which the Company shall have no role to play.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.5.</span> Personal information,
            personally identifiable information and/or Financial Information
            shall be collected by the Company on an ongoing basis, without need
            for further, separate consent from the User (aside from the
            acceptance of this Privacy Policy, as provided in paragraph 5.1) for
            one or more of the following reasons:
          </p>
          <p class="mt-4">
            <span class="font-medium">5.5.1.</span> To identify the User, to
            understand his/her/its needs and resolve disputes, if any;
          </p>
          <p>
            <span class="font-medium">5.5.2.</span> To set up, manage, and offer
            products and to enhance the Services to meet the User’s
            requirements;
          </p>
          <p>
            <span class="font-medium">5.5.3.</span> To provide ongoing service;
          </p>
          <p>
            <span class="font-medium">5.5.4.</span> To meet legal and regulatory
            requirements;
          </p>
          <p>
            <span class="font-medium">5.5.5.</span> To resolve technical issues
            and troubleshoot problems;
          </p>
          <p>
            <span class="font-medium">5.5.6.</span> To aid the Company in
            collecting monies from Users for transactions carried out on the
            Platforms;
          </p>
          <p>
            <span class="font-medium">5.5.7.</span> To keep Users apprised of
            the Company’s (or third parties) promotions and offers;
          </p>
          <p>
            <span class="font-medium">5.5.8.</span> To customize User
            experience;
          </p>
          <p>
            <span class="font-medium">5.5.9.</span> To detect and protect the
            Company from error, fraud, and other criminal activities;
          </p>
          <p>
            <span class="font-medium">5.5.10.</span> To enforce the Terms of
            Use; and
          </p>
          <p>
            <span class="font-medium">5.5.11.</span> Other reasons which, prior
            to being put into effect, shall be communicated to the Users through
            an update carried out to this Privacy Policy.
          </p>
          <p class="break-words my-4">
            <span class="font-medium">5.6.</span> The Financial Information
            collected from the Users is transacted through secure digital
            platforms of approved payment gateways which are under encryption,
            thereby complying with reasonably expected technology standards.
            While the Company shall make reasonable endeavors to ensure that the
            User’s personal information and the Financial Information is duly
            protected by undertaking security measures prescribed under
            applicable laws, the User is strongly advised to exercise discretion
            while providing personal information or Financial Information while
            using the Services given that the Internet is susceptible to
            security breaches.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.7.</span> Due to the communications
            standards on the Internet, when a User visits the Platforms, the
            Company automatically receives the uniform resource locator of the
            site from which such User visits the Platforms, details of the
            website such User is visiting on leaving the Platforms, the internet
            protocol (“IP”) address of each User’s computer operating system,
            type of web browser the User is using, email patterns, and the name
            of the User’s internet service provider. This information is used
            solely to analyze overall User trends and to help the Company in
            improving its Services. Please note that the link between the User’s
            IP address and the User’s personally identifiable information is not
            shared with third parties without such User’s permission or except
            when required by law or to provide or facilitate the User with the
            Services. Notwithstanding the above, the User acknowledges the
            Company’s right to share some of the aggregate findings, including
            the personal information provided by the Users in an unidentifiable
            form, and not the specific data with advertisers, sponsors,
            investors, strategic partners, and others in order to help grow the
            business. The amount of information sent to the Company depends on
            the settings of the web browser used by the User to access the
            Platforms. The User may refer to the browser used, if the User
            wishes to learn what information is provided to the Company.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.8.</span> The Platforms use temporary
            cookies to store certain data. The Company does not store personally
            identifiable information in the cookies. Information collected by
            the Company, by any means whatsoever, that does not personally
            identify the User as an individual (such as patterns of utilization
            described above) is exclusively owned by the Company and may be used
            by the Company and third-party service providers for technical
            administration of the Platforms, user administration, research,
            development, and other purposes. Additionally, the Company may sell
            or otherwise transfer such research, statistical, or intelligence
            data in an aggregated or non-personally identifiable form to its
            parent company, group companies, subsidiaries, associates,
            affiliates, suppliers, vendors, sister concerns, service providers,
            and service partners and other third parties (collectively referred
            to as “Other Parties”).
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.9.</span> A User may set or amend
            his/her/its web browsers to delete or disable cookies. If a User
            chooses to disable cookies on his/her/its computer or mobile
            telecommunication device, it may impair, degrade, or restrict access
            to certain areas of the Platforms. Merely closing the web browser
            should ordinarily clear all temporary cookies installed by the
            Company. However, Users are encouraged to use the “clear cookies”
            functionality of their browsers to ensure deletion, as the Company
            cannot guarantee, predict, or provide for the behavior of the
            equipment of all the Users of the Platforms.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.10</span> The Company may allow other
            companies or entities to serve advertisements to Users. These
            companies or entities include third-party advertisement servers,
            advertisement agencies, advertisement technology vendors, and
            research firms. The Company may target some advertisements to Users
            that fit a certain general profile. The Company does not use
            personally identifiable information to target advertisements to
            specific Users. In the course of serving advertisements or
            optimizing the Services to its Users, the Company may allow
            authorized third parties to place or recognize a unique cookie on
            the User’s browser.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.11.</span> The Company does not exercise
            control over the websites displayed as search results or links from
            within the Services. These other sites may place their own cookies
            or other files on the Users’ computer, collect data or solicit
            personal information or Financial Information from the Users, for
            which the Company shall not be held responsible or liable. The
            Company does not make any representations concerning the privacy
            practices or policies of such third parties or terms of use of such
            websites, nor does the Company guarantee the accuracy, integrity, or
            quality of the information, data, text, software, sound,
            photographs, graphics, videos, messages or other materials available
            on such websites. The inclusion or exclusion does not imply any
            endorsement by the Company of such websites, the websites’ provider,
            or the information on the website.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.12.</span> When a User visits or uses
            the Platforms, the Company may access, collect, monitor and/or
            remotely store data in relation to the User’s location, which may
            also include global positioning system coordinates or similar
            information regarding the location of the device using which the
            User has visited or used the Platforms. The location data does not
            collect or share any personally identifiable information about the
            User.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.13.</span> The Company may keep records
            of telephone calls received from and made to Users for the purpose
            of administration of Services, research and development, training,
            business intelligence, business development, or for User
            administration. The Company may share the telephone records with
            third parties when required by law or when required to provide or
            facilitate the User with the Services.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.14.</span> The Company may choose to
            conduct contests and surveys to collect relevant information about
            the Users’ preferences. These surveys and contests are optional and
            if the User chooses to respond, his/her/its responses will be kept
            anonymous. The demographic information that the User provides while
            availing or signing-up for the Services and through any surveys or
            contests is used to help the Company improve its services to meet
            the needs and preferences of Users.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.15.</span> The Company does not
            knowingly collect personal data from children (only persons above
            the aged of 18 shall be permitted to use the Services as provided in
            the Terms of Use). In an event, where in contravention of the Terms
            of Use, a person below the age of 18 uses the Services, the Company
            shall not be held liable or responsible for any damage or injury
            suffered by such person in logging into the Platforms and making use
            of the Services.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.16.</span> The Company has implemented
            security policies, rules and technical measures, as required under
            applicable law including firewalls, transport layer security and
            other physical and electronic security measures to protect the
            Financial Information and personal information that it has under its
            control from unauthorized access, improper use or disclosure,
            unauthorized modification and unlawful destruction or accidental
            loss. It is expressly stated that the Company shall not be
            responsible for any breach of security or for any action of any
            third parties that receive Users’ personal data or events that are
            beyond the reasonable control of the Company including, acts of
            government, computer hacking, unauthorized access to computer data
            and storage device, computer crashes, breach of security and
            encryption, etc.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.17.</span> The Company may be required
            to disclose personal information or Financial Information to
            governmental institutions or authorities when such disclosure is
            requisitioned under any law or judicial decree or when the Company,
            in its sole discretion, deems it necessary in order to protect its
            rights or the rights of others, to prevent harm to persons or
            property, to fight fraud and credit risk, or to enforce or apply the
            Terms of Use.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.18.</span> The Company may share/use
            personal information and personally identifiable information
            provided by Users with the Other Parties for the purposes of:
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.18.1.</span> enabling Users to enjoy the
            Services and to use the Products, including sharing information with
            Other Parties to provide services, technical support, etc. in
            relation to the Products; or
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.18.2.</span> detecting and preventing
            identity theft, fraud, or any other potentially illegal acts; or
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.18.3.</span> monitoring and enhancing
            User interest and engagement, including through promotional
            activity, personal messages to Users using personally identifiable
            information provided by Users, etc.; or
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.18.4.</span> processing the purchase of
            Products on the Platforms. To the extent that Other Parties have
            access to the personal information, the Company shall make efforts
            to ensure that the Other Parties treat such personal information at
            least as protectively as they treat personally identifiable
            information obtained from their users or members.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.19.</span> The Company or the Other
            Parties may merge with or be acquired by another business entity. In
            such an event, the Company and the Other Parties may be required to
            transfer the personal information to such merging or acquiring
            party, as the case may be. While sharing such personal information
            with the acquiring or merging entity, as the case may be, the
            Company and the Other Parties shall make reasonable endeavors to
            ensure that the User’s personal information is duly protected by the
            acquiring or merging entity, as the case may be, by undertaking
            security measures prescribed under applicable laws.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.20.</span> The Company may also disclose
            or transfer the personal and other information provided by Users, to
            any third party as a part of reorganization or a sale of the assets,
            division or transfer of a part or whole of the company. Any third
            party to which the Company transfers or sells its assets will have
            the right to continue to use the personal and other information that
            Users provide to the Company.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.21.</span> All the Company employees and
            data processors, who have access to and are associated with the
            processing of personal information or Financial Information provided
            by Users are obliged to respect the confidentiality of every User’s
            personal information or Financial Information.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.22.</span> The User’s visit to and/or
            use of the Platforms and any dispute over privacy is subject to this
            Privacy Policy and the Terms of Use. The Company may update this
            Privacy Policy at any time, with or without advance notice. The
            Company shall not be required to notify the Users of any changes
            made to this Privacy Policy. It is your responsibility, in such
            cases, to review the terms of this Privacy Policy from time to time.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.23.</span> All information collected
            from the Users by the Company is maintained in electronic form on
            servers and/or cloud systems and shall be accessible by certain
            employees of the Company. The User information may also be converted
            to physical form from time to time. Regardless of the manner of
            storage, the Company shall make commercially reasonable endeavors to
            ensure that the User information is rendered confidential and will
            disclose User information only in accordance with the terms of this
            Privacy Policy.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">5.24.</span> The User consents to the
            Company reproducing/publishing all testimonials and reviews given by
            the User (whether on the Website, social media platforms, or in any
            other manner whatsoever) in relation to the Company or any of the
            Products, together with the User’s name and location, on the
            Website, on such page and in such position as the Company may
            determine in its sole discretion. The User agrees that the Company
            may edit the testimonials and reviews provided by the User and
            reproduce/publish such edited or paraphrased versions of the
            testimonials and reviews on the Website. If the User has any
            concerns with the reproduction/publication of any testimonial or
            review provided by the User on the Website, the User may contact the
            Company at <span class="font-medium">contact@cycledepo.com</span>
          </p>
        </div>
        <div id="visitorNote" class="mt-8">
          <h2 class="mb-6 text-2xl md:text-3xl font-medium">
            6. Visitors Note
          </h2>
          <p class="break-words mb-4">
            <span class="font-medium">6.1.</span> No personal information or
            Financial Information is automatically collected from any visitors
            of the Platforms who are merely perusing or browsing the Platforms
            (“Visitor”). Nevertheless, the provisions of this Privacy Policy are
            applicable to Visitors, and Visitors are required to read,
            understand, and accept the privacy statements
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">6.2.</span> A User will not merely be a
            Visitor if the User has willingly submitted any personal information
            or Financial Information (including phone numbers, email addresses,
            responses to surveys, etc.) to the Company through any means,
            including email, telephone calls, telephonic messaging or while
            availing or signing-up for the Services. All such Visitors will be
            deemed to be and will be treated as, Users for the purposes of this
            Privacy Policy, and in which case, all the statements in this
            Privacy Policy shall apply to the User.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">6.3.</span> If you, as a Visitor, have
            inadvertently browsed any other pages of the Platforms prior to
            reading the privacy statements set out herein, and you do not agree
            with the manner in which such information is obtained, stored or
            used, merely quitting the Platforms should ordinarily clear all
            temporary cookies installed by the Company. All Visitors, however,
            are encouraged to use the “clear cookies” functionality on their
            browsers to ensure such clearing or deletion, as the Company cannot
            guarantee, predict, or provide for the behavior of the equipment of
            all the Visitors of the Platforms.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">6.4.</span> If you are accessing the
            Platforms from outside India, it is solely your responsibility to
            ensure that your access does not breach or violate any local or
            national law applicable in the place from where you are making the
            access, for the time being in force.
          </p>
        </div>
        <div id="otpPolicy" class="mt-8">
          <h2 class="mb-6 text-2xl md:text-3xl font-medium">
            7. Opt-Out Policy
          </h2>
          <p class="break-words mb-4">
            <span class="font-medium">7.1.</span> The third party service
            providers with whom the Company may share personal information or
            Financial Information provided by Users are not permitted to market
            their own services or send promotional e-mails or engage in
            promotional communication with the Users. The Company provides all
            Users with the opportunity to opt-out of receiving non-essential,
            promotional, or marketing-related communication from itself or its
            partners. These settings can be found on the Platforms.
          </p>
          <p class="break-words mb-4">
            <span class="font-medium">7.2.</span> If a User wishes to remove
            his/her/its contact information from all the Company’s lists and
            newsletters, the User can click on the “unsubscribe” link or follow
            the instructions in each e- mail message. Alternatively, the User
            can contact the Company at contact@cycleonrent.com. The Company
            reserves the right to limit membership based on the availability of
            contact information. All Users will be notified by email prior to
            any actions taken.
          </p>
        </div>
        <div id="feedback" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            8. Feedback or Concern
          </h2>
          <p class="break-words">
            For feedback or concern, if any, kindly contact us at : Email
            Address: <span class="font-semibold">contact@cycledepo.com</span>
          </p>
        </div>
      </div>
      <div class="w-full md:w-[30%] lg:w-[30%] mb-6">
        <div class="md:sticky md:top-[7.5rem] lg:sticky lg:top-[7.5rem]">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            Table of contents
          </h2>
          <button
            @click="scrollToCycleDepo('cycleDepo')"
            class="text-lg font-medium">
            1. Who is CYCLE DEPO?
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('depoPrivacy')"
            class="text-lg font-medium">
            2. What is this Privacy Policy?
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('privacyPolicy')"
            class="text-lg font-medium">
            3. Why this Privacy Policy?</button
          ><br />
          <h1
            @click="scrollToCycleDepo('informationType')"
            class="text-lg font-medium cursor-pointer">
            4. What type of information is covered by this Privacy Policy?
          </h1>
          <button
            @click="scrollToCycleDepo('userNote')"
            class="text-lg font-medium">
            5. Users Note</button
          ><br />
          <button
            @click="scrollToCycleDepo('visitorNote')"
            class="text-lg font-medium">
            6. Visitors Note</button
          ><br />
          <button
            @click="scrollToCycleDepo('otpPolicy')"
            class="text-lg font-medium">
            7. Opt-Out Policy</button
          ><br />
          <button
            @click="scrollToCycleDepo('feedback')"
            class="text-lg font-medium">
            8. Feedback or Concern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToCycleDepo(elementId) {
      const cycleDepoDiv = document.getElementById(elementId);
      if (cycleDepoDiv) {
        cycleDepoDiv.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
