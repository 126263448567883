<template>
  <header class="block md:hidden lg:hidden">
    <div class="bg-[#36314C] p-3 flex gap-6 items-center">
      <img :src="backMbl" @click="$router.push({ name: 'Profile' })" />
      <h1 class="text-[20px] font-semibold text-white">Address Book</h1>
    </div>
  </header>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else class="bg-[#F4F4F4] p-4 lg:px-40">
    <div class="flex items-center justify-between">
      <div>
        <h1 class="text-[36px] font-semibold hidden md:block lg:block">
          Address Book
        </h1>
      </div>
      <div class="hidden md:block lg:block">
        <div
          @click="$router.push({ name: 'Profile' })"
          class="flex text-[12px] md:text-[26px] lg:text-[26px] font-bold cursor-pointer items-center gap-1">
          <img :src="backArrow" />
          <h1 class="font-bold text-[#FF4A49]">Back to my account</h1>
        </div>
      </div>
    </div>
    <div class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
      <div class="flex md:items-center lg:items-center justify-between">
        <h1 class="text-[22px] md:text-[28px] lg:text-[28px] font-extrabold">
          My Address Book
        </h1>
        <button
          @click="$router.push({ name: 'AddNewAddress' })"
          class="w-[120px] mt-10 h-[44px] md:w-[240px] md:h-[60px] lg:w-[240px] lg:h-[60px] text-center font-medium text-[17px] md:text-[30px] lg:text-[30px] text-white bg-[#FF4A49] border border-transparent rounded-lg hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
          Add Address
        </button>
      </div>
      <div
        v-if="userAddress.defaultAddress.length > 0"
        class="flex mt-4 items-center text-[20px] md:-[text-24px] lg:text-[24px] font-medium gap-2">
        <img :src="defaultAdd" />
        <h1>Default Address</h1>
      </div>
      <div
        v-if="userAddress.defaultAddress.length > 0"
        v-for="(address, i) in userAddress.defaultAddress"
        :key="i"
        class="border rounded-lg w-full md:w-[370px] lg:w-[470px] lg:h-[292px] p-[15px] md:p-[30px] lg:p-[30px] mt-6">
        <div class="flex justify-between">
          <div>
            <h1 class="font-bold text-[22px] mb-[15px]">
              {{ address.contact_name }}
            </h1>
            <p class="text-[20px] leading-5 mb-[20px]">
              {{ address.landmark }}, {{ address.address_line_1 }},
              {{ address.address_line_2 }}
              <br />
              {{ address.city }}, {{ address.pincode }} <br />
              {{ address.state }}
            </p>
          </div>
          <div>
            <div
              class="bg-[#EAEAF3] text-center text-[#867EA8] w-[82px] h-[25px] rounded-xl capitalize">
              {{ address.type }}
            </div>
          </div>
        </div>

        <h2 class="font-bold text-[20px]">
          Mobile: {{ address.contact_number }}
        </h2>

        <div class="flex gap-2 items-center mt-8 text-[#FF4A49]">
          <div
            @click="addressRemove(address.id)"
            class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
            <img :src="removeIcon" />
            <p>Remove</p>
          </div>
          <router-link
            :to="'/address-update/' + address.id"
            class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
            <img :src="editIcon" />
            <p>Edit</p>
          </router-link>
        </div>
      </div>
      <div
        v-if="userAddress.otherAddress.length > 0"
        class="flex mt-8 items-center text-[20px] md:-[text-24px] lg:text-[24px] font-medium gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M11.5 23.5C8.81667 21.2167 6.81267 19.096 5.488 17.138C4.16333 15.18 3.50067 13.3673 3.5 11.7C3.5 9.19998 4.30433 7.20832 5.913 5.72499C7.52167 4.24165 9.384 3.49999 11.5 3.49999C11.6667 3.49999 11.8293 3.50432 11.988 3.51299C12.1467 3.52165 12.3173 3.54232 12.5 3.57499V5.59999C12.3333 5.56665 12.1667 5.54165 12 5.52499C11.8333 5.50832 11.6667 5.49998 11.5 5.49998C9.81667 5.49998 8.39567 6.07899 7.237 7.23699C6.07833 8.39499 5.49933 9.88265 5.5 11.7C5.5 12.8833 5.99167 14.2377 6.975 15.763C7.95833 17.2883 9.46667 18.984 11.5 20.85C13.5333 18.9833 15.0417 17.2873 16.025 15.762C17.0083 14.2367 17.5 12.8827 17.5 11.7C17.5 11.6667 17.4957 11.6333 17.487 11.6C17.4783 11.5667 17.4743 11.5333 17.475 11.5H19.475C19.475 11.5333 19.4793 11.5667 19.488 11.6C19.4967 11.6333 19.5007 11.6667 19.5 11.7C19.5 13.3667 18.8373 15.1793 17.512 17.138C16.1867 19.0967 14.1827 21.2173 11.5 23.5ZM11.5 13.5C12.05 13.5 12.521 13.304 12.913 12.912C13.305 12.52 13.5007 12.0493 13.5 11.5C13.5 10.95 13.304 10.479 12.912 10.087C12.52 9.69499 12.0493 9.49932 11.5 9.49999C10.95 9.49999 10.479 9.69599 10.087 10.088C9.695 10.48 9.49933 10.9507 9.5 11.5C9.5 12.05 9.696 12.521 10.088 12.913C10.48 13.305 10.9507 13.5007 11.5 13.5Z"
            fill="black" />
          <path
            d="M17 7.5L15 5.6L13.5 7.5L17 10.5L23 4L21.5 2.5L17 7.5Z"
            fill="black" />
        </svg>
        <h1>Other Address</h1>
      </div>
      <div
        v-if="userAddress.otherAddress.length > 0"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        <div
          v-for="(address, i) in userAddress.otherAddress"
          :key="i"
          class="border rounded-lg w-full md:w-[370px] lg:w-[470px] lg:h-[292px] p-[15px] md:p-[30px] lg:p-[30px] mt-6">
          <div class="flex justify-between">
            <div>
              <h1 class="font-bold text-[22px] mb-[15px]">
                {{ address.contact_name }}
              </h1>
              <p class="text-[20px] leading-5 mb-[20px]">
                {{ address.landmark }}, {{ address.address_line_1 }},
                {{ address.address_line_2 }}
                <br />
                {{ address.city }}, {{ address.pincode }} <br />
                {{ address.state }}
              </p>
            </div>
            <div>
              <div
                class="bg-[#EAEAF3] text-center text-[#867EA8] w-[82px] h-[25px] rounded-xl capitalize">
                {{ address.type }}
              </div>
            </div>
          </div>
          <h2 class="font-bold text-[20px]">
            Mobile: {{ address.contact_number }}
          </h2>
          <div class="flex gap-2 items-center mt-8 text-[#FF4A49]">
            <div
              @click="addressRemove(address.id)"
              class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
              <img :src="removeIcon" />
              <p>Remove</p>
            </div>
            <router-link
              :to="'/address-update/' + address.id"
              class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
              <img :src="editIcon" />
              <p>Edit</p>
            </router-link>
            <div
              @click="handleMakeDefault(address.id)"
              class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
              <img :src="makeDefault" />
              <p>Make Default</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner from "../components/LoadingSpinner";
import backArrow from "@/assets/temp/backArrow.svg";
import backMbl from "@/assets/temp/backMbl.svg";
import defaultAdd from "@/assets/temp/defaultAdd.svg";
import editIcon from "@/assets/temp/editIcon.svg";
import removeIcon from "@/assets/temp/removeIcon.svg";
import makeDefault from "@/assets/temp/makeDefault.svg";

export default {
  name: "Addresses",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      backArrow,
      removeIcon,
      editIcon,
      makeDefault,
      defaultAdd,
      backMbl,
      userAddress: {
        defaultAddress: [],
        otherAddress: [],
      },
    };
  },
  methods: {
    async getAllUserAddress() {
      try {
        this.isLoading = true;
        this.userAddress.defaultAddress = [];
        this.userAddress.otherAddress = [];
        const result = await axios.get("api/user/user-address");
        if (
          result.data.user_addresses &&
          result.data.user_addresses.length > 0
        ) {
          result.data.user_addresses.forEach((address) => {
            if (address.is_default) {
              this.userAddress.defaultAddress.push(address);
            } else {
              this.userAddress.otherAddress.push(address);
            }
          });
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        this.isLoading = false;
      }
    },
    addressRemove(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`api/user/user-address/${id}/delete`)
            .then((response) => {
              console.log("Address deleted:", response);
              if (response.data.sucess === true) {
                Swal.fire(
                  "Deleted!",
                  "Your Address has been deleted.",
                  "success"
                );
                this.getAllUserAddress();
              }
            })
            .catch((error) => {
              console.error("Error deleting address:", error);
              // Handle error cases
            });
        }
      });
    },
    // make default function
    async handleMakeDefault(id) {
      const result = await axios.get(
        `/api/user/user-address/${id}/make-default`
      );
      if ("success" in result.data && result.data.success == true) {
        this.getAllUserAddress();
      }
    },
  },
  async mounted() {
    this.getAllUserAddress();
  },
  computed: {},
};
</script>

<style>
.custom-section .v-label {
  opacity: unset;
}
</style>
