<template>
  <div>
    <LoadingSpinner2 v-if="cycle_loading" :style="{ minHeight: '400px' }" />

    <div
      v-if="!cycle_loading && cycle"
      class="flex flex-col md:flex-row lg:flex-row">
      <div class="p-4 w-full md:w-[50%] lg:w-[50%] bg-[#F6F6FB]">
        <div class="flex items-center gap-2 mb-[10px]">
          <img
            :src="detailsBack"
            @click="$router.push({ name: 'rental-listing' })" />

          <h1 class="text-[#000000] text-[16px] font-medium">
            {{ cycle.name ?? "-" }}
          </h1>
        </div>
        <div class="bg-white relative p-4 rounded-[10px] w-full">
          <div class="flex h-[200px] md:h-[400px] lg:h-[400px]">
            <div :style="{ width: '20%', overflowY: 'auto' }">
              <img
                class="has-img-fallback"
                v-for="(image, i) in images"
                :src="image['data-source']"
                :key="image.id"
                :style="{
                  border: '1px solid #bdbdbd',
                  borderRadius: '10px',
                  marginBottom: '10px',
                }"
                @click="currentImageIndex = i" />
            </div>
            <img
              class="has-img-fallback"
              :style="{
                width: '80%',
                aspectRatio: '1.5',
                objectFit: 'contain',
              }"
              :src="currentImage"
              @click="openImagePreview" />
          </div>

          <div
            v-if="cycle?.type?.value"
            class="bestseller-tag rotate-90 uppercase">
            <h1 class="text-white text-[14px] -rotate-180">
              {{ cycle.type.value }}
            </h1>
          </div>

          <div class="flex items-center gap-4 justify-center mt-4 mb-6">
            <div v-if="cycle.age?.value" class="flex items-center gap-2">
              <img :src="ageIcon" />
              <h1 class="text-[#999797] font-medium text-[11px]">
                {{ cycle.age.value }}
              </h1>
            </div>
            <div
              v-if="cycle?.frame_size?.value"
              class="flex items-center gap-2">
              <img :src="frameIcon" />
              <h1 class="text-[#999797] font-medium text-[11px]">
                {{ cycle.frame_size.value }}
              </h1>
            </div>
            <div
              v-if="cycle?.wheel_size?.value"
              class="flex items-center gap-2">
              <img :src="wheelIcon" />
              <h1 class="text-[#999797] font-medium text-[11px]">
                {{ cycle.wheel_size.value }}
              </h1>
            </div>
          </div>
        </div>

        <div class="w-full flex items-center my-4">
          <div class="w-[70%]">
            <div class="flex items-center gap-2">
              <h1 class="text-[14px] font-medium text-[#867EA8]">SKU:</h1>
              <p class="text-[12px] text-[#36314C]">{{ cycle.sku }}</p>
            </div>
            <div class="flex items-center gap-2">
              <h1 class="text-[14px] font-medium text-[#867EA8]">Condition:</h1>
              <p class="text-[12px] text-[#36314C]">
                {{ cycle.condition ?? "-" }}
              </p>
            </div>
          </div>
          <div class="w-[30%] flex items-start gap-4">
            <div class="w-[1px] h-[77px] bg-[#CCC6E9]"></div>
            <div>
              <h1 class="text-[14px] font-medium text-[#867EA8]">
                Self Pickup
              </h1>
              <h1 class="text-[12px] text-[#36314C]">Not Available</h1>
              <h1 class="text-[14px] font-medium text-[#867EA8] mt-[8px]">
                Deposit
              </h1>
              <h1 class="text-[12px] font-semibold text-[#36314C]">
                ₹ {{ cycle.deposit }}
              </h1>
              <h1 class="text-[14px] font-medium text-[#867EA8] mt-[8px]">
                Shipping
              </h1>
              <h1 class="text-[12px] font-semibold text-[#36314C]">
                ₹ {{ cycle.shipping_fee }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-[50%] lg:w-[50%]">
        <div class="flex items-center justify-between w-full bg-white mt-[8px]">
          <div
            @click="toggleTab = 'Specifications'"
            class="w-[50%] cursor-pointer text-center">
            <h1
              class="mb-[5px] text-[16px] font-bold"
              :style="{
                color: toggleTab === 'Specifications' ? '#000000' : '#CBCED6',
              }">
              Specifications
            </h1>
            <div
              class="w-full h-[2px]"
              :class="{
                'bg-[#FF4A49]': toggleTab === 'Specifications',
                'bg-[#CBCED6]': toggleTab !== 'Specifications',
              }"></div>
          </div>
          <div
            @click="toggleTab = 'Description'"
            class="w-[50%] text-center cursor-pointer">
            <h1
              class="text-[16px] font-bold mb-[5px]"
              :style="{
                color: toggleTab === 'Description' ? '#000000' : '#CBCED6',
              }">
              Description
            </h1>
            <div
              class="w-full h-[2px]"
              :class="{
                'bg-[#FF4A49]': toggleTab === 'Description',
                'bg-[#CBCED6]': toggleTab !== 'Description',
              }"></div>
          </div>
        </div>

        <div class="flex items-center">
          <div v-if="toggleTab === 'Description'" class="p-4 relative w-full">
            <div>
              {{ cycle.description }}
            </div>

            <!-- <div
              @click="toggleDescription"
              class="p-3 flex items-start justify-between bg-white gap-8"
              style="
                border-radius: 15px;
                border: 1px solid var(--primary-1-purple-shade-2, #867ea8);
                background: var(--text-1, #fff);
              ">
              <h1 v-if="cycleDescription === ''" class="text-[12px]">
                True Blood lineage of a perfect Hybrid bicycle, Blooding comes
                packed with undisputed specifications & unmatched looks
              </h1>
              <h1 v-else class="text-[12px]">
                {{ cycleDescription.title }}
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 10 6"
                fill="none">
                <path
                  d="M5 3.38195L8.3093 0L10 0.847128L5.56061 5.38397L5 6L4.43939 5.38397L0 0.847128L1.69069 0L5 3.38195Z"
                  fill="#36314C" />
              </svg>
            </div> -->
            <!-- <ul
              class="z-[50] absolute mt-1 w-[90%] overflow-y-auto h-[130px] rounded bg-gray-50 ring-1 ring-gray-300"
              v-show="openDescription">
              <li
                v-for="(des, i) in rentalCycleDescription"
                :key="i"
                class="cursor-pointer select-none p-2 hover:bg-gray-200"
                @click="setDescription(des)">
                <h1 class="text-[12px]">
                  {{ des.title }}
                </h1>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="flex items-center">
          <div
            v-if="toggleTab === 'Specifications'"
            class="p-4 relative w-full">
            <ul>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">Category</h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.category?.value ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">Company</h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.company?.name ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">Type</h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.type?.value ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">Gears</h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.speed?.value ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">
                    Wheel Size
                  </h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.wheel_size?.value ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">
                    Frame Size
                  </h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.frame_size?.value ?? "-" }}
                  </h1>
                </div>
              </li>
              <li class="mb-[6px]">
                <div
                  class="flex items-center justify-between border rounded-[10px] p-3">
                  <h1 class="font-bold text-[#36314C] capitalize">
                    Frame Material
                  </h1>
                  <h1 class="text-[15px] text-[#867EA8]">
                    {{ cycle?.frame_material?.value ?? "-" }}
                  </h1>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="cycle && cycle.accessories && cycle.accessories.length > 0"
      class="bg-[#F6F6FB] p-2">
      <h1 class="text-[16px] text-[#000000] my-2">
        Suggested Add-on Accessories
      </h1>
      <Swiper
        :breakpoints="{
          0: {
            slidesPerView: 2.2,
            spaceBetween: 5,
          },
          412: {
            slidesPerView: 2.4,
            spaceBetween: 5,
          },
          600: {
            slidesPerView: 2.4,
            spaceBetween: 5,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1000: {
            slidesPerView: 7,
            spaceBetween: 5,
          },
          1280: {
            slidesPerView: 7,
            spaceBetween: 5,
          },
        }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :modules="modules"
        class="mySwiper">
        <SwiperSlide v-for="(accessory, i) in cycle.accessories" :key="i">
          <div class="bg-white p-2 w-[150px] h-[250px] rounded-[10px]">
            <img
              class="w-full has-img-fallback"
              :style="{ aspectRatio: 1, objectFit: 'contain' }"
              :src="accessory.image_path" />
            <div class="text-center mt-2">
              <h1 class="text-[11px] text-[#36314C] mb-[3px]">
                {{ accessory.name }}
              </h1>
              <p class="text-[12px] text-[#36314C]">
                <span class="text-[16px] font-extrabold"
                  >₹ {{ accessory.price * days }} /</span
                >
                {{ days }} days
              </p>

              <button
                v-if="selectedAccessories.includes(accessory.id)"
                class="w-[81px] mt-[4px] h-[25px] bg-[#71AE0D] text-white rounded-[5px]"
                @click="toggleAccessory(accessory.id)">
                <img :style="{ display: 'inline' }" :src="checkIcon" />Added
              </button>
              <button
                v-else
                class="w-[81px] mt-[4px] h-[25px] bg-[#36314C] text-white rounded-[5px]"
                @click="toggleAccessory(accessory.id)">
                ADD
              </button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>

    <div
      v-if="cycle"
      class="text-center sticky p-4 bottom-0 z-[80]"
      style="
        border-top: 2px solid var(--secondary-1, #eaeaf3);
        border-bottom: 2px solid var(--secondary-1, #eaeaf3);
        background: var(--text-1, #fff);
        box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.05);
      ">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-4">
          <div>
            <h1 class="text-[10px] font-medium">Base Rent</h1>
            <h1 class="text-[10px] font-medium text-[#E51963]">
              for {{ days }} days
            </h1>
          </div>
          <h1 v-if="cycle" class="text-[22px] font-medium text-[#36314C]">
            ₹ {{ cycle.price * days }}
          </h1>
        </div>
        <button
          @click="visitBookingDetails"
          class="w-[88px] h-[35px] rounded-[5px] bg-[#71AE0D] text-white text-[16px] font-semibold">
          ADD
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import age from "@/assets/HomeView/age.svg";
import height from "@/assets/HomeView/height.svg";
import detailsBack from "@/assets/temp/detailsBack.svg";
import ageIcon from "@/assets/temp/ageIcon.svg";
import frameIcon from "@/assets/temp/frameIcon.svg";
import wheelIcon from "@/assets/temp/wheelIcon.svg";
import wheel from "@/assets/HomeView/wheel.svg";
import cycle_2 from "@/assets/temp/rental-listing/cycle_2.png";
import mobileImg from "../assets/HomeView/mobile.jpg";
import headlight from "../assets/HomeView/headlight.jpg";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Navigation } from "swiper/modules";
import axios from "axios";
import checkIcon from "@/assets/icons/check.svg";

import LoadingSpinner2 from "./LoadingSpinner2.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    LoadingSpinner2,
  },
  data() {
    return {
      checkIcon,
      id: this.$route.params.id,
      age,
      frameIcon,
      wheelIcon,
      ageIcon,
      detailsBack,
      height,
      wheel,
      cycle: null,
      cycle_loading: false,
      cycle_2,
      mobileImg,
      headlight,
      open: false,
      toggleTab: "Specifications",
      openDescription: false,
      nearCycle: "",
      cycleDescription: "",
      details: [
        {
          title: "bike type",
          description: "Mountain Bike",
        },
        {
          title: "Number Of Speed",
          description: "21",
        },
        {
          title: "Wheel",
          description: "27.5 inch",
        },
        {
          title: "Model",
          description: "BLACKFYRE27T21SBLUE",
        },
        {
          title: "Frame Material",
          description: "Steel",
        },
        {
          title: "Frame Size",
          description: "17 inch",
        },
      ],
      hideDiv: false,
      modules: [Navigation],
      rentalCycleDescription: [
        {
          title:
            "True Blood lineage of a perfect Hybrid bicycle, Blooding comes packed with undisputed specifications & unmatched looks",
        },
        {
          title:
            "True Blood lineage of a perfect Hybrid bicycle, Blooding comes packed with undisputed specifications & unmatched looks",
        },
        {
          title:
            "True Blood lineage of a perfect Hybrid bicycle, Blooding comes packed with undisputed specifications & unmatched looks",
        },
        {
          title:
            "True Blood lineage of a perfect Hybrid bicycle, Blooding comes packed with undisputed specifications & unmatched looks",
        },
        {
          title:
            "True Blood lineage of a perfect Hybrid bicycle, Blooding comes packed with undisputed specifications & unmatched looks",
        },
      ],
      rentalCycleNears: [
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400071",
        },
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400072",
        },
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400073",
        },
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400074",
        },
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400075",
        },
        {
          part1: "₹ 3500 - SKU: 4567889",
          part2: "- Charkop Market, Kandivali, 400076",
        },
      ],
      currentImageIndex: 0,
      accessories: null,
      selectedAccessories: [],
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openDiv() {
      this.hideDiv = true;
    },
    closeDiv() {
      this.hideDiv = false;
    },
    toggleDescription() {
      this.openDescription = !this.openDescription;
    },
    setNearCycle(val) {
      this.nearCycle = val;
      this.open = false;
    },
    setDescription(val) {
      this.cycleDescription = val;
      this.openDescription = false;
    },
    fetchRentalListingDetails() {
      this.cycle_loading = true;
      axios
        .get(`api/user/rp/rental-listing/${this.id}`, {
          params: this.$route.query,
        })
        .then((response) => {
          console.log(response.data);
          this.cycle_loading = false;
          if ("rental_listing" in response.data) {
            this.cycle = response.data["rental_listing"];
          }
        })
        .catch((error) => {
          console.log(error);
          this.cycle_loading = false;
        });
    },
    openImagePreview() {
      this.$viewerApi({
        images: this.images,
        options: {
          initialViewIndex: this.currentImageIndex,
        },
      });
    },
    visitBookingDetails() {
      let query = { ...this.$route.query };
      query["rental_listing_id"] = this.$route.params.id;
      this.$router.push({
        name: "BookingDetails",
        query: query,
      });
    },
    toggleAccessory(id) {
      if (this.selectedAccessories.includes(id)) {
        let i = this.selectedAccessories.indexOf(id);
        if (i > -1) {
          this.selectedAccessories.splice(i, 1);
        }
      } else {
        this.selectedAccessories.push(id);
      }
    },
    getSelectedAccesssories() {
      let accessories = this.$route.query?.accessories;
      if (accessories && accessories.length > 0) {
        this.selectedAccessories = accessories.split(",").map(function (i) {
          return parseInt(i);
        });
      }
    },
  },
  mounted() {
    this.getSelectedAccesssories();
    this.fetchRentalListingDetails();
  },
  computed: {
    images() {
      let images = [];
      this.cycle.images.forEach((image) => {
        images.push({
          src: image.file_path,
          "data-source": image.thumbnail_path,
        });
      });
      return images;
    },
    days() {
      let startDate = dayjs(this.$route.query.startDate);
      let endDate = dayjs(this.$route.query.endDate);
      return endDate.diff(startDate, "day");
    },
    currentImage() {
      if (
        this.images &&
        this.images[this.currentImageIndex] &&
        this.images[this.currentImageIndex]["src"]
      ) {
        return this.images[this.currentImageIndex]["src"];
      }
    },
  },
  watch: {
    selectedAccessories: {
      handler(data) {
        let query = { ...this.$route.query };
        if (data.length > 0) {
          query.accessories = data.join(",");
        } else {
          delete query.accessories;
        }
        this.$router.replace({
          name: this.$route.name,
          query: query,
          params: this.$route.params,
        });
      },
      deep: true,
    },
  },
};
</script>
