<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div
    v-else
    class="p-4 md:px-[160px] md:py-[50px] lg:px-[160px] lg:py-[50px] bg-[#F4F4F4]">
    <div class="bg-[#FFF] p-[20px] md:[40px] lg:p-[40px] rounded-[30px]">
      <div class="flex justify-between items-center">
        <h1 class="text-[16px] md:text-[48px] lg:text-[48px] font-semibold">
          Add New Address
        </h1>
        <div
          @click="$router.push({ name: 'Profile' })"
          class="flex text-[12px] md:text-[26px] lg:text-[26px] font-bold cursor-pointer items-center gap-1">
          <img :src="backArrow" />
          <h1 class="font-bold text-[#FF4A49]">Back to my account</h1>
        </div>
      </div>
      <form @submit.prevent="createUserAddress">
        <div
          class="w-full md:w-[480px] lg:w-[480px] custom-section mt-10 mb-10">
          <v-text-field
            v-model="first_name"
            label="First Name *"
            variant="outlined"
            required>
          </v-text-field>
          <v-text-field
            v-model="last_name"
            label="Last Name *"
            variant="outlined"
            required>
          </v-text-field>
          <!-- <v-text-field
            v-model="user.mobile_number"
            readonly
            label="Mobile Number *"
            variant="outlined"
            required>
          </v-text-field> -->
          <v-text-field
            v-model="house"
            label="House No, Building*"
            variant="outlined"
            required>
          </v-text-field>
          <v-text-field
            v-model="address"
            @input="getAddressSuggestions"
            label="Address (Street Address) *"
            variant="outlined"
            required>
          </v-text-field>
          <div v-if="suggestions.length">
            <ul>
              <li
                v-for="suggestion in suggestions"
                :key="suggestion.place_id"
                @click="selectAddress(suggestion)">
                {{ suggestion.description }}
              </li>
            </ul>
          </div>
          <div v-if="location">
            <p>Latitude: {{ location.lat }}</p>
            <p>Longitude: {{ location.lng }}</p>
          </div>
          <v-text-field
            v-model="town"
            label="Locality / Town *"
            variant="outlined"
            required>
          </v-text-field>
          <v-select
            label="Pin Code *"
            v-model="pin_code"
            :items="options"
            variant="outlined"></v-select>
          <v-text-field
            model-value="Maharashtra"
            label="State / Province *"
            variant="outlined"
            readonly>
          </v-text-field>
          <v-text-field
            model-value="Mumbai"
            label="City / District *"
            variant="outlined"
            readonly>
          </v-text-field>
          <h1 class="text-[20px]">Address Type</h1>
          <v-radio-group inline v-model="category" required>
            <v-radio label="Home" class="custom-radio" value="home" required>
              <template #label>
                <span
                  class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                  >Home</span
                >
              </template>
            </v-radio>
            <v-radio
              label="Office"
              class="custom-radio"
              value="office"
              required>
              <template #label>
                <span
                  class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                  >Office</span
                >
              </template>
            </v-radio>
            <v-radio label="Other" color="red" value="other" required>
              <template #label>
                <span
                  class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                  >Other</span
                >
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div class="flex items-center gap-6">
          <button
            @click="resetForm"
            class="w-[150px] h-[50px] border-[#FF4A49] md:w-[160px] md:h-[60px] lg:w-[160px] lg:h-[60px] text-[#FF4A49] font-extrabold text-[25px] md:text-[30px] lg:text-[30px] border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
            Cancel
          </button>
          <button
            class="bg-[#FF4A49] font-extrabold text-[25px] md:text-[30px] lg:text-[30px] text-white rounded-lg w-[150px] h-[50px] md:w-[160px] md:h-[60px] lg:w-[160px] lg:h-[60px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner from "../components/LoadingSpinner";
import backArrow from "@/assets/temp/backArrow.svg";

export default {
  name: "AddNewAddress",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      backArrow,
      isLoading: false,
      house: "",
      address: "",
      address: "",
      location: null,
      suggestions: [],
      mobile_number: "",

      town: "",
      pin_code: null,
      state: "Maharashtra",
      city: "Mumbai",
      category: "",
      options: [],
    };
  },
  methods: {
    async createUserAddress() {
      const requestData = {
        contact_name: this.first_name + " " + this.last_name,
        is_default: true,
        contact_number: this.user.mobile_number,
        address_line_1: this.house,
        address_line_2: this.address,
        landmark: this.town,
        pincode: this.pin_code,
        state: this.state,
        city: this.city,
        type: this.category,
      };
      console.log(requestData);
      try {
        const response = await axios.post(
          "api/user/user-address/create",
          requestData
        );

        // Handle success, show a success message, etc.
        this.$router.push("/addresses");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Address added successfully!!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error("Error creating address:", error);
        // Handle error, show an error message, etc.
      }
    },

    resetForm() {
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.mobile_number = "";
      this.house = "";
      this.address = "";
      this.town = "";
      this.pin_code = "";
      this.category = "";
      this.$router.push("/Profile");
    },
    async getAddressSuggestions() {
      try {
        const apiKey = "AIzaSyD4w89B43OJWu_NPc2bbjdccP0Z1604fjk";
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/place/autocomplete/json",
          {
            params: {
              input: this.address,
              key: apiKey,
            },
          }
        );
        this.suggestions = response.data.predictions;
      } catch (error) {
        console.error("Error fetching address suggestions:", error);
      }
    },
    async selectAddress(selectedSuggestion) {
      this.address = selectedSuggestion.description;
      this.suggestions = [];

      // Now, you can geocode the selected address if needed
      await this.geocodeAddress();
    },
    async geocodeAddress() {
      try {
        const apiKey = "AIzaSyD4w89B43OJWu_NPc2bbjdccP0Z1604fjk";
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address: this.address,
              key: apiKey,
            },
          }
        );
        if (response.data.results.length > 0) {
          this.location = response.data.results[0].geometry.location;
        } else {
          this.location = null;
          console.error("No results found");
        }
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      const response = await axios.get(`api/user/pincode/getAll`);

      // Extract pincode values from the pincodes array

      const pincodes = response.data.pincodes.map(
        (pincodeObj) => pincodeObj.pincode
      );
      this.options = pincodes;
      // console.log(pincodes);
    } catch (error) {
      console.error("API error:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
.custom-section .v-label {
  opacity: unset;
}
.custom-section .v-selection-control__input > .v-icon {
  opacity: unset;
  color: #ff4a49;
}

.custom-section .v-icon--size-default {
  font-size: 26px;
}
</style>
