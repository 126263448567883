<template>
  <header class="block md:hidden lg:hidden">
    <div class="bg-[#36314C] p-3 flex gap-6 items-center">
      <svg
        @click="$router.push({ name: 'ServiceRepair' })"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        class="cursor-pointer"
        height="17"
        viewBox="0 0 10 17"
        fill="none">
        <path
          d="M8.33333 16.6667L0 8.33333L8.33333 0L9.8125 1.47917L2.95833 8.33333L9.8125 15.1875L8.33333 16.6667Z"
          fill="white" />
      </svg>
      <h1 class="text-[16px] font-semibold text-white">Time Slot</h1>
    </div>
  </header>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <!-- address slot -->
    <div class="p-4 lg:px-36 bg-[#F4F4F4]">
      <div class="flex items-center justify-between">
        <h1 class="text-[#36314C] text-[16px] md:text-[28px] lg:text-[28px]">
          Package Details
        </h1>
        <div
          @click="$router.push({ name: 'ServiceRepair' })"
          class="flex gap-1 text-[18px] font-medium cursor-pointer items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M4.16667 15.8334H5.33333L12.5208 8.64585L11.3542 7.47919L4.16667 14.6667V15.8334ZM16.0833 7.43752L12.5417 3.93752L13.7083 2.77085C14.0278 2.45141 14.4203 2.29169 14.8858 2.29169C15.3514 2.29169 15.7436 2.45141 16.0625 2.77085L17.2292 3.93752C17.5486 4.25696 17.7153 4.64252 17.7292 5.09419C17.7431 5.54585 17.5903 5.93113 17.2708 6.25002L16.0833 7.43752ZM14.875 8.66669L6.04167 17.5H2.5V13.9584L11.3333 5.12502L14.875 8.66669ZM11.9375 8.06252L11.3542 7.47919L12.5208 8.64585L11.9375 8.06252Z"
              fill="#FF4A49" />
          </svg>
          <p
            class="text-[14px] md:text-[18px] lg:text-[18px] font-medium text-[#FF4A49]">
            Edit
          </p>
        </div>
      </div>
      <div class="p-4 lg:p-8 mt-[9px] rounded-[5px] bg-white">
        <div class="flex items-center gap-16 md:gap-20 lg:gap-20">
          <h1 class="text-[12px] md:text-[18px] lg:text-[18px] text-[#36314C]">
            Pincode:
          </h1>
          <h2 class="text-[12px] md:text-[18px] lg:text-[18px] font-bold">
            {{ searchValue.cyclePin }}
          </h2>
        </div>
        <div class="flex mt-[10px] gap-10">
          <h1 class="text-[12px] md:text-[18px] lg:text-[18px]">
            Cycle Details:
          </h1>
          <h2 class="text-[12px] md:text-[18px] lg:text-[18px] font-bold">
            {{ searchValue.cycleBrand }} ({{ searchValue.cycleOption }})
            <br />
            <span
              class="text-[11px] md:text-[16px] lg:text-[16px] font-medium text-[#867EA8]"
              >{{ searchValue.cycleModel }}</span
            >
          </h2>
        </div>
        <hr class="bg-[#CCC6E9] mt-[18px] mb-[15px]" />
        <div class="flex items-center justify-between">
          <h1 class="text-[12px] md:text-[18px] lg:text-[18px] text-[#36314C]">
            {{ packageData.name }}
          </h1>
          <h2 class="text-[12px] md:text-[18px] lg:text-[18px] font-bold">
            ₹{{ packageData.price }}
          </h2>
        </div>
        <!-- <h1
          class="text-[10px] md:text-[15px] lg:text-[15px] font-medium text-[#FF9A00]">
          Offer: Free brake wire
        </h1> -->
        <hr class="bg-[#CCC6E9] my-[10px]" />

        <div
          v-if="userAddresses.length !== 0"
          class="flex items-center justify-between">
          <h1
            class="text-[12px] md:text-[18px] lg:text-[18px] text-[#36314C] mb-2">
            Address:
          </h1>
          <h1
            @click="openModalAddress"
            class="text-[12px] cursor-pointer md:text-[18px] lg:text-[18px] text-[#ff4a49] mb-2">
            Change Address
          </h1>
        </div>
        <div class="relative">
          <div v-if="userAddresses.length === 0" class="text-center">
            <button
              @click="modalOpen"
              class="w-[257px] text-[16px] font-bold text-[#71AE0D] h-[50px]"
              style="
                border-radius: 10px;
                border: 1px solid #e9e9e9;
                background: linear-gradient(
                  180deg,
                  #f4f4f6 0%,
                  rgba(234, 234, 243, 0.37) 100%
                );
              ">
              ADD ADDRESS
            </button>
          </div>
          <div
            v-if="selectedAddress && userAddresses.length !== 0"
            class="p-3 w-full lg:w-1/2 rounded-[5px]"
            style="border: 1px solid var(--secondary-1, #eaeaf3)">
            <div class="flex gap-2 items-start my-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-[30px] h-[24px]"
                viewBox="0 0 18 18"
                fill="none">
                <path
                  d="M9 10.125C8.44374 10.125 7.89997 9.96005 7.43746 9.65101C6.97495 9.34197 6.61446 8.90272 6.40159 8.3888C6.18872 7.87488 6.13302 7.30938 6.24154 6.76381C6.35006 6.21824 6.61793 5.7171 7.01126 5.32376C7.4046 4.93043 7.90574 4.66256 8.45131 4.55404C8.99688 4.44552 9.56238 4.50122 10.0763 4.71409C10.5902 4.92696 11.0295 5.28745 11.3385 5.74996C11.6476 6.21247 11.8125 6.75624 11.8125 7.3125C11.8116 8.05815 11.515 8.773 10.9878 9.30025C10.4605 9.82751 9.74565 10.1241 9 10.125ZM9 5.625C8.66625 5.625 8.33998 5.72397 8.06248 5.9094C7.78497 6.09482 7.56868 6.35837 7.44095 6.66672C7.31323 6.97507 7.27981 7.31437 7.34493 7.64172C7.41004 7.96906 7.57076 8.26974 7.80676 8.50574C8.04276 8.74175 8.34344 8.90246 8.67079 8.96758C8.99813 9.03269 9.33743 8.99927 9.64578 8.87155C9.95413 8.74383 10.2177 8.52753 10.4031 8.25003C10.5885 7.97252 10.6875 7.64626 10.6875 7.3125C10.6871 6.86509 10.5091 6.43612 10.1928 6.11975C9.87638 5.80338 9.44742 5.62545 9 5.625Z"
                  fill="#FF4A49" />
                <path
                  d="M9.00001 16.875L4.25476 11.2787C4.18882 11.1947 4.12357 11.1101 4.05901 11.025C3.24843 9.95725 2.81054 8.65306 2.81251 7.3125C2.81251 5.67147 3.4644 4.09766 4.62478 2.93728C5.78516 1.7769 7.35898 1.125 9.00001 1.125C10.641 1.125 12.2148 1.7769 13.3752 2.93728C14.5356 4.09766 15.1875 5.67147 15.1875 7.3125C15.1895 8.65245 14.7518 9.95605 13.9416 11.0233L13.941 11.025C13.941 11.025 13.7723 11.2466 13.7469 11.2764L9.00001 16.875ZM4.95676 10.3472C4.95788 10.3472 5.08838 10.5204 5.11819 10.5576L9.00001 15.1358L12.8869 10.5514C12.9116 10.5204 13.0433 10.3461 13.0438 10.3455C13.706 9.47313 14.0638 8.4077 14.0625 7.3125C14.0625 5.96984 13.5291 4.68217 12.5797 3.73277C11.6303 2.78337 10.3427 2.25 9.00001 2.25C7.65735 2.25 6.36968 2.78337 5.42028 3.73277C4.47088 4.68217 3.93751 5.96984 3.93751 7.3125C3.93633 8.40838 4.29401 9.47442 4.95676 10.3472Z"
                  fill="#6D3186" />
              </svg>
              <div>
                <h1
                  class="text-[13px] md:text-[15px] lg:text-[15px] font-bold capitalize">
                  {{ selectedAddress.type }}
                </h1>

                <h1 class="text-[14px] text-[#36314C]">
                  {{ selectedAddress.address_line_1 }},
                  {{ selectedAddress.address_line_2 }},
                  {{ selectedAddress.landmark }} , {{ selectedAddress.city }}-{{ selectedAddress.pincode }}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <dialog :id="modalIdAddress" class="modal modal-bottom">
          <div
            class="modal-box w-full h-[50%] overflow-y-auto max-h-[50%] max-w-full"
            ref="deleteModalRef">
            <form method="dialog">
              <button
                class="btn btn-sm bg-white btn-circle btn-ghost absolute right-2 top-2"
                @click="closeModalAddress">
                ✕
              </button>
            </form>
            <div class="flex items-center justify-between mt-6 mb-4">
              <h3 class="font-bold text-lg">Addresses</h3>
              <h1
                @click="modalOpen"
                class="text-[15px] font-bold cursor-pointer md:text-[18px] lg:text-[18px] text-[#ff4a49] mb-2">
                + Add New Address
              </h1>
            </div>
            <a
              v-if="userAddresses.length !== 0"
              v-for="address in userAddresses"
              :key="address"
              @click="selectAddress(address)"
              class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
              <div class="flex gap-2 items-start my-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-[30px] h-[24px]"
                  viewBox="0 0 18 18"
                  fill="none">
                  <path
                    d="M9 10.125C8.44374 10.125 7.89997 9.96005 7.43746 9.65101C6.97495 9.34197 6.61446 8.90272 6.40159 8.3888C6.18872 7.87488 6.13302 7.30938 6.24154 6.76381C6.35006 6.21824 6.61793 5.7171 7.01126 5.32376C7.4046 4.93043 7.90574 4.66256 8.45131 4.55404C8.99688 4.44552 9.56238 4.50122 10.0763 4.71409C10.5902 4.92696 11.0295 5.28745 11.3385 5.74996C11.6476 6.21247 11.8125 6.75624 11.8125 7.3125C11.8116 8.05815 11.515 8.773 10.9878 9.30025C10.4605 9.82751 9.74565 10.1241 9 10.125ZM9 5.625C8.66625 5.625 8.33998 5.72397 8.06248 5.9094C7.78497 6.09482 7.56868 6.35837 7.44095 6.66672C7.31323 6.97507 7.27981 7.31437 7.34493 7.64172C7.41004 7.96906 7.57076 8.26974 7.80676 8.50574C8.04276 8.74175 8.34344 8.90246 8.67079 8.96758C8.99813 9.03269 9.33743 8.99927 9.64578 8.87155C9.95413 8.74383 10.2177 8.52753 10.4031 8.25003C10.5885 7.97252 10.6875 7.64626 10.6875 7.3125C10.6871 6.86509 10.5091 6.43612 10.1928 6.11975C9.87638 5.80338 9.44742 5.62545 9 5.625Z"
                    fill="#FF4A49" />
                  <path
                    d="M9.00001 16.875L4.25476 11.2787C4.18882 11.1947 4.12357 11.1101 4.05901 11.025C3.24843 9.95725 2.81054 8.65306 2.81251 7.3125C2.81251 5.67147 3.4644 4.09766 4.62478 2.93728C5.78516 1.7769 7.35898 1.125 9.00001 1.125C10.641 1.125 12.2148 1.7769 13.3752 2.93728C14.5356 4.09766 15.1875 5.67147 15.1875 7.3125C15.1895 8.65245 14.7518 9.95605 13.9416 11.0233L13.941 11.025C13.941 11.025 13.7723 11.2466 13.7469 11.2764L9.00001 16.875ZM4.95676 10.3472C4.95788 10.3472 5.08838 10.5204 5.11819 10.5576L9.00001 15.1358L12.8869 10.5514C12.9116 10.5204 13.0433 10.3461 13.0438 10.3455C13.706 9.47313 14.0638 8.4077 14.0625 7.3125C14.0625 5.96984 13.5291 4.68217 12.5797 3.73277C11.6303 2.78337 10.3427 2.25 9.00001 2.25C7.65735 2.25 6.36968 2.78337 5.42028 3.73277C4.47088 4.68217 3.93751 5.96984 3.93751 7.3125C3.93633 8.40838 4.29401 9.47442 4.95676 10.3472Z"
                    fill="#6D3186" />
                </svg>
                <div>
                  <h1
                    class="text-[13px] md:text-[15px] lg:text-[15px] font-bold capitalize">
                    {{ address.type }}
                  </h1>

                  <h1 class="text-[14px] text-[#36314C]">
                    {{ address.address_line_1 }}, {{ address.address_line_2 }},
                    {{ address.landmark }}
                    {{ address.city }}-{{ address.pincode }}
                  </h1>
                </div>
              </div>
            </a>
          </div>
        </dialog>
        <dialog :id="modalId" className="modal modal-top">
          <div
            method="dialog"
            className="modal-box bg-white w-full max-w-full h-full max-h-full ">
            <div class="modal-action mb-6 justify-between">
              <h3 class="font-medium text-lg">Add New Address</h3>
              <button
                class="btn btn-sm btn-circle btn-ghost"
                @click="modalClose">
                ✕
              </button>
            </div>
            <form @submit.prevent="createUserAddress">
              <div class="w-full custom-section">
                <div class="flex items-center gap-4">
                  <v-text-field
                    density="compact"
                    v-model="first_name"
                    label="First Name *"
                    variant="outlined"
                    required>
                  </v-text-field>
                  <v-text-field
                    density="compact"
                    v-model="last_name"
                    label="Last Name *"
                    variant="outlined"
                    required>
                  </v-text-field>
                </div>

                <v-text-field
                  density="compact"
                  v-model="house"
                  label="House No, Building*"
                  variant="outlined"
                  required>
                </v-text-field>
                <v-text-field
                  density="compact"
                  v-model="address"
                  label="Address (Street Address) *"
                  variant="outlined"
                  required>
                </v-text-field>
                <v-text-field
                  density="compact"
                  v-model="town"
                  label="Locality / Town *"
                  variant="outlined"
                  required>
                </v-text-field>

                <div
                  @click="openModal1"
                  class="p-[10px] mb-[20px] border border-black cursor-pointer rounded-[5px]">
                  <h1 v-if="pin_code === null" class="text-[16px]">
                    Pin Code*
                  </h1>
                  <h1 v-if="pin_code" class="text-[16px]">
                    {{ pin_code }}
                  </h1>
                </div>
                <v-text-field
                  density="compact"
                  v-model="state"
                  label="State / Province *"
                  variant="outlined"
                  readonly>
                </v-text-field>
                <v-text-field
                  v-model="city"
                  density="compact"
                  label="City / District *"
                  variant="outlined"
                  readonly>
                </v-text-field>
                <h1 class="text-[16px] font-medium">Address Type</h1>
                <v-radio-group inline v-model="category" required>
                  <v-radio
                    label="Home"
                    class="custom-radio"
                    value="home"
                    required>
                    <template #label>
                      <span
                        class="text-[14px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                        >Home</span
                      >
                    </template>
                  </v-radio>
                  <v-radio
                    label="Office"
                    class="custom-radio"
                    value="office"
                    required>
                    <template #label>
                      <span
                        class="text-[14px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                        >Office</span
                      >
                    </template>
                  </v-radio>
                  <v-radio label="Other" color="red" value="other" required>
                    <template #label>
                      <span
                        class="text-[14px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                        >Other</span
                      >
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="flex items-center justify-center bg-white pt-3 gap-6">
                <button
                  @click="resetForm"
                  class="w-[150px] h-[50px] md:w-[160px] md:h-[60px] lg:w-[160px] lg:h-[60px] text-[#FF4A49] font-extrabold text-[20px] md:text-[30px] lg:text-[30px] rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]"
                  style="border: 3px solid var(--primary-4, #ff4a49)">
                  Cancel
                </button>
                <button
                  class="bg-[#FF4A49] font-extrabold text-[20px] md:text-[30px] lg:text-[30px] text-white rounded-lg w-[150px] h-[50px] md:w-[160px] md:h-[60px] lg:w-[160px] lg:h-[60px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
                  Save
                </button>
              </div>
            </form>
          </div>
        </dialog>
        <dialog ref="modal1" class="modal">
          <form method="dialog" class="modal-box">
            <button
              class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              @click="closeModal1">
              ✕
            </button>
            <ul class="menu">
              <div class="mb-4">
                <h1
                  class="text-[18px] border-none md:text-[28px] lg:text-[28px] font-medium">
                  Pincodes
                </h1>
              </div>
              <hr class="mb-4" />
              <li v-for="(option, i) in options" :key="i">
                <h3
                  class="text-lg font-medium"
                  @click="handleOptionClick(option)">
                  {{ option }}
                </h3>
              </li>
            </ul>
          </form>
        </dialog>
        <!-- error added -->
        <!-- <div>{{ errorMsg }}</div> -->
        <div
          v-if="
            searchValue.cyclePin !== selectedAddress.pincode &&
            userAddresses.length !== 0
          "
          class="flex mt-2 items-center justify-between">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M1 21L12 2L23 21H1ZM4.45 19H19.55L12 6L4.45 19ZM12 18C12.2833 18 12.521 17.904 12.713 17.712C12.905 17.52 13.0007 17.2827 13 17C13 16.7167 12.904 16.479 12.712 16.287C12.52 16.095 12.2827 15.9993 12 16C11.7167 16 11.479 16.096 11.287 16.288C11.095 16.48 10.9993 16.7173 11 17C11 17.2833 11.096 17.521 11.288 17.713C11.48 17.905 11.7173 18.0007 12 18ZM11 15H13V10H11V15Z"
              fill="#E42724" />
          </svg>
          <h1 class="text-[10px] text-center text-[#E42724]">
            The Service pincode is not matches with your address pincode. Please
            change the address or Edit selected pincode
          </h1>
        </div>
        <div
          v-if="userAddresses.length === 0"
          class="flex mt-2 items-center justify-between">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M1 21L12 2L23 21H1ZM4.45 19H19.55L12 6L4.45 19ZM12 18C12.2833 18 12.521 17.904 12.713 17.712C12.905 17.52 13.0007 17.2827 13 17C13 16.7167 12.904 16.479 12.712 16.287C12.52 16.095 12.2827 15.9993 12 16C11.7167 16 11.479 16.096 11.287 16.288C11.095 16.48 10.9993 16.7173 11 17C11 17.2833 11.096 17.521 11.288 17.713C11.48 17.905 11.7173 18.0007 12 18ZM11 15H13V10H11V15Z"
              fill="#E42724" />
          </svg>
          <h1 class="text-[10px] text-center text-[#E42724]">
            OOPS ! Your address book is empty.Please add atleast one address
            with above pincode.
          </h1>
        </div>
      </div>
    </div>
    <!-- time slot -->
    <div
      v-if="searchValue.cyclePin === selectedAddress.pincode"
      class="p-2 md:pl-16 lg:pl-16">
      <h1
        class="mt-[10px] ml-[8px] md:ml-[70px] lg:ml-[70px] text-[16px] md:text-[40px] lg:text-[40px]">
        Choose a Slot
      </h1>
      <v-timeline
        line-color="red-lighten-2"
        style="justify-content: start"
        side="end"
        class="mt-[10px] left-[-22px] md:left-[40px] lg:left-[40px]">
        <v-timeline-item dot-color="red-lighten-2" size="small">
          <v-alert
            size="large"
            class="w-[107%] bg-white md:w-[970px] lg:w-[970px]">
            <h3 class="mb-4 text-[14px] md:text-[32px] lg:text-[32px]">
              Select a Date
            </h3>
            <div class="flex justify-between">
              <!-- <div class="swiper-button-prev hidden md:block lg:block">
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                density="compact"
                size="x-large"
                color="#36314C" />
            </div> -->
              
            <div
                v-for="(day, index) in sevenDaysInMonth"
                :key="index"
                :class="{ today: isToday(day) }">
                <div class="text-center">
                  <button
                    @click="handleGetTime(day, index)"
                    class="text-[13px] time-outline md:text-[24px] lg:text-[24px] rounded-[5px] w-[63px] h-[33px] md:w-[120px] md:h-[50px] lg:w-[120px] lg:h-[50px]"
                    :class="[
                      {
                        today: isToday(day),
                        'bg-[#ff4a49] text-white':
                          selectedButtonIndex === index,
                      },
                    ]">
                    {{ isToday(day) ? "Today" : formatDate(day) }}
                  </button>
                </div>
            </div>

              
            </div>
          </v-alert>
        </v-timeline-item>
        <v-timeline-item dot-color="red-lighten-2" size="small">
          <v-alert
            size="large"
            class="w-[107%] bg-white md:w-[920px] lg:w-[920px]">
            <h3 class="mb-4 text-[14px] md:text-[32px] lg:text-[32px]">
              Select a Time
            </h3>
            <div class="flex items-center gap-[8px] lg:gap-4">
              <div>
                <button
                  @click="selectButton('Morning')"
                  :class="{
                    'bg-[#ff4a49] text-white': selectedButton === 'Morning',
                  }"
                  class="time-outline rounded-[5px] w-[98px] text-[14px] md:text-[30px] lg:text-[30px] font-semibold h-[36px] md:w-[280px] md:h-[60px] lg:w-[280px] lg:h-[60px]">
                  Morning
                </button>
              </div>

              <div>
                <div>
                  <button
                    @click="selectButton('Afternoon')"
                    :class="{
                      'bg-[#ff4a49] text-white': selectedButton === 'Afternoon',
                    }"
                    class="time-outline rounded-[5px] w-[98px] text-[14px] md:text-[30px] lg:text-[30px] font-semibold h-[36px] md:w-[280px] md:h-[60px] lg:w-[280px] lg:h-[60px]">
                    Afternoon
                  </button>
                </div>
              </div>

              <div>
                <div>
                  <button
                    @click="selectButton('Evening')"
                    :class="{
                      'bg-[#ff4a49] text-white': selectedButton === 'Evening',
                    }"
                    class="time-outline rounded-[5px] w-[98px] text-[14px] md:text-[30px] lg:text-[30px] font-semibold h-[36px] md:w-[280px] md:h-[60px] lg:w-[280px] lg:h-[60px]">
                    Evening
                  </button>
                </div>
              </div>
            </div>
            <div
              class="mt-[30px] mb-8 flex items-center md:w-[750px] lg:w-[750px]">
              <div>
                <button
                  v-for="(slot, index) in selectedTimeSlots"
                  :key="index"
                  @click="handleSlotDetails(slot)"
                  :class="{
                    'bg-[#ff4a49] text-white': slot.selected,
                    'bg-[#d9d9d9]': !slot.selected,
                    'disable-time': slot.disabled,
                    'mr-3': true,
                    'mb-3': true,
                    'text-[14px]': true,
                    'md:text-[22px]': true,
                    'lg:text-[22px]': true,
                    'rounded-[5px]': true,
                    'w-[86px]': true,
                    'h-[34px]': true,
                    'md:w-[140px]': true,
                    'md:h-[60px]': true,
                    'lg:w-[140px]': true,
                    'lg:h-[60px]': true,
                  }"
                  :disabled="slot.disabled">
                  {{ slot.time }}
                </button>
              </div>

              <!-- <Swiper
                :breakpoints="{
                  0: {
                    slidesPerView: 3.5,
                    spaceBetween: 5,
                  },
                  412: {
                    slidesPerView: 3.5,
                    spaceBetween: 5,
                  },
                  600: {
                    slidesPerView: 3.5,
                    spaceBetween: 5,
                  },
                  700: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                  },
                  1000: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                  },
                  1280: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                  },
                }"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :modules="modules"
                class="mySwiper">
                <template v-for="(slot, index) in selectedTimeSlots" :key="index">
                  <SwiperSlide>
                    <div class="text-center">
                      <button
                        @click="handleSlotDetails(slot)"
                        :class="{
                          'bg-[#ff4a49] text-white': slot.selected,
                          'bg-[#d9d9d9]': !slot.selected,
                          'mr-3': true,
                          'text-[14px]': true,
                          'md:text-[22px]': true,
                          'lg:text-[22px]': true,
                          'rounded-[5px]': true,
                          'w-[86px]': true,
                          'h-[34px]': true,
                          'md:w-[140px]': true,
                          'md:h-[60px]': true,
                          'lg:w-[140px]': true,
                          'lg:h-[60px]': true,
                        }">
                        {{ slot.time }}
                      </button>
                    </div>
                  </SwiperSlide>
                </template>
              </Swiper> -->
            </div>
          </v-alert>
        </v-timeline-item>
      </v-timeline>
    </div>

    <footer
      :class="{
        'text-center mb-0 md:mb-6 lg:mb-6 bg-white p-4 sticky bottom-0 md:static lg:static z-[100]': true,
        'mt-[15rem]': searchValue.cyclePin !== selectedAddress.pincode,
      }">
      <button
        @click="handleBooking"
        :class="{
          'text-white z-[100] text-[16px] md:text-[30px] lg:text-[30px] w-[300px] md:w-[500px] lg:w-[500px] h-[40px] md:h-[60px] lg:h-[60px] font-semibold rounded-[5px] bg-[#71AE0D] hover:bg-[#70ac10] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#71AE0D]':
            searchValue.cyclePin === selectedAddress.pincode,
          'text-white z-[100] text-[16px] md:text-[30px] lg:text-[30px] w-[300px] md:w-[500px] lg:w-[500px] h-[40px] md:h-[60px] lg:h-[60px] font-semibold rounded-[5px] bg-[#CCCCCC] hover:bg-[#CCCCCC] cursor-not-allowed':
            searchValue.cyclePin !== selectedAddress.pincode || slotInfo === '',
        }"
        :disabled="
          slotInfo === '' || searchValue.cyclePin !== selectedAddress.pincode
        ">
        Review Details
      </button>
    </footer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import LoadingSpinner from "../components/LoadingSpinner";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Navigation } from "swiper/modules";
import axios from "axios";
import Swal from "sweetalert2";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  components: {
    Swiper,
    SwiperSlide,
    LoadingSpinner,
  },
  data() {
    return {
      modules: [Navigation],
      open: false,
      modalId: "my_modal_5",
      modalIdAddress: "my_modal_9", // ID of the modal Address
      showModal: false,
      selectedAddress: {},
      isLoading: false,
      userAddresses: [],
      currentDate: new Date(),
      selectedButtonIndex: null,
      selectedButton: "",
      first_name: "",
      last_name: "",
      dateValue: "",
      slotInfo: "",
      errorMsg: "",
      timeSlots: [],
      house: "",
      address: "",
      town: "",
      pin_code: null,
      options: [],
      state: "Maharashtra",
      city: "Mumbai",
      category: "",
    };
  },
  methods: {
    selectAddress(address) {
      this.selectedAddress = address;
      const modal = document.getElementById(this.modalIdAddress);
      if (modal) {
        modal.close();
      }
    },
    openModalAddress() {
      const modal = document.getElementById(this.modalIdAddress);
      if (modal) {
        modal.showModal();
      }
    },
    closeModalAddress() {
      const modal = document.getElementById(this.modalIdAddress);
      if (modal) {
        modal.close();
      }
    },
    handleSlotDetails(slot) {

      console.log("handleSlotDetails", slot)

      this.slotInfo = slot;
      this.timeSlots.forEach((slot) => {
        slot.selected = false;
      });

      // Select the clicked slot
      slot.selected = true;
    },
    selectButton(button) {
      this.selectedButton = button;

      // this.timeSlots.forEach((slot) => {
      //   slot.visible = false;
      // });

      // // Set visibility based on the selected button
      // if (button === "morning") {
      //   for (let i = 0; i < 5; i++) {
      //     this.timeSlots[i].visible = true;
      //   }
      // } else if (button === "afternoon") {
      //   for (let i = 5; i < 10; i++) {
      //     this.timeSlots[i].visible = true;
      //   }
      // } else if (button === "evening") {
      //   for (let i = 10; i < 15; i++) {
      //     this.timeSlots[i].visible = true;
      //   }
      // }
    },
    formatDate(date) {
      const day = date.getDate();
      const month = date.toLocaleString(undefined, { month: "short" });
      return `${day} ${month}`;
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },

    handleBooking() {
      

      const data = {
        pincode: this.searchValue.cyclePin,
        service_plan_id: this.packageData.id,
        user_address_id: this.selectedAddress.id,
        date: this.dateValue,
        slot_id: this.slotInfo.id,
        cycle_company_id: this.searchValue.companyId,
        cycle_is_geared: this.searchValue.cycleOption,
        service_type: this.searchValue.cycleModel
      };

      axios.post(`api/user/cart/add`, data).then(response => {
        console.log(response)
        if("success" in response.data && response.data["success"] == true) {
          this.$router.push({
            name: 'Review'
          });
        }else{
          this.$swal.fire({
            title: "",
            text: "Some error occurred. Please try again after some time",
            icon: "error"
          })
        }
      }).catch(error => {
        console.log(error)
      });

      console.log(data);

    },
    openModal1() {
      this.$refs.modal1.showModal();
    },
    closeModal1() {
      this.$refs.modal1.close();
    },
    handleOptionClick(option) {
      // Handle the option click logic here if needed
      this.pin_code = option;
      // Then close the modal

      this.$refs.modal1.close();
    },
    async handleGetTime(dayStr, index) {

      console.log("handleGetTime")
      console.log(dayStr)

      // this.isLoading = true;
      try {
        this.selectedButtonIndex = index;
        // Calculate the date for the selected day
        const selectedDate = new Date(dayStr);

        // Check if 'selectedDate' is a valid date
        if (!isNaN(selectedDate)) {
          this.dateValue = dayjs(dayStr).format('YYYY-MM-DD');
        } else {
          console.error("Invalid date");
        }
        const result = await axios.get(`api/user/time-slots`);
        const apiResponse = result.data.slots;
        console.log(result);
        this.timeSlots = apiResponse.map((item) => ({
          time: item.time_period,
          visible: false,
          id: item.id,
          selected: false, // You can set the visibility as needed
          type: item.type,
          disabled: this.isSlotDisabled(item.time_period, selectedDate),
        }));

        console.log("timeSlots", this.timeSlots);

        // Set the selectedButtonIndex to the clicked button's index

        this.selectedButton = this.defaultSelectedButton;
        this.selectButton(this.selectedButton);
      } catch (error) {
        console.error("API error:", error);
      } finally {
        // this.isLoading = false;
      }
    },
    isSlotDisabled(slotTime, selectedDate) {
      const currentTime = new Date();
      const currentDay = currentTime.getDate(); // Get the current day of the month

      // Parse the slotTime to a Date object
      const [time, period] = slotTime.split(" "); // Split the time and period (AM/PM)
      const [hours, minutes] = time.split(":"); // Split hours and minutes
      let hour = parseInt(hours);

      // Adjust the hour for PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      // Set the slotTime with the parsed hours and minutes
      const slotDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        hour,
        parseInt(minutes)
      );

      // Calculate the time 1 hour from now
      const oneHourFromNow = new Date(currentTime);
      oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);

      // Compare the slot time to check if it's within the next 1 hour or if it's in the past for the selected date
      return (
        (currentDay === selectedDate.getDate() && slotDate <= oneHourFromNow) ||
        currentTime >= slotDate
      );
    },
    modalOpen() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.showModal();
      }
    },
    modalClose() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.close();
      }
    },
    async createUserAddress() {
      const requestData = {
        contact_name: this.first_name + " " + this.last_name,
        is_default: true,
        contact_number: this.user.mobile_number,
        address_line_1: this.house,
        address_line_2: this.address,
        landmark: this.town,
        pincode: this.pin_code,
        state: this.state,
        city: this.city,
        type: this.category,
      };
      const updateData = {
        first_name: this.first_name,
        last_name: this.last_name,
      };
      try {
        const response = await axios.post(
          "api/user/user-address/create",
          requestData
        );
        this.userAddresses.push(response.data.user_address);
        this.selectedAddress = response.data.user_address;
        if (this.user.name === " ") {
          axios
            .post("api/user/user/update", updateData)
            .then((response) => {
              if (
                "success" in response.data &&
                response.data["success"] == true
              ) {
                let user = this.$store.state.user;
                user.first_name = this.first_name;
                user.last_name = this.last_name;
                user.name = this.first_name + " " + this.last_name;
                this.$store.dispatch("setUser", user);
              }
            })
            .catch((error) => {
              // Handle error
              console.error("Error updating user", error);
            });
        }
        this.open = false;
        const modal = document.getElementById(this.modalId);
        if (modal) {
          modal.close();
        }
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Address added successfully!!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error("Error creating address:", error);
        // Handle error, show an error message, etc.
      }
    },
    resetForm() {
      this.first_name = "";
      this.last_name = "";
      this.mobile_number = "";
      this.house = "";
      this.address = "";
      this.town = "";
      this.pin_code = null;
      this.category = "";
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.close();
      }
    },
    validateMobileNumber(number) {
      // Regular expression to validate Indian mobile number format
      const mobileNumberRegex = /^[6-9]\d{9}$/;
      return mobileNumberRegex.test(number);
    },
  },
  setup() {
    const deleteModalRef = ref(null);

    const closeModalAdr = () => {
      const modal = document.getElementById("my_modal_9");
      if (modal) {
        modal.close();
      }
    };

    onClickOutside(deleteModalRef, (event) => {
      closeModalAdr();
      // Call the closeModal function when a click occurs outside the target element
    });

    return {
      deleteModalRef,
      closeModalAdr,
    };
  },
  computed: {
    searchValue() {
      return this.$store.state.searchValue;
    },
    packageData() {
      return this.$store.state.packageData;
    },
    sevenDaysInMonth() {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(this.currentDate.getDate() + i);
        days.push(date);
      }
      return days;
    },
    defaultSelectedButton() {
      const now = new Date();
      const hour = now.getHours();

      if (hour < 12) {
        return "Morning"; // It's morning
      } else if (hour < 17) {
        return "Afternoon"; // It's afternoon
      } else {
        return "Evening"; // It's evening
      }
    },
    user() {
      return this.$store.state.user;
    },
    selectedTimeSlots() {
      if (this.selectedButton) {
        return this.timeSlots.filter((x) => x.type == this.selectedButton);
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      const response = await axios.get(`api/user/pincode/getAll`);
      // Extract pincode values from the pincodes array
      const pincodes = response.data.pincodes.map(
        (pincodeObj) => pincodeObj.pincode
      );
      this.options = pincodes;

      const result = await axios.get(`api/user/user-address`);
      this.userAddresses = result.data.user_addresses;

      if (this.userAddresses.length === 0) {
        // If userAddresses is empty, set selectedAddress to an empty object
        this.errorMsg = "No data found";
      } else if (this.userAddresses.length === 1) {
        // If there's only one address, set it as the selectedAddress
        this.selectedAddress = this.userAddresses[0];
      } else {
        // If there are multiple addresses, find and set the default address
        const data = this.userAddresses.find(
          (address) => address.is_default === true
        );
        this.selectedAddress = data;
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      this.isLoading = false;
    }
    try {
      // Find the index of today's date in sevenDaysInMonth array
      const todayIndex = this.sevenDaysInMonth.findIndex((day) =>
        this.isToday(day)
      );

      if (todayIndex !== -1) {
        this.handleGetTime(this.sevenDaysInMonth[todayIndex], todayIndex);
      }
    } catch {
      console.error("error:", error);
    }
  },
};
</script>

<style>
@media (min-width: 100px) and (max-width: 770px) {
  .v-alert {
    padding: 2px;
  }
  .v-timeline--vertical.v-timeline
    .v-timeline-item:nth-child(2n + 1)
    .v-timeline-item__body {
    padding-inline-start: 5px;
  }
  .v-timeline--vertical.v-timeline.v-timeline--side-end
    .v-timeline-item
    .v-timeline-item__body {
    padding-inline-start: 5px;
  }
}

.v-timeline--vertical .v-timeline-item:last-child .v-timeline-divider__after {
  bottom: 30px;
}

.v-timeline--vertical.v-timeline
  .v-timeline-item:last-child
  .v-timeline-divider,
.v-timeline--vertical.v-timeline
  .v-timeline-item:last-child
  .v-timeline-item__body,
.v-timeline--vertical.v-timeline
  .v-timeline-item:last-child
  .v-timeline-item__opposite {
  padding-block-end: 0px;
}
/* .date-btn {
  background-color: #ff4a49;
  color: white;
} */

.slot-color {
  color: #f2ab3e;
}

.selected {
  color: #ff4a49;
}

.v-timeline-divider__dot--size-small {
  height: 20px;
  width: 20px;
}

.time-outline {
  border: 1px solid #ff4a49;
  color: #ff4a49;
}

.disable-btn {
  background-color: #ff8786;
  opacity: 0.3;
}
.disable-time {
  background-color: #d9d9d9;
  opacity: 0.3;
}
</style>
