<template>
  <div class="bg-[#F4F4F4] p-10">
    <div
      class="w-[850px] p-10 rounded-xl bg-[#FFFFFF] flex items-start justify-between">
      <div>
        <h1 class="text-[24px] font-medium">Verified Billing Address</h1>
        <p class="text-[24px] text-[#36314C]">
          A-304, 3rd floor, Eastern Business <br />
          District, LBS marg, Bhandup(W), <br />
          Mumbai - 400078
        </p>
        <h2 class="text-[24px] font-medium">GST number :</h2>
      </div>
      <div>
        <h1 class="text-[24px] font-medium">Delivery Address</h1>
        <p class="text-[24px] text-[#36314C]">
          A-304, 3rd floor, Eastern Business <br />
          District, LBS marg, Bhandup(W), <br />
          Mumbai - 400078
        </p>
      </div>
    </div>
  </div>
</template>
