<template>
  <div v-if="rentalSearch">
    <div class="bg-[#EAEAF3]">
      <Swiper
        :breakpoints="{
          0: {
            slidesPerView: 1.4,
            spaceBetween: 5,
          },
          412: {
            slidesPerView: 1.6,
            spaceBetween: 5,
          },
          600: {
            slidesPerView: 1.6,
            spaceBetween: 5,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1000: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
        }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :modules="modules"
        class="mySwiper">
        <swiper-slide>
          <div class="bg-[#EAEAF3] p-4">
            <div class="w-[234px] h-[70px] flex items-center">
              <div class="w-[140px] p-[4px] rounded-l-[5px] h-full bg-white">
                <div class="pl-[10px] mt-[10px]">
                  <h1
                    class="text-[13px] leading-[120%] baloo-font font-bold text-[#00A6BA]">
                    Cheapest prices <br />
                    for long <br />
                    durations
                  </h1>
                </div>
              </div>

              <div
                class="w-[140px] p-[4px] left-[110px] rounded-r-[5px] h-[70px] absolute bg-[#052364]"
                :style="' clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);'">
                <div class="pl-[30px]">
                  <h1 class="text-[13px] font-bold baloo-font text-white">
                    Book Cycles from
                  </h1>
                  <h2
                    class="text-[24px] ml-[10px] mt-[-7px] font-extrabold baloo-font text-[#00A7B9]">
                    ₹49
                    <span
                      class="text-[13px] font-bold baloo-font text-[#00A7B9]"
                      >/ day</span
                    >
                  </h2>
                </div>
                <div
                  class="text-[11px] rounded-[5px] mt-[-5px] cursor-pointer ml-[16px] text-center w-[112px] h-[16px] bg-[#E90979] font-bold baloo-font text-white">
                  For Min 1 month
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="bg-[#EAEAF3] p-4">
            <div class="w-[234px] h-[70px] flex items-center">
              <div
                class="w-[140px] p-[4px] rounded-l-[5px] h-full bg-[#00A6BA]">
                <div class="pl-[15px] mt-[4px]">
                  <h1 class="text-[13px] baloo-font font-bold text-white">
                    FLAT
                  </h1>
                  <h2
                    class="text-[20px] mt-[-8px] font-bold baloo-font text-white">
                    10% OFF
                  </h2>
                </div>
              </div>

              <div
                class="w-[140px] p-[4px] left-[110px] rounded-r-[5px] h-[70px] absolute bg-[#052364]"
                :style="' clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);'">
                <div class="pl-[27px] mt-[7px]">
                  <h1 class="text-[13px] font-bold baloo-font text-white">
                    Book Cycles from
                  </h1>
                  <h2
                    class="text-[24px] ml-[10px] mt-[-7px] font-extrabold baloo-font text-[#00A7B9]">
                    ₹49
                    <span
                      class="text-[13px] font-bold baloo-font text-[#00A7B9]"
                      >/ day</span
                    >
                  </h2>
                </div>
              </div>
              <div
                class="text-[11px] ml-[10px] mt-[40px] cursor-pointer absolute rounded-[5px] text-center w-[112px] h-[16px] bg-[#E90979] font-bold baloo-font text-white">
                For All new users
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="bg-[#EAEAF3] p-4">
            <div class="w-[234px] h-[70px] flex items-center">
              <div class="w-[140px] p-[4px] rounded-l-[5px] h-full bg-white">
                <div class="pl-[10px] mt-[10px]">
                  <h1
                    class="text-[13px] leading-[120%] baloo-font font-bold text-[#00A6BA]">
                    Cheapest prices <br />
                    for long <br />
                    durations
                  </h1>
                </div>
              </div>

              <div
                class="w-[140px] p-[4px] left-[110px] rounded-r-[5px] h-[70px] absolute bg-[#052364]"
                :style="' clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);'">
                <div class="pl-[30px]">
                  <h1 class="text-[13px] font-bold baloo-font text-white">
                    Book Cycles from
                  </h1>
                  <h2
                    class="text-[24px] ml-[10px] mt-[-7px] font-extrabold baloo-font text-[#00A7B9]">
                    ₹49
                    <span
                      class="text-[13px] font-bold baloo-font text-[#00A7B9]"
                      >/ day</span
                    >
                  </h2>
                </div>
                <div
                  class="text-[11px] rounded-[5px] mt-[-5px] cursor-pointer ml-[16px] text-center w-[112px] h-[16px] bg-[#E90979] font-bold baloo-font text-white">
                  For Min 1 month
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="bg-[#EAEAF3] p-4">
            <div class="w-[234px] h-[70px] flex items-center">
              <div
                class="w-[140px] p-[4px] rounded-l-[5px] h-full bg-[#00A6BA]">
                <div class="pl-[15px] mt-[4px]">
                  <h1 class="text-[13px] baloo-font font-bold text-white">
                    FLAT
                  </h1>
                  <h2
                    class="text-[20px] mt-[-8px] font-bold baloo-font text-white">
                    10% OFF
                  </h2>
                </div>
              </div>

              <div
                class="w-[140px] p-[4px] left-[110px] rounded-r-[5px] h-[70px] absolute bg-[#052364]"
                :style="' clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);'">
                <div class="pl-[27px] mt-[7px]">
                  <h1 class="text-[13px] font-bold baloo-font text-white">
                    Book Cycles from
                  </h1>
                  <h2
                    class="text-[24px] ml-[10px] mt-[-7px] font-extrabold baloo-font text-[#00A7B9]">
                    ₹49
                    <span
                      class="text-[13px] font-bold baloo-font text-[#00A7B9]"
                      >/ day</span
                    >
                  </h2>
                </div>
              </div>
              <div
                class="text-[11px] ml-[10px] mt-[40px] cursor-pointer absolute rounded-[5px] text-center w-[112px] h-[16px] bg-[#E90979] font-bold baloo-font text-white">
                For All new users
              </div>
            </div>
          </div>
        </swiper-slide>
      </Swiper>
    </div>

    <div
      class="bg-white p-[20px] block md:hidden lg:hidden w-full lg:w-[80%] mx-auto">
      <div class="flex items-center justify-between">
        <h1
          class="text-[12px] md:text-[18px] lg:text-[18px] font-semibold text-[#36314C]">
          {{ rentalSearch.pincodeRental }}
        </h1>
        <h1
          @click="openModalSearch"
          class="text-[12px] cursor-pointer md:text-[18px] lg:text-[18px] font-semibold text-[#F2AB3E]">
          Modify Search
        </h1>
      </div>
      <div class="bg-white mb-4 mt-[10px]">
        <div class="flex items-center justify-between">
          <div>
            <h1
              class="text-[12px] md:text-[18px] lg:text-[18px] font-medium text-[#36314C]">
              {{ dateFormatter(startDate) }}
            </h1>
          </div>
          <div
            class="bg-white w-[80px] text-center h-4 md:h-[25px] lg:h-[25px]">
            <h1
              class="text-[#71AE0D] text-[11px] md:text-[18px] lg:text-[18px] font-medium">
              {{ days }} days
            </h1>
          </div>
          <div>
            <h1
              class="text-[12px] md:text-[18px] lg:text-[18px] font-medium text-[#36314C]">
              {{ dateFormatter(endDate) }}
            </h1>
          </div>
        </div>
        <div
          class="w-[60%] mx-auto mt-[-8px] md:mt-[-14px] lg:mt-[-14px] border-dashed border-[1px] border-[#867EA8]"></div>
      </div>
    </div>
    <div
      class="bg-[#867EA8] block md:hidden lg:hidden h-[33px] md:h-[40px] lg:h-[40px] p-2 w-full">
      <h1
        class="text-[12px] md:text-[18px] lg:text-[18px] ml-[10px] text-white">
        {{ cycles?.length }} Results found for rental in
        {{ rentalSearch.pincodeRental }}
      </h1>
    </div>

    <v-container class="bg-[#F4F4F4]" fluid>
      <div class="hidden md:block lg:block">
        <div
          class="bg-white w-[93%] md:w-[1150px] lg:w-[1150px] mx-auto flex items-center flex-column relative justify-center rounded-[20px] py-[30px] my-[40px]">
          <h1
            class="text-[#211C37] text-center text-[16px] md:text-[20px] lg:text-[20px] font-medium mb-[20px]">
            Cycles Available Near You
          </h1>

          <div
            class="w-[100%] flex flex-col items-center justify-center gap-[10px]">
            <div
              class="pl-[13px] w-[80%] md:w-[60%] lg:w-[60%] border border-black rounded-[19px] sectionRentalHover md:pl-8 lg:pl-8 py-[5px]">
              <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                Servicable Pincodes
              </p>
              <h1
                @click="openModal"
                v-if="PinCode === null"
                class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                Select Pincode
              </h1>
              <h1
                @click="openModal"
                v-if="PinCode"
                class="text-[18px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                {{ PinCode }}
              </h1>
            </div>
            <div
              className="flex items-start w-[80%] md:w-[60%] lg:w-[60%] border border-black rounded-[19px] h-[53px] md:h-[80px] lg:h-[80px]">
              <div class="w-1/2 h-full">
                <div
                  class="pl-[13px] sectionHoverRight md:pl-8 lg:pl-8 py-[5px]">
                  <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                    Start Date
                  </p>
                  <VueDatePicker
                    v-model="startDate"
                    :enable-time-picker="false"
                    auto-apply
                    :format="dateFormatter"
                    :clearable="false"
                    :min-date="new Date()">
                    <template #dp-input="{ value }">
                      <div
                        class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                        {{ value }}
                      </div>
                    </template>
                  </VueDatePicker>
                </div>
              </div>
              <div className="divider divider-horizontal"></div>
              <div class="w-1/2 h-full">
                <div class="py-[5px] sectionHoverLeft pl-3">
                  <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                    End Date
                  </p>
                  <VueDatePicker
                    v-model="endDate"
                    :enable-time-picker="false"
                    auto-apply
                    :format="dateFormatter"
                    :clearable="false"
                    :min-date="new Date()">
                    <template #dp-input="{ value }">
                      <div
                        class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                        {{ value }}
                      </div>
                    </template>
                  </VueDatePicker>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-[28px]">
            <button
              @click="handleSearch"
              class="text-[14px] md:text-[22px] lg:text-[22px] rounded-[20px] text-white w-[180px] md:w-[280px] lg:w-[280px] h-[40px] md:h-[50px] lg:h-[50px] font-bold"
              :style="{
                background:
                  PinCode === null
                    ? '#DEDEDE'
                    : 'linear-gradient(258deg, #d70d6e 8.72%, #3e0f62 91.28%)',
              }"
              :disabled="PinCode === null">
              SEARCH
            </button>
          </div>
        </div>
      </div>

      <v-row v-if="shouldShowData">
        <v-col id="filters" class="pt-12 hidden md:block lg:block" md="3">
          <div>
            <div class="flex items-center mb-4">
              <div class="flex-grow" style="font-weight: 600; font-size: 20px">
                FILTERS
              </div>
              <div
                class="text-[18px] text-[#36314C] cursor-pointer"
                @click="resetFilters">
                Reset all
              </div>
            </div>

            <LoadingSpinner2
              v-if="filters_loading"
              :size="50"
              :width="5"
              :style="{ minHeight: '200px' }" />

            <v-card v-else>
              <v-card-text class="px-6">
                <div>
                  <div v-if="available_filters.companies" class="my-4">
                    <div style="font-size: 20px; font-weight: 500">Brands</div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.companies"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                        v-model="filter.companies"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>

                  <div v-if="available_filters.cycle_categories" class="my-4">
                    <div style="font-size: 20px; font-weight: 500">
                      Category
                    </div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_categories"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_categories"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>

                  <div v-if="available_filters.age" class="my-4">
                    <div style="font-size: 20px; font-weight: 500">Age</div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.age"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.age"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>

                  <div
                    v-if="available_filters.cycle_frame_materials"
                    class="my-4">
                    <div style="font-size: 20px; font-weight: 500">
                      Frame Material
                    </div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_frame_materials"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_frame_materials"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>
                  <div
                    v-if="available_filters.cycle_frame_materials"
                    class="my-4">
                    <div style="font-size: 20px; font-weight: 500">
                      Frame Size
                    </div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_frame_sizes"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_frame_sizes"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>
                  <div
                    v-if="available_filters.cycle_frame_materials"
                    class="my-4">
                    <div style="font-size: 20px; font-weight: 500">Gears</div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_speeds"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_speeds"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>
                  <div
                    v-if="available_filters.cycle_frame_materials"
                    class="my-4">
                    <div style="font-size: 20px; font-weight: 500">
                      Cycle Type
                    </div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_types"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_types"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>
                  <div
                    v-if="available_filters.cycle_frame_materials"
                    class="my-4">
                    <div style="font-size: 20px; font-weight: 500">
                      Wheel Size
                    </div>
                    <div style="padding-left: 8px; padding-top: 10px">
                      <v-checkbox
                        v-for="option in available_filters.cycle_wheel_sizes"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                        v-model="filter.cycle_wheel_sizes"
                        density="compact"
                        hide-details
                        style="font-weight: 600; color: #867ea8"
                        :value-comparator="checkboxValueComparator" />
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>

        <v-col id="cycle-grid" md="9" class="pt-[35px]">
          <div v-if="cycles" class="hidden md:block lg:block">
            <div class="px-2 flex items-center justify-between mb-8 mt-1 gap-2">
              <div class="text-[20px] font-semibold text-[#867EA8]">
                {{ cycles.length }} Cycles Available in
                {{ $route.query.pincode }}
              </div>
              <div class="flex items-center gap-2">
                <div class="text-[18px] text-[#867EA8]">Sort By:</div>
                <div>
                  <select
                    class="my-select_select text-[18px] select font-semibold bg-[#f4f4f4]">
                    <option disabled selected>Popularity</option>
                    <option>Popularity</option>
                    <option>Random</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <LoadingSpinner2
            v-if="cycles_loading"
            :style="{ minHeight: '260px' }" />

          <div v-else>
            <v-row>
              <v-col v-for="cycle in cycles" :key="cycle.name" cols="12" md="4">
                <div
                  class="bg-white h-[220px] md:h-[330px] lg:h-[330px] p-4 rounded-[10px] w-full md:w-[320px] lg:w-[320px] flex md:flex-col lg:flex-col items-center">
                  <div class="w-[70%] md:w-full lg:w-full relative h-full">
                    <h1
                      class="text-[14px] text-center md:text-[18px] lg:text-[18px] font-semibold">
                      {{ cycle.name }}
                    </h1>
                    <div class="bestseller rotate-90 uppercase z-10">
                      <h1 class="text-white text-[14px] -rotate-180">
                        {{ cycle.type.value }}
                      </h1>
                    </div>
                    <img
                      class="h-[170px] md:h-[200px] lg:h-[200px] mx-auto has-img-fallback"
                      :src="
                        cycle.images && cycle.images[0]
                          ? cycle.images[0].thumbnail_path
                          : ''
                      " />
                  </div>
                  <div class="w-[30%] md:w-full lg:w-full pl-[5px] h-full">
                    <div
                      class="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center gap-2">
                      <div class="block md:hidden lg:hidden">
                        <div class="flex items-start gap-[5px]">
                          <h2 class="text-[10px] mt-[2px] text-[#867EA8]">
                            From
                          </h2>
                          <h1
                            class="text-[16px] font-extrabold text-[#6D3186] text-center">
                            ₹ {{ cycle.price * days }}
                          </h1>
                        </div>
                        <h1 class="text-[#71AE0D] text-[11px]">
                          Rent for {{ days }} days
                        </h1>
                      </div>
                      <div
                        v-if="cycle.type === 'Non-Gear'"
                        class="bg-[#F2AB3E] rounded-[5px] p-[3px] bg-opacity-[0.3] w-[76px] h-[22px]">
                        <h1 class="text-[#211C37] text-[11px] text-center">
                          {{ cycle.type }}
                        </h1>
                      </div>
                      <div
                        v-if="cycle.type === 'Geared'"
                        class="bg-[#71AE0D] rounded-[5px] p-[3px] bg-opacity-[0.3] w-[76px] h-[22px]">
                        <h1 class="text-[#211C37] text-[11px] text-center">
                          {{ cycle.type }}
                        </h1>
                      </div>
                      <div
                        class="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center gap-1">
                        <div
                          v-if="cycle.age?.value"
                          class="flex gap-1 items-center">
                          <img :src="ageIcon" />
                          <h1 class="text-[11px] font-medium text-[#999797]">
                            {{ cycle.age.value }}
                          </h1>
                        </div>
                        <div
                          v-if="cycle.frame_size"
                          class="flex gap-1 my-2 items-center">
                          <img :src="frameIcon" />
                          <h1 class="text-[11px] font-medium text-[#999797]">
                            {{ cycle.frame_size.value }}
                          </h1>
                        </div>
                        <div
                          v-if="cycle.wheel_size"
                          class="flex gap-1 items-center">
                          <img :src="wheelIcon" />
                          <h1 class="text-[11px] font-medium text-[#999797]">
                            {{ cycle.wheel_size.value }}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-col md:flex-row lg:flex-row items-center justify-between">
                      <div class="hidden md:block lg:block">
                        <div class="flex items-start gap-[5px]">
                          <h2 class="text-[10px] mt-[2px] text-[#867EA8]">
                            From
                          </h2>
                          <h1
                            class="text-[16px] font-extrabold text-[#6D3186] text-center">
                            ₹ {{ cycle.price * days }}
                          </h1>
                        </div>
                        <h1 class="text-[#71AE0D] text-[11px]">
                          Rent for {{ days }} days
                        </h1>
                      </div>
                      <button
                        @click="openListing(cycle.id)"
                        class="bg-[#6D3186] rounded-[20px] w-[90px] mt-[6px] h-[30px] text-center text-[14px] font-extrabold text-white">
                        DETAILS
                      </button>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-pagination
              v-model="current_page"
              :length="total_pages"
              @update:modelValue="onPageChange"
              :style="{
                marginTop: '40px',
              }" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <footer
      class="text-center block md:hidden lg:hidden mb-4 p-4 lg:px-36 sticky bottom-0 md:static lg:static z-[90]"
      style="
        border-top: 2px solid var(--secondary-1, #eaeaf3);
        background: var(--text-1, #fff);
        box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.05);
      ">
      <div class="flex items-center px-4 justify-between">
        <div
          @click="openModalRental"
          class="flex items-center gap-1 cursor-pointer">
          <h1
            class="text-[12px] md:text-[18px] lg:text-[18px] font-medium text-[#36314C]">
            Sort
          </h1>
          <img :src="sortIcon" />
        </div>
        <div
          @click="modalOpen122"
          class="flex items-center gap-1 cursor-pointer">
          <img :src="filterIcon" />
          <h1
            class="text-[12px] md:text-[18px] lg:text-[18px] font-medium text-[#36314C]">
            Filter
          </h1>
        </div>
      </div>
    </footer>

    <dialog :id="modalIdRentalSearch" class="modal modal-top">
      <div
        class="modal-box w-full p-0 rounded-none bg-transparent h-full max-h-full max-w-full">
        <form method="dialog">
          <button
            class="btn btn-sm btn-circle btn-ghost absolute right-3 top-3"
            @click="closeModalSearch">
            ✕
          </button>
        </form>

        <div
          class="bg-white w-full h-[40%] md:h-[60%] lg:h-[60%] rounded-b-[10px] flex flex-col justify-center items-center">
          <h1
            class="text-[#211C37] mb-[20px] text-center text-[16px] md:text-[20px] lg:text-[20px] font-medium">
            Cycles Available Near You
          </h1>
          <div
            className="flex items-start mb-[8px]  w-[290px] md:w-[550px] lg:w-[550px] rounded-[20px] h-[53px] md:h-[80px] lg:h-[80px] border">
            <div class="w-full h-full">
              <div
                class="pl-[13px] sectionRentalHover md:pl-8 lg:pl-8 py-[5px]">
                <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                  Servicable Pincodes
                </p>
                <h1
                  @click="openModal"
                  class="text-[18px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                  {{ PinCode }}
                </h1>
              </div>
            </div>
          </div>
          <div
            className="flex items-start  w-[290px] md:w-[550px] lg:w-[550px] rounded-[20px] h-[53px] md:h-[80px] lg:h-[80px] border">
            <div class="w-1/2 h-full">
              <div class="pl-[13px] sectionHoverRight md:pl-8 lg:pl-8 py-[5px]">
                <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                  Start Date
                </p>
                <VueDatePicker
                  v-model="startDate"
                  :enable-time-picker="false"
                  auto-apply
                  :format="dateFormatter"
                  :clearable="false"
                  :min-date="new Date()">
                  <template #dp-input="{ value }">
                    <div
                      class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                      {{ value }}
                    </div>
                  </template>
                </VueDatePicker>
              </div>
            </div>
            <div className="divider divider-horizontal"></div>
            <div class="w-1/2 h-full">
              <div class="py-[5px] sectionHoverLeft pl-3">
                <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                  End Date
                </p>
                <VueDatePicker
                  v-model="endDate"
                  :enable-time-picker="false"
                  auto-apply
                  :format="dateFormatter"
                  :clearable="false"
                  :min-date="new Date()">
                  <template #dp-input="{ value }">
                    <div
                      class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                      {{ value }}
                    </div>
                  </template>
                </VueDatePicker>
              </div>
            </div>
          </div>
          <div class="text-center mt-[20px] md:mt-[15px] lg:mt-[15px]">
            <button
              @click="handleSearch"
              class="text-[14px] md:text-[22px] lg:text-[22px] rounded-[20px] text-white w-[180px] md:w-[280px] lg:w-[280px] h-[40px] md:h-[50px] lg:h-[50px] font-bold"
              style="
                background: linear-gradient(
                  258deg,
                  #d70d6e 8.72%,
                  #3e0f62 91.28%
                );
              ">
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </dialog>
    <dialog ref="modal" class="modal">
      <form method="dialog" class="modal-box">
        <button
          class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          @click="closeModal">
          ✕
        </button>
        <ul class="menu">
          <div class="mb-4">
            <h1
              class="text-[18px] border-none md:text-[28px] lg:text-[28px] font-medium">
              Serviceable Pincodes
            </h1>
          </div>
          <hr class="mb-4" />
          <li v-for="(option, i) in options" :key="i">
            <h3 class="text-lg font-medium" @click="handleOptionClick(option)">
              {{ option }}
            </h3>
          </li>
        </ul>
      </form>
    </dialog>
    <dialog :id="modalIdRental" class="modal modal-bottom">
      <div
        class="modal-box w-full h-[50%] max-h-[50%] max-w-full"
        ref="sortClose">
        <form method="dialog">
          <button
            class="btn btn-sm bg-white btn-circle btn-ghost absolute right-2 top-2"
            @click="closeModalRental">
            ✕
          </button>
        </form>
        <div class="flex items-center gap-2 mb-4">
          <img :src="sortRoundIcon" />
          <h3 class="font-semibold text-[18px]">SORT BY</h3>
        </div>
        <a
          class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
          <h1 class="text-[16px] font-medium text-[#867EA8]">Featured</h1>
        </a>
        <a
          class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
          <h1 class="text-[16px] font-medium text-[#FF4A49]">New Arrivals</h1>
        </a>
        <a
          class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
          <h1 class="text-[16px] font-medium text-[#867EA8]">Discount</h1>
        </a>
        <a
          class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
          <h1 class="text-[16px] font-medium text-[#867EA8]">
            Price: High to Low
          </h1>
        </a>
        <a
          class="block border border-slate-800 rounded-lg mb-2 px-4 text-[14px] cursor-pointer py-2 hover:bg-gray-100">
          <h1 class="text-[16px] font-medium text-[#867EA8]">
            Price: Low to High
          </h1>
        </a>
      </div>
    </dialog>
    <dialog :id="modalId" className="modal modal-top">
      <div
        method="dialog"
        className="modal-box p-0 bg-white w-full max-w-full h-full max-h-full ">
        <div>
          <div class="flex p-4 shadow-sm items-center justify-between">
            <div class="flex cursor-pointer items-center gap-1">
              <img :src="filterIcon" />
              <h1 class="text-[12px] font-medium text-[#36314C]">
                FILTERS <span class="text-[#B7B9BE] ml-2">(77 products)</span>
              </h1>
            </div>
            <h1
              @click="clearAll"
              class="text-[12px] cursor-pointer font-medium text-[#FF4A49]">
              CLEAR ALL
            </h1>
          </div>
          <div class="w-full mb-20 flex items-start">
            <div class="w-[30%] bg-[#EAEAF3]">
              <div
                @click="activeTab = 'Daily'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Daily' }">
                <h1 class="text-[12px] text-[#36314C]">Rental Daily</h1>
              </div>
              <div
                @click="activeTab = 'Monthly'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Monthly' }">
                <h1 class="text-[12px] text-[#36314C]">Rental Monthly</h1>
              </div>
              <div
                @click="activeTab = 'Type'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Type' }">
                <h1 class="text-[12px] text-[#36314C]">Type</h1>
              </div>
              <div
                @click="activeTab = 'Size'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Size' }">
                <h1 class="text-[12px] text-[#36314C]">Size</h1>
              </div>
              <div
                @click="activeTab = 'Gender'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Gender' }">
                <h1 class="text-[12px] text-[#36314C]">Gender</h1>
              </div>
              <div
                @click="activeTab = 'Brand'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Brand' }">
                <h1 class="text-[12px] text-[#36314C]">Brand</h1>
              </div>
              <div
                @click="activeTab = 'Color'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Color' }">
                <h1 class="text-[12px] text-[#36314C]">Color</h1>
              </div>
              <div
                @click="activeTab = 'Age'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Age' }">
                <h1 class="text-[12px] text-[#36314C]">Age</h1>
              </div>
              <div
                @click="activeTab = 'Deposit'"
                class="p-4 border-b text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Deposit' }">
                <h1 class="text-[12px] text-[#36314C]">Deposit Amount</h1>
              </div>
              <div
                @click="activeTab = 'Delay'"
                class="p-4 text-start cursor-pointer"
                :class="{ 'bg-white': activeTab === 'Delay' }">
                <h1 class="text-[12px] text-[#36314C]">Delay Penalty</h1>
              </div>
            </div>
            <div class="w-[70%] p-4 bg-white">
              <div
                v-if="activeTab === 'Daily'"
                v-for="(item, index) in items"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @change="handleDaily(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
                <h1 class="text-[#CBCED6] text-[11px] font-medium">
                  {{ item.total }}
                </h1>
              </div>
              <div
                v-if="activeTab === 'Brand'"
                v-for="(item, index) in brands"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @change="handleBrand(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Color'"
                v-for="(item, index) in colors"
                :key="index"
                class="flex items-center gap-8 border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @change="handleColor(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
                <div
                  class="h-[15px] w-[15px]"
                  :class="{
                    'bg-[#FF0000]': item.label === 'Red',
                    'bg-[#0000FF]': item.label === 'Blue',
                    'bg-[#008000]': item.label === 'Green',
                    'bg-[#FFFF00]': item.label === 'Yellow',
                    'bg-[#000000]': item.label === 'Black',
                    'bg-[#808080]': item.label === 'Grey',
                  }"></div>
              </div>
              <div
                v-if="activeTab === 'Size'"
                v-for="(item, index) in sizes"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @change="handleSizes(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Type'"
                v-for="(item, index) in types"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @click="handleType(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Monthly'"
                v-for="(item, index) in rentalMonthly"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @change="handleMonthly(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Gender'"
                v-for="(item, index) in Gender"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @click="handleGender(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Age'"
                v-for="(item, index) in ages"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @click="handleAge(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Deposit'"
                v-for="(item, index) in deposits"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @click="handleDeposit(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div
                v-if="activeTab === 'Delay'"
                v-for="(item, index) in delays"
                :key="index"
                class="flex items-center justify-between border-b border-[#CBCED6] p-4">
                <div>
                  <input
                    type="checkbox"
                    :id="'checkbox' + index"
                    v-model="item.checked"
                    @click="handleDeposit(item.label)"
                    class="form-checkbox h-3 w-3 cursor-pointer" />
                  <label
                    :for="'checkbox' + index"
                    class="ml-4 text-[12px] font-medium text-[#000000]"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <footer
            class="text-center p-4 sticky bottom-0 z-[90]"
            style="
              border-top: 1px solid var(--secondary-1, #eaeaf3);
              background: var(--text-1, #fff);
            ">
            <div class="flex items-center px-4 justify-between">
              <button
                @click="modalClose122"
                class="text-[15px] w-[124px] h-[35px] md:text-[18px] lg:text-[18px] font-medium text-[#36314C]"
                style="
                  border-radius: 5px;
                  border: 1px solid var(--STROKE, #cbced6);
                ">
                Cancel
              </button>
              <button
                class="text-[15px] rounded-[5px] bg-[#FF4A49] w-[124px] h-[35px] md:text-[18px] lg:text-[18px] font-medium text-white">
                Apply Filter
              </button>
            </div>
          </footer>
        </div>
      </div>
    </dialog>
  </div>

  <div v-else class="bg-white p-4">
    <div
      class="bg-white w-[93%] md:w-[1150px] lg:w-[1150px] mx-auto flex items-center flex-column relative justify-center rounded-[20px] py-[30px] my-[40px]">
      <h1
        class="text-[#211C37] text-center text-[16px] md:text-[20px] lg:text-[20px] font-medium mb-[20px]">
        Cycles Available Near You
      </h1>

      <div
        class="w-[100%] flex flex-col items-center justify-center gap-[10px]">
        <div
          class="pl-[13px] w-[80%] md:w-[60%] lg:w-[60%] border border-black rounded-[19px] sectionRentalHover md:pl-8 lg:pl-8 py-[5px]">
          <p class="text-[12px] md:text-[16px] lg:text-[16px]">
            Servicable Pincodes
          </p>
          <h1
            @click="openModal"
            v-if="PinCode === null"
            class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
            Select Pincode
          </h1>
          <h1
            @click="openModal"
            v-if="PinCode"
            class="text-[18px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
            {{ PinCode }}
          </h1>
        </div>
        <div
          className="flex items-start w-[80%] md:w-[60%] lg:w-[60%] border border-black rounded-[19px] h-[53px] md:h-[80px] lg:h-[80px]">
          <div class="w-1/2 h-full">
            <div class="pl-[13px] sectionHoverRight md:pl-8 lg:pl-8 py-[5px]">
              <p class="text-[12px] md:text-[16px] lg:text-[16px]">
                Start Date
              </p>
              <VueDatePicker
                v-model="startDate"
                :enable-time-picker="false"
                auto-apply
                :format="dateFormatter"
                :clearable="false"
                :min-date="new Date()">
                <template #dp-input="{ value }">
                  <div
                    class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                    {{ value }}
                  </div>
                </template>
              </VueDatePicker>
            </div>
          </div>
          <div className="divider divider-horizontal"></div>
          <div class="w-1/2 h-full">
            <div class="py-[5px] sectionHoverLeft pl-3">
              <p class="text-[12px] md:text-[16px] lg:text-[16px]">End Date</p>
              <VueDatePicker
                v-model="endDate"
                :enable-time-picker="false"
                auto-apply
                :format="dateFormatter"
                :clearable="false"
                :min-date="new Date()">
                <template #dp-input="{ value }">
                  <div
                    class="text-[16px] cursor-pointer md:text-[28px] lg:text-[28px] text-[#36314C] font-bold">
                    {{ value }}
                  </div>
                </template>
              </VueDatePicker>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-[28px]">
        <button
          @click="handleSearch"
          class="text-[14px] md:text-[22px] lg:text-[22px] rounded-[20px] text-white w-[180px] md:w-[280px] lg:w-[280px] h-[40px] md:h-[50px] lg:h-[50px] font-bold"
          :style="{
            background:
              PinCode === null
                ? '#DEDEDE'
                : 'linear-gradient(258deg, #d70d6e 8.72%, #3e0f62 91.28%)',
          }"
          :disabled="PinCode === null">
          SEARCH
        </button>
      </div>
    </div>
    <dialog ref="modal" class="modal">
      <form method="dialog" class="modal-box">
        <button
          class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          @click="closeModal">
          ✕
        </button>
        <ul class="menu">
          <div class="mb-4">
            <h1
              class="text-[18px] border-none md:text-[28px] lg:text-[28px] font-medium">
              Serviceable Pincodes
            </h1>
          </div>
          <hr class="mb-4" />
          <li v-for="(option, i) in options" :key="i">
            <h3
              class="text-lg font-medium"
              @click="handleOptionClickNew(option)">
              {{ option }}
            </h3>
          </li>
        </ul>
      </form>
    </dialog>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import Datepicker from "vue-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import frameIcon from "@/assets/temp/frameIcon.svg";
import ageIcon from "@/assets/temp/ageIcon.svg";
import wheelIcon from "@/assets/temp/wheelIcon.svg";
import sortIcon from "@/assets/temp/sortIcon.svg";
import filterIcon from "@/assets/temp/filterIcon.svg";
import sortRoundIcon from "@/assets/temp/sortRoundIcon.svg";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import LoadingSpinner2 from "./../components/LoadingSpinner2.vue";
import Cookies from "js-cookie";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Datepicker,
    LoadingSpinner2,
  },
  data() {
    return {
      cycles: null,
      frameIcon,
      ageIcon,
      sortIcon,
      wheelIcon,
      sortRoundIcon,
      filterIcon,
      total_pages: 0,
      current_page: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 0,

      cycles_loading: false,

      modalId: "my_modal_122",
      modalIdRental: "my_modal_7",
      modalIdRentalSearch: "my_modal_14",
      options: [],
      activeTab: "Type",
      checkedItems: [],
      checkedSizes: [],
      checkedMonthly: [],
      checkedBrand: [],
      checkedColor: [],
      genderOption: null,
      ageOption: null,
      depositOption: null,
      delayOption: null,
      type: null,

      PinCode: this.$route.query?.pincode ?? null,
      startDate: this.$dayjs().add(1, "day").toDate(),
      endDate: this.$dayjs().add(2, "day").toDate(),

      available_filters: {
        companies: null,
        cycle_categories: null,
        cycle_frame_materials: null,
        cycle_frame_sizes: null,
        cycle_speeds: null,
        cycle_types: null,
        cycle_wheel_sizes: null,
        age: null,
      },

      filter: {
        companies: this.$route?.query?.companies?.split(",") ?? [],
        cycle_categories:
          this.$route?.query?.cycle_categories?.split(",") ?? [],
        cycle_frame_materials:
          this.$route?.query?.cycle_frame_materials?.split(",") ?? [],
        cycle_frame_sizes:
          this.$route?.query?.cycle_frame_sizes?.split(",") ?? [],
        cycle_speeds: this.$route?.query?.cycle_speeds?.split(",") ?? [],
        cycle_types: this.$route?.query?.cycle_types?.split(",") ?? [],
        cycle_wheel_sizes:
          this.$route?.query?.cycle_wheel_sizes?.split(",") ?? [],
        age: this.$route?.query?.age?.split(",") ?? [],
      },

      filters_loading: false,

      items: [
        { label: "₹ 50 - ₹ 199", checked: false, total: 24 },
        { label: "₹ 200 - ₹ 399", checked: false, total: 12 },
        { label: "₹ 400 - ₹ 599", checked: false, total: 20 },
        { label: "₹ 600 - ₹ 799", checked: false, total: 15 },
        { label: "₹ 800 - ₹ 1000", checked: false, total: 33 },
      ],
      sizes: [
        { label: 'M	17.5"	161.0 - 172.0 cm', checked: false },
        { label: 'M/L	18.5"	165.5 - 179.0 cm', checked: false },
        { label: 'L	19.5"	177.0 - 188.0 cm', checked: false },
        { label: 'XL	21.5"	186.0 - 196.0 cm', checked: false },
      ],
      rentalMonthly: [
        { label: "₹2000 - ₹5000", checked: false },
        { label: "₹5001 - ₹7000", checked: false },
        { label: "₹7001 and beyond", checked: false },
      ],
      Gender: [
        { label: "Female", checked: false },
        { label: "Male", checked: false },
        { label: "Unisex", checked: false },
      ],
      types: [
        { label: "Geared", checked: false },
        { label: "Non-Geared", checked: false },
        { label: "E-bike (coming soon)", checked: false },
      ],
      ages: [
        { label: "3yrs - 5yrs", checked: false },
        { label: "6yrs - 12yrs", checked: false },
        { label: "12yrs and beyond", checked: false },
      ],
      deposits: [
        { label: "₹1000 - ₹2999", checked: false },
        { label: "₹3000 - ₹4999", checked: false },
        { label: "₹5000 and beyond", checked: false },
      ],
      delays: [
        { label: "₹500 - ₹1999", checked: false },
        { label: "₹2000 and beyond", checked: false },
      ],
      colors: [
        { label: "Red", checked: false },
        { label: "Blue", checked: false },
        { label: "Green", checked: false },
        { label: "Yellow", checked: false },
        { label: "Black", checked: false },
        { label: "Grey", checked: false },
      ],
      brands: [
        { label: "Angry Birds", checked: false },
        { label: "Atlas", checked: false },
        { label: "Author", checked: false },
        { label: "Bergamont", checked: false },
        { label: "Bianchi", checked: false },
        { label: "BSA", checked: false },
        { label: "BMC", checked: false },
        { label: "Btwin", checked: false },
        { label: "Cannondale", checked: false },
        { label: "Cervelo", checked: false },
        { label: "Cosmic", checked: false },
        { label: "Colnago", checked: false },
        { label: "Cube", checked: false },
        { label: "HLX - NMC", checked: false },
        { label: "Hollicy", checked: false },
        { label: "EZ Playmates", checked: false },
        { label: "Dahon", checked: false },
        { label: "Ducati", checked: false },
        { label: "Decathlon", checked: false },
        { label: "Firefox", checked: false },
        { label: "FitTrip", checked: false },
        { label: "Frog", checked: false },
        { label: "Focus", checked: false },
        { label: "Fomas", checked: false },
        { label: "Freemotion", checked: false },
        { label: "Fuji", checked: false },
        { label: "Fyxation", checked: false },
        { label: "Giant", checked: false },
        { label: "Ghost", checked: false },
        { label: "Goldie", checked: false },
        { label: "GT", checked: false },
      ],
    };
  },
  methods: {
    checkboxValueComparator(a, b) {
      return a == b;
    },
    modalOpen122() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.showModal();
      }
    },
    dateFormatter(date) {
      return this.$dayjs(date).format("DD MMM");
    },
    modalClose122() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.close();
      }
    },
    openModalSearch() {
      const modal = document.getElementById(this.modalIdRentalSearch);
      if (modal) {
        modal.showModal();
      }
    },

    handleSearchNew() {
      const startDate = new Date(this.newStartDate);
      const endDate = new Date(this.newEndDate);

      const startDay = startDate.getDate();
      const startMonth = startDate.toLocaleString("default", {
        month: "short",
      });
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.toLocaleString("default", { month: "short" });
      const endYear = endDate.getFullYear();

      const startFormatted = `${startDay} ${startMonth} ${startYear}`;
      const endFormatted = `${endDay} ${endMonth} ${endYear}`;

      const pincodeRental = this.PinCode;

      const rentalSearch = {
        startFormatted,
        endFormatted,
        pincodeRental,
      };

      if (pincodeRental === null) {
        alert("Please fill up the form then click the Button ");
      } else {
        Promise.all([
          this.$store.dispatch("setRentalValue", rentalSearch),
        ]).then(() => {
          this.$router.push({ name: "rental-listing" });
        });
      }
    },
    handleSearch() {
      console.log("handleSearch");

      const pincodeRental = this.PinCode;

      if (
        pincodeRental === null ||
        this.startDate === null ||
        this.endDate === null
      ) {
        return alert("Please fill up the form then click the Button ");
      }

      let startFormatted = dayjs(this.startDate).format("YYYY-MM-DD");
      let endFormatted = dayjs(this.endDate).format("YYYY-MM-DD");

      const rentalSearch = {
        startFormatted,
        endFormatted,
        pincodeRental,
      };

      this.$store.dispatch("setRentalValue", rentalSearch).then(() => {
        this.$router.push({
          name: "rental-listing",
          query: {
            pincode: pincodeRental,
            startDate: dayjs(this.startDate).format("YYYY-MM-DD"),
            endDate: dayjs(this.endDate).format("YYYY-MM-DD"),
          },
          hash: "#cycle-grid",
        });
      });
    },
    handleUpdateSearchValue() {
      let startFormatted = dayjs(this.rentalSearch.startFormatted).format(
        "YYYY-MM-DD"
      );
      let endFormatted = dayjs(this.rentalSearch.endFormatted).format(
        "YYYY-MM-DD"
      );

      const pincodeRental = this.rentalSearch.pincodeRental;

      console.log(pincodeRental);

      const rentalSearch = {
        startFormatted,
        endFormatted,
        pincodeRental,
      };

      if (pincodeRental === null) {
        alert("Please fill up the form then click the Button ");
      } else {
        Promise.all([
          this.$store.dispatch("setRentalValue", rentalSearch),
        ]).then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Updated successfully!!",
            showConfirmButton: false,
            timer: 1500,
          });
          const modal = document.getElementById(this.modalIdRentalSearch);
          if (modal) {
            modal.close();
          }
        });
      }
    },

    closeModalSearch() {
      const modal = document.getElementById(this.modalIdRentalSearch);
      if (modal) {
        modal.close();
      }
    },
    openModalRental() {
      const modal = document.getElementById(this.modalIdRental);
      if (modal) {
        modal.showModal();
      }
    },
    closeModalRental() {
      const modal = document.getElementById(this.modalIdRental);
      if (modal) {
        modal.close();
      }
    },
    openModal() {
      this.$refs.modal.showModal();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    handleDaily(label) {
      // Find the checked item and add or remove it from the checkedItems array
      const item = this.items.find((item) => item.label === label);
      if (item) {
        if (item.checked) {
          this.checkedItems.push(label);
        } else {
          const index = this.checkedItems.indexOf(label);
          if (index !== -1) {
            this.checkedItems.splice(index, 1);
          }
        }
      }
      console.log(this.checkedItems);
    },
    handleSizes(label) {
      // Find the checked item and add or remove it from the checkedSizes array
      const item = this.sizes.find((item) => item.label === label);
      if (item) {
        if (item.checked) {
          this.checkedSizes.push(label);
        } else {
          const index = this.checkedSizes.indexOf(label);
          if (index !== -1) {
            this.checkedSizes.splice(index, 1);
          }
        }
      }
      console.log(this.checkedSizes);
    },
    handleMonthly(label) {
      // Find the checked item and add or remove it from the checkedMonthly array
      const item = this.rentalMonthly.find((item) => item.label === label);
      if (item) {
        if (item.checked) {
          this.checkedMonthly.push(label);
        } else {
          const index = this.checkedMonthly.indexOf(label);
          if (index !== -1) {
            this.checkedMonthly.splice(index, 1);
          }
        }
      }
      console.log(this.checkedMonthly);
    },
    handleColor(label) {
      // Find the checked item and add or remove it from the checkedColor array
      const item = this.colors.find((item) => item.label === label);
      if (item) {
        if (item.checked) {
          this.checkedColor.push(label);
        } else {
          const index = this.checkedColor.indexOf(label);
          if (index !== -1) {
            this.checkedColor.splice(index, 1);
          }
        }
      }
      console.log(this.checkedColor);
    },
    handleBrand(label) {
      // Find the checked item and add or remove it from the checkedBrand array
      const item = this.brands.find((item) => item.label === label);
      if (item) {
        if (item.checked) {
          this.checkedBrand.push(label);
        } else {
          const index = this.checkedBrand.indexOf(label);
          if (index !== -1) {
            this.checkedBrand.splice(index, 1);
          }
        }
      }
      console.log(this.checkedBrand);
    },
    handleGender(label) {
      this.genderOption = label;
      console.log(this.genderOption);
    },
    handleAge(label) {
      this.ageOption = label;
      console.log(this.ageOption);
    },
    handleType(label) {
      this.type = label;
      console.log(this.type);
    },
    handleDelay(label) {
      this.delayOption = label;
      console.log(this.delayOption);
    },
    handleDeposit(label) {
      this.depositOption = label;
      console.log(this.depositOption);
    },
    clearAll() {
      this.items.forEach((item) => (item.checked = false));
      this.sizes.forEach((item) => (item.checked = false));
      this.rentalMonthly.forEach((item) => (item.checked = false));
      this.colors.forEach((item) => (item.checked = false));
      this.brands.forEach((item) => (item.checked = false));
      this.Gender.forEach((item) => (item.checked = false));
      this.types.forEach((item) => (item.checked = false));
      this.ages.forEach((item) => (item.checked = false));
      this.deposits.forEach((item) => (item.checked = false));
      this.delays.forEach((item) => (item.checked = false));
      this.checkedItems = [];
      this.checkedSizes = [];
      this.checkedMonthly = [];
      this.checkedBrand = [];
      this.checkedColor = [];
      this.genderOption = null;
      this.depositOption = null;
      this.delayOption = null;
      this.ageOption = null;
      this.type = null;
      console.log("All items cleared");
    },
    handleOptionClick(pincode) {
      this.PinCode = pincode;
      this.$refs.modal.close();
    },
    handleOptionClickNew(option) {
      // Handle the option click logic here if needed
      this.PinCode = option;

      // Then close the modal

      this.$refs.modal.close();
    },

    fetchAvailableCycles() {
      this.cycles_loading = true;

      axios
        .get("api/user/rp/rental-listing", {
          params: this.$route.query,
        })
        .then((response) => {
          console.log(response.data);

          this.cycles_loading = false;
          if ("rental_listings" in response.data) {
            this.cycles = response.data["rental_listings"]["data"];
            this.total_pages = response.data["rental_listings"]["last_page"];
            this.current_page =
              response.data["rental_listings"]["current_page"];
          }
        })
        .catch((error) => {
          console.log(error);
          this.cycles_loading = false;
        });
    },

    fetchMeta() {
      this.filters_loading = true;

      axios
        .get(`api/user/rp/rental-listing/meta`)
        .then((response) => {
          this.filters_loading = false;

          if ("companies" in response.data) {
            this.available_filters.companies = response.data["companies"];
          }
          if ("cycle_categories" in response.data) {
            this.available_filters.cycle_categories =
              response.data["cycle_categories"];
          }
          if ("cycle_frame_materials" in response.data) {
            this.available_filters.cycle_frame_materials =
              response.data["cycle_frame_materials"];
          }
          if ("cycle_frame_sizes" in response.data) {
            this.available_filters.cycle_frame_sizes =
              response.data["cycle_frame_sizes"];
          }
          if ("cycle_speeds" in response.data) {
            this.available_filters.cycle_speeds = response.data["cycle_speeds"];
          }
          if ("cycle_types" in response.data) {
            this.available_filters.cycle_types = response.data["cycle_types"];
          }
          if ("cycle_wheel_sizes" in response.data) {
            this.available_filters.cycle_wheel_sizes =
              response.data["cycle_wheel_sizes"];
          }
          if ("age" in response.data) {
            this.available_filters.age = response.data["age"];
          }
        })
        .catch((error) => {
          this.filters_loading = false;
          console.log(error);
        });
    },

    resetFilters() {
      this.filter = {
        companies: [],
        cycle_categories: [],
        cycle_frame_materials: [],
        cycle_frame_sizes: [],
        cycle_speeds: [],
        cycle_types: [],
        cycle_wheel_sizes: [],
      };
      this.$router
        .replace({
          name: this.$route.name,
          query: {
            pincode: this.$route.query.pincode,
            startDate: this.$route.query.startDate,
            endDate: this.$route.query.endDate,
          },
          hash: "#cycle-grid",
        })
        .then(() => {
          this.fetchAvailableCycles();
        });
    },
    openListing(id) {
      Cookies.set("selectedAccessories", null);
      this.$router.push({
        name: "RentalDetails",
        params: {
          id: id,
        },
        query: {
          pincode: this.$route.query.pincode,
          startDate: this.$route.query.startDate,
          endDate: this.$route.query.endDate,
        },
      });
    },
    async fetchPincodes() {
      try {
        this.isLoading = true;
        const response = await axios.get("api/user/pincode/getAll");
        const pincodes = response.data.pincodes.map(
          (pincodeObj) => pincodeObj.pincode
        );
        this.options = pincodes;
      } catch (error) {
        console.error("API error:", error);
      } finally {
        this.isLoading = false;
      }
    },

    onPageChange(page) {
      this.$router
        .replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: page,
          },
          hash: "#cycle-grid",
        })
        .then(() => {
          this.fetchAvailableCycles();
        });
    },
  },
  setup() {
    const sortClose = ref(null);
    const RentalSearchClose = ref(null);

    const closeModal = () => {
      const modal = document.getElementById("my_modal_7");
      if (modal) {
        modal.close();
      }
    };
    const closeRental = () => {
      const modal = document.getElementById("my_modal_14");
      if (modal) {
        modal.close();
      }
    };

    onClickOutside(sortClose, (event) => {
      closeModal();
      // Call the closeModal function when a click occurs outside the target element
    });

    onClickOutside(RentalSearchClose, (event) => {
      closeRental();
      // Call the closeModal function when a click occurs outside the target element
    });

    return {
      sortClose,
      closeModal,
      RentalSearchClose,
      closeRental,
    };
  },
  computed: {
    rentalSearch() {
      return this.$store.state.rentalSearch;
    },
    days() {
      let startDate = dayjs(this.$route.query.startDate);
      let endDate = dayjs(this.$route.query.endDate);
      return endDate.diff(startDate, "day");
    },
    shouldShowData() {
      return (
        this.$route.query?.pincode &&
        this.$route.query?.startDate &&
        this.$route.query?.endDate
      );
    },
  },
  mounted() {
    this.fetchPincodes();

    if (this.$route.query?.startDate) {
      this.startDate = this.$route.query.startDate;
    }

    if (this.$route.query?.endDate) {
      this.endDate = this.$route.query.endDate;
    }

    if (
      this.$route.query?.pincode &&
      this.$route.query?.startDate &&
      this.$route.query?.endDate
    ) {
      this.fetchAvailableCycles();
      this.fetchMeta();
    }
  },
  watch: {
    filter: {
      handler: function () {
        let urlParams = [];
        for (var key in this.filter) {
          let values = Object.values(this.filter[key]);
          if (values && values.length > 0) {
            urlParams[key] = values.join(",");
          }
        }

        if (this.$route.query.pincode)
          urlParams["pincode"] = this.$route.query.pincode;
        if (this.$route.query.startDate)
          urlParams["startDate"] = this.$route.query.startDate;
        if (this.$route.query.endDate)
          urlParams["endDate"] = this.$route.query.endDate;

        this.$router
          .replace({
            name: this.$route.name,
            query: urlParams,
            hash: "#cycle-grid",
          })
          .then(() => {
            this.fetchAvailableCycles();
          });
      },
      deep: true,
    },
  },
};
</script>

<style>
.bestseller {
  color: white;
  background-color: #36314c;
  position: absolute;
  transform-origin: left;
  top: 20px;
  left: -5px;
  padding: 0px 14px;
  border-radius: 10px 10px 0 0;
}
</style>
