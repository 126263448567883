<template>
  <div class="p-6 w-full md:w-1/2 lg:w-1/2">
    <h2 class="text-4xl font-bold text-[#211C37] mb-4">LOGIN</h2>

    <form @submit.prevent="handleLoginSubmit">
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="otp"
          >OTP*</label
        >
        <input
          required
          class="w-full border border-gray-400 p-2 rounded-lg"
          type="number"
          placeholder="Enter your OTP"
          v-model="otp" />
        <p class="text-sm text-gray-600 mt-1">
          OTP has been sent to your number
        </p>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </div>

      <div class="flex items-center">
        <input class="cursor-pointer" type="checkbox" v-model="isChecked" />
        <label class="ml-2 text-gray-700 font-medium" for="terms-and-conditions"
          >I agree to the
          <span
            @click="$router.push({ name: 'TermsPolicy' })"
            class="text-[#FF4A49] cursor-pointer underline">
            terms and conditions
          </span>
        </label>
      </div>
      <p v-if="isChecked === false" class="text-red-500">
        Please agree to Terms & Conditions.
      </p>
      <div class="text-center mt-4">
        <button
          :disabled="!isChecked"
          class="bg-red-600 text-white text-lg font-bold w-[85%] md:w-[60%] lg:w-[60%] h-[50px] rounded-lg hover:bg-red-700">
          Submit
        </button>
      </div>
    </form>
    <!-- <div className="divider">OR</div>
    <div class="text-center">
      <button
        @click="signInWithGoogle"
        className="btn btn-wide mb-2 border border-[#867EA8]">
        <svg
          class="mr-6"
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 32 32"
          fill="none">
          <g clip-path="url(#clip0_456_2759)">
            <path
              d="M31.9844 16.2994C31.9844 14.9884 31.8755 14.0317 31.6399 13.0396H16.3184V18.9569H25.3117C25.1305 20.4274 24.1514 22.642 21.9755 24.1301L21.945 24.3282L26.7894 27.9952L27.125 28.0279C30.2074 25.2463 31.9844 21.1537 31.9844 16.2994Z"
              fill="#4285F4" />
            <path
              d="M16.3184 31.8901C20.7244 31.8901 24.4232 30.4727 27.125 28.0279L21.9755 24.1301C20.5975 25.0691 18.748 25.7246 16.3184 25.7246C12.003 25.7246 8.34036 22.9432 7.03473 19.0986L6.84336 19.1145L1.80611 22.9236L1.74023 23.1025C4.42373 28.3112 9.93586 31.8901 16.3184 31.8901Z"
              fill="#34A853" />
            <path
              d="M7.03512 19.0987C6.69062 18.1066 6.49125 17.0435 6.49125 15.9451C6.49125 14.8466 6.69063 13.7837 7.017 12.7915L7.00787 12.5803L1.9075 8.70996L1.74063 8.78752C0.634625 10.949 0 13.3762 0 15.9451C0 18.5141 0.634625 20.9412 1.74063 23.1026L7.03512 19.0987Z"
              fill="#FBBC05" />
            <path
              d="M16.3184 6.16537C19.3826 6.16537 21.4496 7.45869 22.6282 8.53948L27.2337 4.14571C24.4052 1.57679 20.7244 0 16.3184 0C9.93586 0 4.42373 3.57875 1.74023 8.78742L7.01661 12.7915C8.34036 8.94693 12.003 6.16537 16.3184 6.16537Z"
              fill="#EB4335" />
          </g>
          <defs>
            <clipPath id="clip0_456_2759">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        SIGN IN WITH GOOGLE
      </button>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import app from "../firebase/firebase.config";

export default {
  name: "OTP",
  data() {
    return {
      user: null,
      mobile_number: "",
      otp: "",
      errorMessage: null,
      isChecked: false,
    };
  },
  methods: {
    async handleLoginSubmit() {
      // Handle form submission here
      const mobile_number = localStorage.getItem("mobile_number");
      try {
        const response = await axios.post(
          "api/user/login",
          { mobile_number, otp: this.otp } // Assuming `this.mobile_number` holds the mobile number value
        );

        const data = response.data.user;
        const jsonData = JSON.stringify(data);
        localStorage.setItem("user", jsonData);
        localStorage.setItem("token", response.data.token);
        this.$router.push("/profile");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Login successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        // Assuming the API returns data in the response
        // Other handling or processing of the response data
      } catch (error) {
        console.error("Error login:", error.response.data.message);
        this.errorMessage = error.response.data.message;
        // Handle error
      }
    },
    // async signInWithGoogle() {
    //   const auth = getAuth(app);
    //   const provider = new GoogleAuthProvider();

    //   try {
    //     const result = await signInWithPopup(auth, provider);
    //     this.user = result.user;
    //     const data = result.user;
    //     const jsonData = JSON.stringify(data);
    //     localStorage.setItem("user", jsonData);
    //     this.$router.push("/profile");
    //     Swal.fire({
    //       position: "center",
    //       icon: "success",
    //       title: "Login successfully",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   } catch (error) {
    //     console.error("Error signing in:", error.message);
    //   }
    // },
  },
};
</script>
