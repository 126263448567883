<template>
  <div
    className="flex flex-col p-4 justify-center items-center h-[calc(100vh-68px)]">
    <img :src="successLogo" alt="" />
    <h1 class="text-[24px] font-medium text-[#36314C] mb-5">
      Order Confirmed !
    </h1>
    <h1 class="text-[14px] font-semibold text-[#36314C]">
      HERO CONTINO ETB 100
    </h1>
    <h1 class="text-[12px] my-2 text-[#36314C]">Booking ID: HX1025489</h1>

    <button
      @click="$router.push({ name: 'RentalOrderDetails' })"
      class="bg-[#FF4A49] mt-4 font-medium text-[24px] text-white rounded-lg w-[142px] h-[45px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
      ok
    </button>
  </div>
</template>

<script>
import successLogo from "../assets/HomeView/successLogo.png";

export default {
  data() {
    return {
      successLogo,
    };
  },
};
</script>
