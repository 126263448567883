import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDBipczb9pp6k961cGiOz-t4j_n0Bp_888",
  authDomain: "cycle-depo.firebaseapp.com",
  projectId: "cycle-depo",
  storageBucket: "cycle-depo.appspot.com",
  messagingSenderId: "165969909151",
  appId: "1:165969909151:web:18a6e84bf9e9a5313999aa",
};

const app = initializeApp(firebaseConfig);

export default app;
