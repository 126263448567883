<template>
  <div>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div
      v-else
      class="p-4 md:px-[100px] md:py-[50px] lg:px-[100px] lg:py-[50px] md:bg-[#F4F4F4] lg:bg-[#F4F4F4]">
      <div class="bg-[#FFF] p-0 md:[40px] lg:p-[40px] rounded-[30px]">
        <h1
          class="font-semibold text-[1.375rem] md:text-[1.5rem] lg:text-[4.8rem] mb-6 md:mb-0 lg:mb-0">
          My Account
        </h1>

        <div class="flex gap-5 mt-[40px] mb-[37px]">
          <div class="flex gap-3 items-center">
            <h1
              class="text-[15px] md:text-[1.6rem] lg:text-[1.6rem] font-medium cursor-pointer">
              My Profile
            </h1>
            <div class="bg-[#CCC6E9] h-[20px] w-[2px]"></div>
          </div>
          <div class="flex gap-3 items-center">
            <h1
              @click="$router.push({ name: 'Addresses' })"
              class="text-[#FF4A49] cursor-pointer text-[15px] md:text-[1.6rem] lg:text-[1.6rem] font-medium">
              Address Book
            </h1>
            <div class="bg-[#CCC6E9] h-[20px] w-[2px]"></div>
          </div>
          <h1
            @click="$router.push({ name: 'OrderHistory' })"
            class="text-[#FF4A49] cursor-pointer text-[15px] md:text-[1.6rem] lg:text-[1.6rem] font-medium">
            My Orders
          </h1>
        </div>

        <hr />

        <div
          class="w-full custom-section md:w-[480px] lg:w-[480px] mt-[45px] mb-[60px]">
          <h1 class="mb-[40px] text-[26px] font-extrabold">My Profile</h1>
          <form @submit.prevent="updateUserData">
            <v-text-field
              v-model="user.first_name"
              label="First Name *"
              variant="outlined"></v-text-field>

            <v-text-field
              v-model="user.last_name"
              label="Last Name *"
              variant="outlined"
              required>
            </v-text-field>

            <v-text-field
              class="mb-2"
              v-model="email"
              @click="openModal"
              label="Email Address"
              :error-messages="emailErrors"
              :rules="[emailRule]"
              variant="outlined">
            </v-text-field>

            <v-text-field
              label="Mobile Number *"
              variant="outlined"
              v-model="user.mobile_number"
              readonly>
            </v-text-field>
            <div class="text-right mt-10">
              <button
                class="w-[127px] h-[50px] text-[24px] font-medium text-white bg-[#FF4A49] border border-transparent rounded-lg hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
                Update
              </button>
            </div>
          </form>
        </div>
        <hr />
        <div>
          <dialog :id="modalId" className="modal modal-top lg:modal-middle">
            <div
              method="dialog"
              className="modal-box bg-white w-full lg:w-[70%] max-w-full lg:max-w-md h-full lg:h-[70%] max-h-full lg:max-h-md "
              ref="deleteModalRef">
              <div class="modal-action mb-6 justify-between">
                <h3 v-if="!otpSent" class="font-semibold text-lg">
                  Email Address
                </h3>
                <h3 v-else class="font-semibold text-lg">Enter OTP</h3>

                <button
                  class="btn btn-sm btn-circle btn-ghost"
                  @click="closeModal">
                  ✕
                </button>
              </div>

              <!--  -->
              <div v-if="!otpSent">
                <form @submit.prevent="handleAddEmail">
                  <v-text-field
                    class="mb-2"
                    v-model="email"
                    label="Email Address"
                    :error-messages="emailErrors"
                    :rules="[emailRule]">
                  </v-text-field>
                  <div class="text-center">
                    <button
                      :disabled="email === ''"
                      class="w-[200px] h-[50px] text-[16px] font-medium text-white rounded-[31px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]"
                      :class="{
                        'bg-gray-400 hover:bg-gray-400 cursor-not-allowed':
                          email === '',
                        'bg-gradient-to-r from-red-400 to-pink-500':
                          email !== '', // Apply gradient when email is not empty
                      }">
                      Verify Email Address
                    </button>
                  </div>
                </form>
              </div>
              <div v-else>
                <form @submit.prevent="handleConfirm">
                  <v-otp-input
                    type="number"
                    v-model="otp"
                    variant="underlined"></v-otp-input>
                  <div class="text-center">
                    <button
                      :disabled="otp === ''"
                      :class="{
                        'bg-gray-400 hover:bg-gray-400 cursor-not-allowed':
                          otp === '',
                      }"
                      class="w-[200px] h-[50px] text-[16px] font-medium text-white bg-[#FF4A49] border border-transparent rounded-xl hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { VOtpInput } from "vuetify/labs/VOtpInput";
import LoadingSpinner from "../components/LoadingSpinner";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "MyProfile",
  components: {
    LoadingSpinner,
    VOtpInput,
  },
  data() {
    return {
      email: "",
      modalId: "my_modal_9",
      otp: "",
      otpSent: false,
      isLoading: false,
      emailRule: (v) => {
        if (!v) return "Email is required";
        if (!this.validateEmail(v)) return "Invalid email format";
        return true;
      },
      user: {},
    };
  },
  setup() {
    const deleteModalRef = ref(null);

    const closeModal = () => {
      const modal = document.getElementById("my_modal_9");
      if (modal) {
        modal.close();
      }
    };

    onClickOutside(deleteModalRef, (event) => {
      closeModal();
      // Call the closeModal function when a click occurs outside the target element
    });

    return {
      deleteModalRef,
      closeModal,
    };
  },
  methods: {
    openModal() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.showModal();
      }
    },
    closeModal() {
      const modal = document.getElementById(this.modalId);
      if (modal) {
        modal.close();
      }
    },
    async getAllUser() {
      try {
        this.isLoading = true;
        const result = await axios.get("api/user/profile");
        this.user = result.data;
        this.email = result.data.email_id;
        console.log(result.data);
      } catch (error) {
        console.error("API error:", error);
      } finally {
        this.isLoading = false;
      }
    },
    updateUserData() {
      const updateData = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        // email: this.email,
      };

      axios
        .post("api/user/user/update", updateData)
        .then((response) => {
          if ("success" in response.data && response.data["success"] == true) {
            let user = this.$store.state.user;
            user.first_name = this.user.first_name;
            user.last_name = this.user.last_name;
            user.name = user.first_name + " " + user.last_name;
            user.email_id = this.email;
            this.$store.dispatch("setUser", user);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating user", error);
        });
    },
    validateEmail(email) {
      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async handleAddEmail() {
      const emailAdd = await axios
        .post("api/user/user/update_email", { email: this.email })
        .then((response) => {
          if (response.data.success === true) {
            this.otpSent = true;
          }
        })
        .catch((error) => {
          console.error("There was a problem with the request:", error);
        });
    },

    async handleConfirm() {
      const otpConfirm = await axios
        .post("api/user/user/verify_email", {
          email: this.email,
          otp: this.otp,
        })
        .then((response) => {
          if (response.data.success === true) {
            this.closeModal();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Email Added successfully!!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.error("There was a problem with the request:", error);
        });
    },
  },
  async mounted() {
    this.getAllUser();
  },
  computed: {
    // user() {
    //   return this.$store.state.user;
    // },
    emailErrors() {
      const errors = [];
      if (!this.emailRule(this.email)) {
        errors.push("Email is invalid");
      }
      return errors;
    },
  },
};
</script>

<style>
.custom-section .v-label {
  opacity: unset;
}
</style>
