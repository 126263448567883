<!-- <template>
  <div class="p-4 md:p-8 lg:p-8">
    <div
      className="hero min-h-[60vh] md:min-h-[90vh] lg:min-h-[90vh] rounded-[15px] relative"
      :style="{ backgroundImage: 'url(' + eventsBanner + ')' }">
      <div
        class="w-[95%] md:w-[80%] lg:w-[80%] absolute bottom-10 p-4"
        style="
          background: rgba(0, 0, 0, 0.37);

          backdrop-filter: blur(7.5px);
        ">
        <div class="flex items-center justify-between">
          <div
            class="w-[60px] h-[19px] bg-[#161616] rounded-[15px] flex items-center justify-center">
            <h1 class="font-bold text-white text-[10px]">Mumbai</h1>
          </div>
          <div class="flex items-center justify-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 18 20"
              fill="none">
              <path
                d="M8 12V10H10V12H8ZM4 12V10H6V12H4ZM12 12V10H14V12H12ZM8 16V14H10V16H8ZM4 16V14H6V16H4ZM12 16V14H14V16H12ZM0 20V2H3V0H5V2H13V0H15V2H18V20H0ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z"
                fill="white" />
            </svg>
            <p class="text-[13px] text-white">Mar 03 - 04 | 10:30 PM(IST)</p>
          </div>
        </div>

        <div class="flex items-center justify-between mt-3">
          <h1
            class="text-[14px] md:text-[30px] lg:text-[30px] font-bold text-white">
            Mumbai Midnight Cycling - SoBo Circuit on 11th March 2023
          </h1>
          <div
            class="w-[110px] h-[20px] md:h-[30px] lg:h-[30px] bg-[#71AE0D] rounded-[15px] flex items-center justify-center">
            <h1
              @click="$router.push({ name: 'EventDetails' })"
              class="font-bold text-white text-[9px] cursor-pointer md:text-[14px] lg:text-[14px]">
              More Details
            </h1>
          </div>
        </div>
      </div>
    </div>
    
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10 mb-5">
      <div
        v-for="(event, index) in events"
        :key="index"
        class="text-black"
        style="
          padding: 10px;
          border-radius: 30px;
          box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.18);
        ">
        <img :src="event.img" style="width: 100%" />
        <div class="pa-4">
          <div style="font-weight: 600; font-size: 20px">
            Mumbai Midnight Cycling - SoBo Circuit on 11th March 2023
          </div>
          <div class="d-flex mt-1" style="font-size: 12px">
            <div class="d-flex flex-grow-1">
              <v-icon class="mr-2" icon="mdi-calendar"></v-icon>
              <div>Mar 03 - 04 | 10:30 PM(IST)</div>
            </div>
            <div>Mumbai</div>
          </div>
          <div class="mt-4" style="font-size: 12px">
            Mumbai is famously seen as one of the fastest and busiest cities of
            India. But, one of the lesser-known charms of the city, is to
            experience it in the night. Explore the beautiful coastline and
            visit the iconic landmarks, while riding through the Mumbai
            coastline, starting at Colaba Causeway and ending at Colaba with
            multiple stops on the way......
          </div>
          <div class="flex items-center gap-1 my-2">
            <v-icon icon="mdi-thumb-up-outline"></v-icon>
            <div class="text-[14px]" style="font-weight: 500">
              108 Peoples have shown interest recently
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex-grow-1">
              <div style="font-size: 18px">
                <span style="font-weight: 600">Event Fees:</span> Free
              </div>
            </div>
            <v-btn
              @click="$router.push({ name: 'EventDetails' })"
              class="rounded-xl text-white"
              color="#211C37"
              elevation="0">
              Learn More
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventsBanner from "../assets/HomeView/eventsBanner.jpg";
import cardImg2 from "../assets/HomeView/cardImg2.jpg";
import eventImg from "@/assets/temp/event.png";

export default {
  data() {
    return {
      eventsBanner,
      cardImg2,
      events: [
        { img: eventImg },
        { img: eventImg },
        { img: eventImg },
        { img: eventImg },
      ],
    };
  },
};
</script> -->

<template>
  <div class="min-h-screen flex flex-col justify-center items-center">
    <img
      src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
      alt="Logo"
      class="mb-8 h-40" />
    <h1
      class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">
      Events are coming soon
    </h1>
  </div>
</template>
