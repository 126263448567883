import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Vue3Marquee from "vue3-marquee";
import "./axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Swal from "sweetalert2";
import { pageTitle } from "vue-page-title";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import { currency, rentalPricing, stringifyAddress } from "./global";

loadFonts();

const dayjs = require("dayjs");
var customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(customParseFormat);

window.dayjs = dayjs;

const app = createApp(App)
  .use(router)
  .use(store)
  .use(Vue3Marquee)
  .use(vuetify)
  .use(VueViewer)
  .use(pageTitle({ prefix: "CycleDepo", separator: " - ", router: router }));

app.component("VueDatePicker", VueDatePicker);

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$swal = Swal;
app.config.globalProperties.$currency = currency;
app.config.globalProperties.$rentalPricing = rentalPricing;
app.config.globalProperties.$stringifyAddress = stringifyAddress;

app.mount("#app");
