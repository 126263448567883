<template>
  <div>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div
      v-else
      class="p-4 md:px-[160px] md:py-[50px] lg:px-[160px] lg:py-[50px] bg-[#F4F4F4]">
      <div class="bg-[#FFF] p-[20px] md:[40px] lg:p-[40px] rounded-[30px]">
        <div class="flex justify-between items-center">
          <h1 class="text-[16px] md:text-[48px] lg:text-[48px] font-semibold">
            Update Address
          </h1>
          <div
            @click="$router.push({ name: 'Profile' })"
            class="flex text-[12px] md:text-[26px] lg:text-[26px] font-bold cursor-pointer items-center gap-1">
            <img :src="backArrow" />
            <h1 class="font-bold text-[#FF4A49]">Back to my account</h1>
          </div>
        </div>
        <form @submit.prevent="updateUserAddress">
          <div
            class="w-full md:w-[480px] lg:w-[480px] custom-section mt-10 mb-10">
            <v-text-field
              v-model="newAddress.contact_name"
              label="Contact Name"
              variant="outlined">
            </v-text-field>
            <!-- <v-text-field
              v-model="newAddress.contact_number"
              label="Mobile Number"
              variant="outlined"
              readonly>
            </v-text-field> -->
            <v-text-field
              v-model="newAddress.address_line_1"
              label="House No, Building"
              variant="outlined">
            </v-text-field>
            <v-text-field
              v-model="newAddress.address_line_2"
              label="Address (Street Address)"
              variant="outlined">
            </v-text-field>
            <v-text-field
              v-model="newAddress.landmark"
              label="Locality / Town"
              variant="outlined">
            </v-text-field>
            <v-select
              label="Pin Code"
              v-model="newAddress.pincode"
              :items="options"
              variant="outlined"></v-select>

            <v-text-field
              v-model="newAddress.state"
              label="State / Province *"
              variant="outlined"
              readonly>
            </v-text-field>
            <v-text-field
              v-model="newAddress.city"
              label="City / District *"
              variant="outlined"
              readonly>
            </v-text-field>
            <h1 class="text-[20px]">Address Type</h1>
            <v-radio-group inline v-model="newAddress.type">
              <v-radio label="Home" class="custom-radio" value="home">
                <template #label>
                  <span
                    class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                    >Home</span
                  >
                </template>
              </v-radio>
              <v-radio label="Office" class="custom-radio" value="office">
                <template #label>
                  <span
                    class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                    >Office</span
                  >
                </template>
              </v-radio>
              <v-radio label="Other" color="red" value="other">
                <template #label>
                  <span
                    class="text-[20px] md:text-[26px] lg:text-[26px] text-[#000] font-medium"
                    >Other</span
                  >
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="flex items-center gap-6">
            <button
              class="bg-[#FF4A49] font-extrabold text-[25px] md:text-[30px] lg:text-[30px] text-white rounded-lg w-[150px] h-[50px] md:w-[160px] md:h-[60px] lg:w-[160px] lg:h-[60px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner from "../components/LoadingSpinner";
import backArrow from "@/assets/temp/backArrow.svg";

export default {
  name: "AddressUpdate",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      newAddress: {
        contact_name: "",
        contact_number: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        pincode: "",
        state: "",
        city: "",
        type: "",
      },
      isLoading: false,
      backArrow,
      options: [],
    };
  },
  methods: {
    async updateUserAddress() {
      try {
        const response = await axios.post(
          `api/user/user-address/${this.$route.params.id}/edit`,
          {
            contact_name: this.newAddress.contact_name,
            contact_number: this.newAddress.contact_number,
            address_line_1: this.newAddress.address_line_1,
            address_line_2: this.newAddress.address_line_2,
            landmark: this.newAddress.landmark,
            pincode: this.newAddress.pincode,
            type: this.newAddress.type,
            is_default: true,
            city: this.newAddress.city,
            state: this.newAddress.state,
          }
        );

        // Handle success, show a success message, etc.
        this.$router.push("/addresses");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Updated successfully!!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error("Error creating address:", error);
        // Handle error, show an error message, etc.
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const response = await axios.get(`api/user/pincode/getAll`);

      // Extract pincode values from the pincodes array

      const pincodes = response.data.pincodes.map(
        (pincodeObj) => pincodeObj.pincode
      );
      this.options = pincodes;

      const result = await axios.get(
        `api/user/user-address/` + this.$route.params.id
      );
      this.newAddress = result.data.user_address;
    } catch (error) {
      console.error("API error:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
.custom-section .v-label {
  opacity: unset;
}
.custom-section .v-selection-control__input > .v-icon {
  opacity: unset;
  color: #ff4a49;
}

.custom-section .v-icon--size-default {
  font-size: 26px;
}
</style>
