<template>
  <div
    className="flex flex-col p-2 justify-center items-center h-[calc(100vh-68px)]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none">
      <path
        d="M79.5837 20.4167C63.3337 4.16675 36.667 4.16675 20.417 20.4167C4.16699 36.6667 4.16699 63.3334 20.417 79.5834C36.667 95.8334 62.917 95.8334 79.167 79.5834C95.417 63.3334 95.8337 36.6667 79.5837 20.4167ZM61.667 67.5001L50.0003 55.8334L38.3337 67.5001L32.5003 61.6667L44.167 50.0001L32.5003 38.3334L38.3337 32.5001L50.0003 44.1667L61.667 32.5001L67.5003 38.3334L55.8337 50.0001L67.5003 61.6667L61.667 67.5001Z"
        fill="#FF4A49" />
    </svg>
    <h1 class="text-[24px] font-medium text-[#36314C] mt-5">Payment Failed</h1>
    <h1 class="text-[14px] text-center mt-[10px] text-[#36314C]">
      We cant process your payment. <br />
      Check the error and try again.
    </h1>
    <h1 class="text-[14px] my-[18px] text-[#FF4A49]">Connectivity issue</h1>
    <h1 class="text-[16px] text-[#36314C]">Standard Service (Non Gear)</h1>
    <h1 class="text-[12px] mt-[10px] text-[#36314C]">
      Mon, 4 Sept 2023, 7.30 PM
    </h1>
    <button
      class="bg-[#FF4A49] mt-[40px] text-[24px] text-white rounded-lg w-[175px] h-[45px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
      TRY AGAIN
    </button>
  </div>
</template>
