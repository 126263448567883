import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    "user",
    "token",
    "searchValue",
    "packageData",
    "bookingAddress",
    "rentalSearch",
    "couponCode",
    "cart",
    "favorites",
  ],
});

export default createStore({
  plugins: [vuexLocal.plugin],
  state: {
    user: null,
    token: null,
    favorites: [],
    searchValue: {
      cycleOption: "",
      cyclePin: "",
      companyId: "",
      cycleModel: "",
      cycleBrand: "",
      selectData: false,
    },
    rentalSearch: null,
    packageData: null,
    bookingAddress: null,
    couponCode: null,
    cart: [],
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
  mutations: {
    ADD_TO_CART(state, payload) {
      state.cart.push(payload);
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_SEARCH_VALUE(state, payload) {
      state.searchValue = payload;
    },
    SET_RENTAL_VALUE(state, payload) {
      state.rentalSearch = payload;
    },
    SET_PACKAGE_DATA(state, payload) {
      state.packageData = payload;
    },
    SET_BOOKING_ADDRESS(state, payload) {
      state.bookingAddress = payload;
    },
    SET_COUPON_CODE(state, payload) {
      state.couponCode = payload;
    },
    TOGGLE_FAVORITE(state, payload) {
      const index = state.favorites.indexOf(payload);
      if (index === -1) {
        state.favorites.push(payload);
      } else {
        state.favorites.splice(index, 1);
      }
    },
  },
  actions: {
    setUser(context, payload) {
      context.commit("SET_USER", payload);
    },
    addToCart(context, payload) {
      context.commit("ADD_TO_CART", payload);
    },
    setToken(context, payload) {
      context.commit("SET_TOKEN", payload);
    },
    setSearchValue(context, payload) {
      context.commit("SET_SEARCH_VALUE", payload);
    },
    setRentalValue(context, payload) {
      context.commit("SET_RENTAL_VALUE", payload);
    },
    setPackageData(context, payload) {
      context.commit("SET_PACKAGE_DATA", payload);
    },
    setBookingAddress(context, payload) {
      context.commit("SET_BOOKING_ADDRESS", payload);
    },
    setCouponCode(context, payload) {
      context.commit("SET_COUPON_CODE", payload);
    },
    toggleFavorite(context, payload) {
      context.commit("TOGGLE_FAVORITE", payload);
    },
  },
  modules: {},
});
