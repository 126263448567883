<template>
  <v-card class="rounded-xl" elevation="0">
    <span class="text-warning absolute top-4 left-4 z-[10]">{{
      cycle.upcoming
    }}</span>
    <v-img :src="cycle.image" aspect-ratio="1" class="p-4 mx-auto"></v-img>
    <span class="bestseller-tag uppercase">{{ cycle.type }}</span>
    <v-card-text>
      <div class="mb-3 text-center" style="font-weight: 500; font-size: 18px">
        {{ cycle.name }}
      </div>
      <div class="d-flex justify-space-between px-2" style="color: #999797">
        <div class="d-flex align-center">
          <img class="mr-2" :src="age" />
          <span>{{ cycle.age }}</span>
        </div>
        <div class="d-flex align-center">
          <img class="mr-2" :src="height" />
          <span>{{ cycle.height }}</span>
        </div>
        <div class="d-flex align-center">
          <img class="mr-2" :src="wheel" />
          <span>{{ cycle.wheel }}</span>
        </div>
      </div>
      <div class="d-flex align-center mt-4">
        <div
          class="flex-grow-1 ml-2"
          style="color: #211c37; font-size: 20px; font-weight: 600">
          ₹ {{ cycle.price }} <span class="text-sm">/ Monthly</span>
        </div>
        <v-btn
          disabled
          class="text-white text-uppercase"
          color="#6D3186"
          rounded
          elevation="0"
          @click="$router.push({ name: 'details-view' })"
          >Coming soon</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import age from "@/assets/HomeView/age.svg";
import height from "@/assets/HomeView/height.svg";
import wheel from "@/assets/HomeView/wheel.svg";

export default {
  props: ["cycle"],
  data() {
    return {
      age,
      height,
      wheel,
    };
  },
};
</script>

<style>
.bestseller-tag {
  color: white;
  background-color: #36314c;
  position: absolute;
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: right center;
  top: 24px;
  right: 0;
  padding: 0px 14px;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
}
</style>
