<!-- <template>
  <div class="bg-[#F4F4F4] p-4">
    <div class="mb-5">
      <img :src="trending" alt="" />
    </div>
    <div
      class="w-full bg-white rounded-[20px] flex flex-col md:flex-row lg:flex-row items-start justify-between gap-9">
      <div class="w-full md:w-[55%] lg:w-[55%]">
        <swiper :pagination="true" :modules="modules" class="mySwiper">
          <swiper-slide>
            <div
              @click="$router.push({ name: 'BlogDetails' })"
              class="relative flex items-center justify-center">
              <img :src="blogImg" class="rounded-[20px]" alt="" srcset="" />
              <div
                class="w-[80%] md:w-[600px] lg:w-[600px] h-[80px] md:h-[125px] lg:h-[125px] absolute bottom-2 md:bottom-10 lg:bottom-10 px-4 md:px-8 lg:px-8 py-1 md:py-2 lg:py-2"
                style="
                  border-radius: 20px;
                  background: rgba(0, 0, 0, 0.37);
                  backdrop-filter: blur(7.5px);
                ">
                <div
                  class="w-[70px] md:w-[130px] lg:w-[130px] h-[15px] md:h-[25px] lg:h-[25px] rounded-[20px] flex items-center justify-center bg-black">
                  <h1
                    class="text-white text-[8px] md:text-sm lg:text-sm font-semibold">
                    Health & Fitness
                  </h1>
                </div>
                <h1
                  class="text-[12px] md:text-[24px] lg:text-[24px] mt-[2px] md:mt-1 lg:mt-1 font-bold text-white">
                  Cycling as a Part of Daily Life: A Review of Health
                  Perspectives
                </h1>
                <div
                  class="flex items-center mb-2 gap-1 justify-end text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-[15px] h-[15px] md:w-[20px] md:h-[20px] lg:w-[20px] lg:h-[20px]"
                    viewBox="0 0 25 25"
                    fill="none">
                    <path
                      d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                      fill="#ffffff" />
                  </svg>
                  <p class="text-[10px] md:text-[14px] lg:text-[14px]">
                    May 27,2023
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              @click="$router.push({ name: 'BlogDetails' })"
              class="relative flex items-center justify-center">
              <img :src="blogImg" class="rounded-[20px]" alt="" srcset="" />
              <div
                class="w-[80%] md:w-[600px] lg:w-[600px] h-[80px] md:h-[125px] lg:h-[125px] absolute bottom-2 md:bottom-10 lg:bottom-10 px-4 md:px-8 lg:px-8 py-1 md:py-2 lg:py-2"
                style="
                  border-radius: 20px;
                  background: rgba(0, 0, 0, 0.37);
                  backdrop-filter: blur(7.5px);
                ">
                <div
                  class="w-[70px] md:w-[130px] lg:w-[130px] h-[15px] md:h-[25px] lg:h-[25px] rounded-[20px] flex items-center justify-center bg-black">
                  <h1
                    class="text-white text-[8px] md:text-sm lg:text-sm font-semibold">
                    Health & Fitness
                  </h1>
                </div>
                <h1
                  class="text-[12px] md:text-[24px] lg:text-[24px] mt-[2px] md:mt-1 lg:mt-1 font-bold text-white">
                  Cycling as a Part of Daily Life: A Review of Health
                  Perspectives
                </h1>
                <div
                  class="flex items-center mb-2 gap-1 justify-end text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-[15px] h-[15px] md:w-[20px] md:h-[20px] lg:w-[20px] lg:h-[20px]"
                    viewBox="0 0 25 25"
                    fill="none">
                    <path
                      d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                      fill="#ffffff" />
                  </svg>
                  <p class="text-[10px] md:text-[14px] lg:text-[14px]">
                    May 27,2023
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              @click="$router.push({ name: 'BlogDetails' })"
              class="relative flex items-center justify-center">
              <img :src="blogImg" class="rounded-[20px]" alt="" srcset="" />
              <div
                class="w-[80%] md:w-[600px] lg:w-[600px] h-[80px] md:h-[125px] lg:h-[125px] absolute bottom-2 md:bottom-10 lg:bottom-10 px-4 md:px-8 lg:px-8 py-1 md:py-2 lg:py-2"
                style="
                  border-radius: 20px;
                  background: rgba(0, 0, 0, 0.37);
                  backdrop-filter: blur(7.5px);
                ">
                <div
                  class="w-[70px] md:w-[130px] lg:w-[130px] h-[15px] md:h-[25px] lg:h-[25px] rounded-[20px] flex items-center justify-center bg-black">
                  <h1
                    class="text-white text-[8px] md:text-sm lg:text-sm font-semibold">
                    Health & Fitness
                  </h1>
                </div>
                <h1
                  class="text-[12px] md:text-[24px] lg:text-[24px] mt-[2px] md:mt-1 lg:mt-1 font-bold text-white">
                  Cycling as a Part of Daily Life: A Review of Health
                  Perspectives
                </h1>
                <div
                  class="flex items-center mb-2 gap-1 justify-end text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-[15px] h-[15px] md:w-[20px] md:h-[20px] lg:w-[20px] lg:h-[20px]"
                    viewBox="0 0 25 25"
                    fill="none">
                    <path
                      d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                      fill="#ffffff" />
                  </svg>
                  <p class="text-[10px] md:text-[14px] lg:text-[14px]">
                    May 27,2023
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="w-full md:w-[45%] lg:w-[45%] p-4">
        <div
          class="h-full md:h-[451px] lg:h-[451px] overflow-none md:overflow-auto lg:overflow-auto">
          <div
            v-for="blog in blogs"
            @click="$router.push({ name: 'BlogDetails' })"
            class="p-2 mb-[10px] w-full md:w-[483px] lg:w-[483px]">
            <div class="flex gap-4">
              <div class="w-[200px] h-[100px]">
                <img
                  :src="blog.img"
                  class="rounded-[6px] h-full w-full object-cover"
                  alt=""
                  srcset="" />
              </div>
              <div class="flex flex-col justify-between">
                <h1 class="text-[15px] link-hover cursor-pointer font-semibold">
                  {{ blog.title }}
                </h1>
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 25 25"
                      fill="none">
                      <path
                        d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                        fill="#36314C" />
                    </svg>
                    <p class="text-[10px]">May 27,2023</p>
                  </div>
                  <div class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 25 25"
                      fill="none">
                      <path
                        d="M12.4902 2.08325C6.74023 2.08325 2.08398 6.74992 2.08398 12.4999C2.08398 18.2499 6.74023 22.9166 12.4902 22.9166C18.2507 22.9166 22.9173 18.2499 22.9173 12.4999C22.9173 6.74992 18.2507 2.08325 12.4902 2.08325ZM12.5007 20.8333C7.89648 20.8333 4.16732 17.1041 4.16732 12.4999C4.16732 7.89575 7.89648 4.16659 12.5007 4.16659C17.1048 4.16659 20.834 7.89575 20.834 12.4999C20.834 17.1041 17.1048 20.8333 12.5007 20.8333Z"
                        fill="#36314C" />
                      <path
                        d="M13.0215 7.29175H11.459V13.5417L16.9277 16.823L17.709 15.5417L13.0215 12.7605V7.29175Z"
                        fill="#36314C" />
                    </svg>
                    <p class="text-[10px]">7 Minutes</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-[#36314C] w-full h-[0.5px] mt-2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-8 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
      <div
        @click="$router.push({ name: 'BlogDetails' })"
        v-for="card in cards"
        :key="card.img"
        class="bg-white h-[350px] w-full md:w-[400px] lg:w-[400px] rounded-[15px]">
        <div class="w-full h-[70%]">
          <img
            class="w-full h-full rounded-t-[15px] object-cover"
            :src="card.img"
            alt="" />
        </div>
        <div class="w-full h-[30%] p-2">
          <h1
            class="text-center link-hover cursor-pointer text-[18px] font-bold">
            {{ card.title }}
          </h1>
          <div class="flex items-center justify-center gap-6 mt-2">
            <div class="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 25 25"
                fill="none">
                <path
                  d="M5.20833 22.9166C4.63542 22.9166 4.14479 22.7128 3.73646 22.3051C3.32882 21.8968 3.125 21.4062 3.125 20.8333V6.24992C3.125 5.677 3.32882 5.18672 3.73646 4.77909C4.14479 4.37075 4.63542 4.16659 5.20833 4.16659H6.25V2.08325H8.33333V4.16659H16.6667V2.08325H18.75V4.16659H19.7917C20.3646 4.16659 20.8552 4.37075 21.2635 4.77909C21.6712 5.18672 21.875 5.677 21.875 6.24992V20.8333C21.875 21.4062 21.6712 21.8968 21.2635 22.3051C20.8552 22.7128 20.3646 22.9166 19.7917 22.9166H5.20833ZM5.20833 20.8333H19.7917V10.4166H5.20833V20.8333ZM5.20833 8.33325H19.7917V6.24992H5.20833V8.33325Z"
                  fill="#36314C" />
              </svg>
              <p class="text-[14px]">May 27,2023</p>
            </div>
            <div class="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 25 25"
                fill="none">
                <path
                  d="M12.4902 2.08325C6.74023 2.08325 2.08398 6.74992 2.08398 12.4999C2.08398 18.2499 6.74023 22.9166 12.4902 22.9166C18.2507 22.9166 22.9173 18.2499 22.9173 12.4999C22.9173 6.74992 18.2507 2.08325 12.4902 2.08325ZM12.5007 20.8333C7.89648 20.8333 4.16732 17.1041 4.16732 12.4999C4.16732 7.89575 7.89648 4.16659 12.5007 4.16659C17.1048 4.16659 20.834 7.89575 20.834 12.4999C20.834 17.1041 17.1048 20.8333 12.5007 20.8333Z"
                  fill="#36314C" />
                <path
                  d="M13.0215 7.29175H11.459V13.5417L16.9277 16.823L17.709 15.5417L13.0215 12.7605V7.29175Z"
                  fill="#36314C" />
              </svg>
              <p class="text-[14px]">7 Minutes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import blogImg from "../assets/HomeView/sliderImg.jpg";
import cardImg1 from "../assets/HomeView/cardImg1.jpg";
import cardImg2 from "../assets/HomeView/cardImg2.jpg";
import cardImg3 from "../assets/HomeView/cardImg3.jpg";
import trending from "../assets/HomeView/trending.webp";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination],
      blogImg,
      cardImg1,
      trending,
      blogs: [
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg1,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg2,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: blogImg,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg3,
        },
      ],
      cards: [
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg1,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg2,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: blogImg,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg2,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: blogImg,
        },
        {
          title:
            "Cycling as a Part of Daily Life: A Review of Health Perspectives",
          img: cardImg3,
        },
      ],
    };
  },
};
</script>

<style>
.swiper-pagination-bullet {
  background-color: white;
}
</style> -->

<template>
  <div class="min-h-screen flex flex-col justify-center items-center">
    <img
      src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
      alt="Logo"
      class="mb-8 h-40" />
    <h1
      class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">
      Blogs are coming soon
    </h1>
  </div>
</template>
