<template>
  <div class="my-10">
    <h1
      class="text-4xl md:text-7xl lg:text-7xl font-medium text-center uppercase">
      Cancellation policy
    </h1>

    <div class="p-8 md:p-24 lg:p-24">
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Cancellation</span> : Customers can cancel
        their bicycle rental booking up to 24 hours before the scheduled rental
        start time.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Cancellation Fee</span> : If a customer
        cancels within the specified cancellation window, no cancellation fee
        will be charged. However, if the cancellation is made less than 24 hours
        before the scheduled rental start time, a cancellation fee of [X]% of
        the total rental cost will be applied.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Refunds</span> : If a customer is eligible
        for a refund due to a cancellation within the allowed window, the
        refunded amount will be the total rental cost minus the applicable
        cancellation fee.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">No-Show Policy</span> : If a customer fails
        to pick up the reserved bicycle without cancelling the booking in
        advance, they will be considered a no-show. In such cases, the entire
        rental cost will be non-refundable.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Weather-Related Cancellations</span> : If
        weather conditions make it unsafe or impractical to use the bicycles on
        the scheduled rental day, customers will have the option to reschedule
        their booking or receive a full refund.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Process for Cancellation</span> : Customers
        must provide written notice of their cancellation via email or through
        the rental platform's messaging system. The cancellation will be
        considered effective once the rental provider acknowledges receipt of
        the cancellation request.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Booking Modifications</span> : Customers can
        modify their booking details (date, time, etc.) within the allowed
        cancellation window without incurring any additional charges. However,
        modifications made within 24 hours of the scheduled rental start time
        might be subject to availability and may incur a fee.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Force Majeure</span> : In the event of
        unforeseen circumstances such as natural disasters, strikes, or other
        unavoidable situations that prevent either the customer or the rental
        provider from fulfilling their obligations, both parties will work
        towards a fair solution, which might include rescheduling or refunds.
      </h1>
      <h1 class="text-lg mb-3 break-all">
        <span class="font-semibold">Contact Information</span> : For
        cancellations or any other inquiries, customers can contact our customer
        support team at [customer support email/phone number].
      </h1>
      <h1 class="text-lg mb-3 break-all">
        Please note that this is just a sample policy. It's important to
        carefully consider your business's specific needs, local laws, and
        customer expectations when crafting a cancellation policy for your
        bicycle rental service. Additionally, it's a good idea to clearly
        communicate the cancellation policy to customers before they make a
        booking.
      </h1>
    </div>
  </div>
</template>
