<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div
    v-else-if="appointment"
    className="flex flex-col p-4 justify-center items-center h-[calc(100vh-68px)]">
    <img :src="successLogo" alt="" />
    <h1 class="text-[24px] font-medium text-[#36314C] mb-5">
      Order Confirmed !
    </h1>
    <h1 class="text-[16px] font-bold text-[#36314C]">
      Service ID: {{ appointment.uid }}
    </h1>
    <h1 class="text-[16px] my-2 text-[#36314C]">
      {{ appointment.service_plan.name }} ({{
        appointment.cycle.is_geared === true ? "Geared" : "Non Geared"
      }})
    </h1>
    <h1 class="text-[12px] text-[#36314C]">
      {{
        new Date(appointment.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })
      }}, {{ appointment.slot.time_period }}
    </h1>
    <button
      @click="handleRedirect"
      class="bg-[#FF4A49] mt-4 font-medium text-[24px] text-white rounded-lg w-[142px] h-[45px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
      ok
    </button>
  </div>
</template>

<script>
import successLogo from "../assets/HomeView/successLogo.png";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";

export default {
  name: "Success",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      successLogo,
      isLoading: false,
      appointment: null,
    };
  },
  methods: {
    handleRedirect() {
      this.$router.push({ name: "OrderHistory" });
      this.$store.dispatch("setSearchValue", {
        cycleOption: "",
        cyclePin: "",
        companyId: "",
        cycleModel: "",
        cycleBrand: "",
        selectData: false,
      });
      this.$store.dispatch("setPackageData", null);
      this.$store.dispatch("setBookingAddress", null);
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      const response = await axios.get(
        `api/user/service-appointment/${this.$route.params.id}`
      );
      this.appointment = response.data.service_appointment;
      console.log(response.data);
      // Fetch the brands
    } catch (error) {
      console.error("API error:", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
