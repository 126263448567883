<template>
  <div class="my-10">
    <h1
      class="text-4xl md:text-7xl lg:text-7xl font-medium text-center uppercase">
      TERMS AND CONDITIONS
    </h1>
    <div
      class="flex flex-col-reverse md:flex-row lg:flex-row p-8 md:p-24 lg:p-24">
      <div class="w-full md:w-[70%] lg:w-[70%] lg:pr-[8.5rem]">
        <div id="1">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">ACCEPTANCE:</h2>
          <p class="text-lg mb-3 break-words">
            Legisttech Solutions Private Limited having its registered office at
            A-304, Eastern Business District, LBS Marg, Bhandup-West, Mumbai –
            400078 [hereinafter referred to as “CycleDepo”, “Company” “we”,
            “us”, “our”] provides services which facilitate the users of the
            website at http://app.cycledepo.com/and its mobile application
            (collectively to be referred as the “Platform”) to sell their
            cycles, purchase a cycle or take /give on rent a cycle or bike in a
            convenient manner ( collectively to be referred as  the “Services”)
          </p>
          <p class="text-lg mb-3 break-words">
            Your use of the Platform and the Services are subject to these Terms
            of Use (these “Terms”). For the purpose of the Terms and wherever
            the context so requires, the terms “you” and “your” shall mean any
            person who uses or accesses, whether through manual or automated
            means, the Platform or the Service in any manner whatsoever
            including persons browsing the Platform and its content, posting
            comments or any content or responding to any content on the
            Platform.
          </p>
          <p class="text-lg mb-3 break-words">
            By proceeding to use the Platform, you warrant that you are eligible
            to contract under applicable laws and you are authorized under law
            to sell/rent the cycle or bike, the details of which you have
            provided on the Platform. If you are using or accessing the Platform
            as a representative of any person/entity, you acknowledge that you
            are legally authorized to represent such person/entity. You affirm
            that you are at least 18 years of age and are fully able and
            competent to enter into the terms, conditions, obligations,
            affirmations, representations, and warranties set forth in these
            Terms, and to abide by and comply with these Terms.
          </p>
          <p class="text-lg mb-3 break-words">
            These Terms may be updated by us at any time at our sole discretion.
            You understand and agree that you are solely responsible for
            reviewing these Terms from time to time. Should you object to any
            term or condition of these Terms, any policy or guideline, or any
            subsequent changes thereto or become unhappy with our Platform in
            any way, your only option is to immediately discontinue using the
            Platform.
          </p>
          <p class="text-lg mb-5 break-words">
            In the event of any conflict between this Agreement and any other
            document with respect to Cycledepo’s online cycle reservation or
            rental program, these Terms and Conditions shall govern except
            otherwise set forth from CycleDepo in writing.
          </p>
        </div>
        <div id="2" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            ACCOUNT, REGISTRATION AND ACCESS :
          </h2>
          <p class="text-lg mb-3 break-words">
            The Service as provided by our Platform is available with or without
            creating an account but subject to providing the details as required
            in the prescribed Forms. You agree to provide us with true,
            accurate, current, and complete information about yourself in the
            Form and promptly update us in the event of any change in the
            information provided.
          </p>
          <p class="text-lg mb-3 break-words">
            You hereby unconditionally allow us and our service providers to
            contact you via text messages, email, or phone calls and agree that
            you shall have no claim against us or our service providers under
            any applicable law in relation to such communication.
          </p>
        </div>
        <div id="3" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">PERMITTED USE:</h2>
          <p class="text-lg mb-3 break-words">
            We authorize you to access, view, and use the Platform solely to the
            extent necessary for you to use the Services. Except as expressly
            permitted by these Terms &amp; Conditions, any modification,
            reproduction, redistribution, republication, uploading, posting,
            transmitting, distributing or otherwise exploiting in any way our
            Platform, is strictly prohibited without the prior written
            permission from us.  You additionally agree not to use automated
            means, including spiders, robots, crawlers, data mining tools, or
            the like to download or scrape data from the Service
          </p>
        </div>
        <div id="4" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            THIRD-PARTY LINKS:
          </h2>
          <p class="text-lg mb-3 break-words">
            The Platform may contain links to other third-party websites (“Third
            Party Websites”), which are completely unrelated to the Company. If
            you link to Third Party Websites, you may be subject to those
            Third-Party Websites’ terms and conditions and other policies.
            Company makes no representation or guarantee as to the accuracy or
            authenticity of the information contained in any such Third Party
            Website, and your linking to any other websites is completely at
            your own risk and Company disclaims all liability there
          </p>
        </div>
        <div id="5" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            DISCLAIMER OF WARRANTIES:
          </h2>
          <p class="text-lg mb-3 break-words">
            (a) You expressly acknowledge and agree that use of the Platform is
            entirely at your own risk and that the Platform is provided on an
            “as is” or “as available” basis, without any warranties of any kind.
            All express and implied warranties, including, without limitation,
            the warranties of merchantability, fitness for a particular purpose,
            and non-infringement of proprietary rights are expressly disclaimed
            to the fullest extent permitted by law. To the fullest extent
            permitted by law, we, our officers, directors, employees, and agents
            disclaim all warranties, express or implied, in connection with the
            Platform and your use thereof. We make no warranties or
            representations about the accuracy or completeness of any content on
            the Platform and assumes no liability or responsibility for any
          </p>
          <div class="ml-4">
            <p class="text-lg break-words">
              (i) errors, mistakes, or inaccuracies of any content made
              available through the service,
            </p>
            <p class="text-lg break-words">
              (ii) personal injury or property damage, of any nature whatsoever,
              resulting from your access to and use of the services,
            </p>
            <p class="text-lg break-words">
              (iii) any unauthorized access to or use of our servers and/or any
              and all personal information and/or financial information stored
              therein,
            </p>
            <p class="text-lg break-words">
              (iv) any interruption or cessation of transmission to or from the
              service,
            </p>
            <p class="text-lg break-words">
              (v) any errors or omissions in any content or for any loss or
              damage of any kind incurred as a result of the use of any content
              posted, emailed, communicated, transmitted, or otherwise made
              available via the service.
            </p>
          </div>
          <p class="text-lg mb-3 mt-2 break-words">
            (b) In no event shall we, our officers, directors, employees, or
            agents, be liable for direct, indirect, incidental, special,
            consequential or exemplary damages (even if we have been advised of
            the possibility of such damages), resulting from any aspect of your
            use of the Service, including without limitation whether the damages
            arise from use or misuse of the Service, from inability to use the
            website or the Service, or the interruption, suspension,
            modification, alteration, or termination of the Platform. These
            limitations shall apply to the fullest extent permitted by law.
          </p>
          <p class="text-lg mb-3 mt-2 break-words">
            (c ) You agree to defend, indemnify and hold harmless us, our
            officers, subsidiaries, affiliates, successors, assigns, directors,
            officers, agents, service providers, suppliers and employees, from
            and against any and all claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses (including but not limited
            to attorneys’ fees) arising from: (i) your use of and access to the
            Platform; (ii) your violation of any provision of these Terms or any
            contract entered into with us; or (iii) your violation of any third
            party right, including without limitation any copyright, trademark,
            trade secret or other property, or privacy right. This defense and
            indemnification obligation will survive termination, modification or
            expiration of these Terms and your use of the Platform.
          </p>
        </div>
        <div id="6" class="mt-8">
          <h2 class="mb-6 text-2xl md:text-3xl font-medium">
            General Conditions of this Rental Agreements
          </h2>
          <p class="text-lg mb-3 break-words">
            The Rental /Subscription Agreement is a contract and governs the
            relationships, rights, and obligations between CycleDepo and the
            Subscriber/Customer. The parties to this agreement are the lessor
            and the lessee where Legisttech Solutions Private Limited
            (“CycleDepo”) is the Lessor &amp; the Customer is the Lessee. The
            terms and conditions of this Rental agreement are as follows:
          </p>
          <p class="text-lg mb-3 italic break-words">
            IF YOU DO NOT ACCEPT ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT,
            YOU ARE NOT AUTHORIZED TO USE OR AVAIL OF ANY OF THE SERVICES OF
            CYCLEDEPO INCLUDING PRODUCTS.
          </p>
        </div>
        <div id="7" class="mt-8">
          <h2 class="mb-6 text-2xl md:text-3xl font-medium">TERM</h2>
          <p class="text-lg mb-3 break-words">
            This Agreement shall commence on the date the product more
            particularly set out in the form (of “Product”) is delivered to the
            Customer and remain in full force and effect until the fixed period
            [equivalent to the tenure of the contract that is agreed by the
            Customer while booking the Product] expires and the Products is
            returned to CycleDepo,. The Parties may shorten or extend the
            estimated term of rental by mutual consent.
          </p>
        </div>
        <div id="8" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">PAYMENT POLICY</h2>
          <p class="text-lg mb-3 break-words">
            The customer shall make payment of the total rent amount for the
            tenure/rental period in advance along with the Security Deposit
            amount at the time of booking.CycleDepo shall issue an invoice
            against receipt of the amount. The Customer shall make all payments
            to CycleDepo only. CycleDepo shall not be liable for any payment
            made to any third party by the Customer. CycleDepo reserves the
            right to share information with credit rating agencies pursuant to
            this Agreement. The Customer hereby agrees that non-payment of
            rental dues, late fees, asset value leased, and any other
            non-payment, may affect the credit rating of the Customer and
            CycleDepo shall not be liable for any claim from the Customer for
            sharing such information with credit rating agencies.
          </p>
        </div>
        <div id="9" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            SECURITY DEPOSIT
          </h2>
          <p class="text-lg mb-4 break-words">
            In addition to the monthly rental, the Customer shall pay an
            interest-free refundable security deposit (“Security Deposit”). The
            Security Deposit shall not carry any interest for the entire tenure.
            The Security Deposit shall be refunded to the Customer on the
            termination and after taking delivery of all the Products from the
            possession of the Customer. Once the final quality check is
            performed on all the Products, and the settlement amount is
            confirmed, we’ll initiate the refund process and the Customer will
            receive it in their source account within 10-15 working days.
            CycleDepo shall refund the Security Deposit to the account from
            which the Security Deposit was paid by the Customer, in case the
            Customer wishes to get the refund to any other account, the Customer
            shall provide the details of the account to CycleDepo via e- mail
            from the registered e-mail address of the Customer and/or upload the
            account details on the dashboard available on the CycleDepo website,
            prior to the pick up of the Products. The same account details shall
            be confirmed at the time of the reverse pick-up of the Products.
          </p>
          <p class="text-lg mb-3 break-words">
            In case any damage is found in any Product, CycleDepo shall have the
            right to deduct the charges for the damages or monthly rental dues
            from the Security Deposit paid by the Customer and shall refund the
            remaining amount to the Customer. In case of default in the payment
            of monthly rentals (including late fee), CycleDepo shall have the
            right to deduct such rental dues from the Security Deposit and may
            at its sole discretion refund or forfeit the remaining balance of
            the Security Deposit. The Security Deposit shall not include any
            monthly rental. The Customer cannot request for the monthly invoice
            dues to be adjusted from the Security Deposit.
          </p>
        </div>
        <div id="10" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            ORDER CONFIRMATION
          </h2>
          <p class="text-lg mb-3 font-medium break-words">
            On receipt of the order, payment towards rent, and the Security
            Deposit, CycleDepo shall confirm the order with the Customer by
            sending a confirmation to the registered e-mail address of the
            Customer. In the event, that any product selected by the Customer is
            unavailable, CycleDepo shall inform the same to the Customer.
            CycleDepo reserves the right to replace any product selected by the
            Customer in the event of unavailability. The Customer shall be
            provided with an option to either accept or deny such substitution
            at the time of confirming the order. Mere payment of the rent amount
            and Security Deposit shall not be considered as a valid contract. In
            the event, that the Customer does not accept the substitute Product,
            CycleDepo shall refund the Security Deposit paid by the Customer as
            per Clause 4 of this Agreement.
          </p>
          <p class="text-lg mb-3 break-words">
            The order raised by the Customer shall be processed subject to
            successful verification of the KYC and serviceability of the
            Customer location as per CycleDepo Policy. In case the KYC
            verification is not successful, or the location is not serviceable
            by CycleDepo, CycleDepo reserves the right to reject the Customer’s
            order any time prior to delivery, at its sole discretion without
            assigning any reason even after successful KYC or serviceability of
            the location. In the event the order is rejected by CycleDepo, the
            Security Deposit paid by the Customer shall be refunded to the
            Customer as per this Agreement.
          </p>
        </div>
        <div id="11" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            OWNERSHIP OF PRODUCTS
          </h2>
          <p class="text-lg mb-3 break-words">
            CycleDepo shall at all times during the term of this Agreement,
            retain title to and/or be the legal as well beneficial owners of the
            Products delivered to the Customer, pursuant to the Agreement.
            Nothing in this Agreement shall be construed as a transfer of
            ownership of the Products to the Customer. The Customer shall give
            immediate notice to CycleDepo if any of the Product is lost or
            damaged or about to become liable or is threatened with seizure and
            the Customer shall indemnify CycleDepot against all loss and damage
            caused by such action against its Products.
          </p>
        </div>
        <div id="12" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">TERMINATION:</h2>
          <p class="text-lg mb-3 break-words">
            In the event, that the Customer does not wish to extend the rental
            period beyond the Agreement date, the Agreement shall terminate on
            the last day of the rental term.This Agreement can be terminated on
            (i) default of payment of rental dues or any other payment dues by
            the Customer; and (ii) breach of any of the terms of this Agreement.
          </p>
        </div>
        <div id="13" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            Consequences of termination:
          </h2>
          <div class="ml-3">
            <p class="text-lg mb-1 break-words">
              1. CycleDepo shall have the right to take possession of the
              Products delivered to the Customer immediately;
            </p>
            <p class="text-lg mb-1 break-words">
              2. Any payment pending from the Customer shall become payable
              immediately to CycleDepo
            </p>
            <p class="text-lg mb-1 break-words">
              3. The Security Deposit paid by the Customer shall be refunded to
              the Customer post the damage assessment of the Products, as per
              clause 4 of this Agreement.
            </p>
            <p class="text-lg mb-1 break-words">
              4. In case of termination due to non-payment of rental dues, the
              Security Deposit refund shall be determined subject to provisions
              of this Agreement.
            </p>
          </div>
        </div>
        <div id="14" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">INDEMNIFICATION</h2>
          <p class="text-lg mb-3 break-words">
            The Customer shall indemnify, defend, and hold CycleDepo harmless
            from and against any claim, demand, cause of action, or loss or
            liability (including, but not limited to, attorneys’ fees and costs)
            for any Product damage or personal injury or loss of life arising
            from the Customer’s use of the Product by any cause. The provisions
            of this clause shall survive the termination of this Agreement with
            respect to any claim or liability accruing before such termination.
            In no event shall CycleDepo be liable for any direct, indirect,
            special, or consequential loss or damage arising out of Customer’s
            use of the Product
          </p>
        </div>
        <div id="15" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">INSPECTION</h2>
          <p class="text-lg mb-3 break-words">
            CycleDepo reserves the right to inspect the Product delivered and/or
            given on rent to the Customer during the term of the Agreement.
            CycleDepo shall provide reasonable prior intimation to the Customer
            regarding the visit for inspection of its representative to avoid
            any inconvenience to the Customer. The Customer shall ensure that
            the representative of CycleDepo is provided with proper access to
            the Product at the premises for inspection.
          </p>
        </div>
        <div id="16" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">RELOCATION</h2>
          <p class="text-lg mb-3 break-words">
            In case the Customer wishes to relocate or remove the Product(s)
            from the registered address, a request shall be made to CycleDepo 2
            (two) weeks prior to the date the Customer wishes to relocate the
            Product along with the address proof of the new address. Once the
            request is raised, CycleDepo shall assess the feasibility and
            serviceability and inform its acceptance or rejection of such
            request to Customer within seven days thereafter. Relocation shall
            be subject to successful verification of the KYC of the Customer’s
            new address and serviceability of the new location. In the event,
            that the location is not serviceable by CycleDepo, the same shall be
            treated as early closure of the Agreement as per the Flexi Tenure
            Policy and the rental dues shall be payable as per the Flexi Tenure
            Policy.
          </p>
        </div>
        <div id="17" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">SWAP OPTION:</h2>
          <p class="text-lg mb-3 break-words">
            CycleDepo allows its Customers to swap the Products taken on a
            rental basis on the following terms and conditions:
          </p>
          <div class="ml-2">
            <p class="text-lg mb-1 break-words">
              <span class="font-extrabold">•</span> Customer is eligible for
              swapping of Product after completion of a minimum of 12 (twelve)
              months continuous tenure;
            </p>
            <p class="text-lg mb-1 break-words">
              <span class="font-extrabold">•</span> Swap option will be valid
              only on Products of equal or of higher value than the current
              Product used by the Customer;
            </p>
            <p class="text-lg mb-1 break-words">
              <span class="font-extrabold">•</span> Time taken for swapping of
              the Product shall be 7 (seven) days from the receipt of the
              request by CycleDepo. Subject to the availability of the
              new/alternate Product requested by the Customer; and
            </p>
            <p class="text-lg mb-1 break-words">
              <span class="font-extrabold">•</span> The swapping option shall be
              available only for the Products of the same category.
            </p>
          </div>
        </div>
        <div id="18" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">ASSIGNMENT</h2>
          <p class="text-lg mb-3 break-words">
            The Customer shall not assign or transfer any interest in this
            Agreement or the Products without the written consent of CycleDepo.
            Any such transfer or assignment shall be considered as illegal and
            hence a violation of the terms of this Agreement. CycleDepo reserves
            the right to assign this Agreement, to any third party without prior
            notice to the Customer.
          </p>
        </div>
        <div id="19" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            LIMITATION OF LIABILITY
          </h2>
          <p class="text-lg mb-3 break-words">
            In no event shall CycleDepo, its officers, directors, employees, or
            agents, be liable for direct, indirect, incidental, special,
            consequential, or exemplary damages (even if CycleDepo has been
            advised of the possibility of such damages), resulting from any
            aspect of your use of the platform or the service, including without
            limitation whether the damages arise from use or misuse of the
            platform or the service, from inability to use the platform or the
            service, or the interruption, suspension, modification, alteration,
            or termination of the platform or the service. Such limitation of
            liability shall also apply with respect to damages incurred by
            reason of other services or products received in connection with the
            platform or the service or any links on the platform, as well as by
            reason of any information, opinions, or advice received through or
            advertised in connection with the platform or the service or any
            links on the platform. These limitations shall apply to the fullest
            extent permitted by law. You specifically acknowledge and agree.
            CycleDepo makes no representations or warranties that the platform
            is appropriate for use in other locations other than in India. Those
            who access or use the platform from other jurisdictions do so at
            their own volition and risk and are responsible for compliance with
            local law.
          </p>
        </div>
        <div id="20" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            ENTIRE AGREEMENT
          </h2>
          <p class="text-lg mb-3 break-words">
            This Agreement (together with the Annexure) constitutes the entire
            agreement between CycleDepo and the Customer. The acceptance of this
            Agreement also signifies the acceptance of the Customer, to the
            terms and conditions and various policies specified on the CycleDepo
            website i.e. www.cycleonrent.com (including the privacy policy).
          </p>
        </div>
        <div id="21" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            GOVERNING LAW AND JURISDICTION:
          </h2>
          <p class="text-lg mb-3 break-words">
            This Agreement including the Terms and the relationship between you
            and CycleDepo shall be governed by the laws of India. Any dispute
            between the Customer and CycleDepo arising out of and in relation to
            the use of Services the courts at Mumbai shall have exclusive
            jurisdiction.
          </p>
        </div>
        <div id="DISCLAIMER" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">DISCLAIMER</h2>
          <p class="text-lg mb-3 break-words">
            CycleDepo reserves its right to cancel any orders completely or
            partially before delivery without prior information and in such
            scenarios, CycleDepo will initiate the refund process for the amount
            paid and the Customer will receive it in their source account within
            10-15 working days.
          </p>
        </div>
        <div id="DELIVERY" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            DELIVERY POLICY:
          </h2>
          <p class="text-lg mb-3 break-words">
            On confirmation of the order by the Customer, CycleDepo may deliver
            the Products to the location specified by the Customer subject to
            payment of applicable transportation charges. The cost of the
            delivery shall be borne by CycleDepo if the subscription period is
            more than 18 months. The Customer shall be present at the location
            at the time of delivery agreed between CycleDepo and the Customer.
            In case the Customer is unavailable at the time of delivery the
            Customer shall appoint a representative (give an authorization
            letter) for taking delivery of the Products and the same shall be
            communicated to CycleDepo prior to the delivery. The representative
            shall provide a copy of his/her ID proof and authorization letter
            from the Customer to the delivery personnel assigned by CycleDepo In
            case the Customer is not present or has not assigned a
            representative to take delivery, at the location and a second
            delivery attempt is required, CycleDepo shall charge an extra
            delivery cost to the Customer.
          </p>
          <p class="text-lg mb-4 break-words">
            CycleDepo shall inspect the quality and ensure that the Products are
            working and in usable condition before the delivery of the Products
            to the Customer. The Customer or its appointed representative shall
            inspect the Products for any damage and quality during the time of
            delivery. In case any Product is damaged during transit or unfit for
            use, CycleDepo shall replace the same at its own cost and in case a
            replacement is not required, such damage shall be noted in the
            delivery receipt and a photo of the same shall be taken for record.
            In case any claim of damage is brought against the Product after the
            acceptance of delivery by the Customer, CycleDepo shall not be
            responsible for replacing the Product and shall levy damage to be
            ascertained as per the damage policy below.
          </p>
          <p class="text-lg mb-4 break-words">
            The Customer shall ensure the entry of the delivery vehicle inside
            the premises where the delivery location is situated and ensure that
            prior permission is obtained for the use of the elevator of the
            building, for delivery of the Products to the location.
          </p>
        </div>
        <div id="POLICY" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            DELIVERY POLICY:
          </h2>
          <p class="text-lg mb-4 break-words">
            The Customer hereby agrees that any damage caused to the Product or
            theft (including disappearance) or loss, shall be liable towards
            repair and replacement cost of the Product. In the event, that the
            Product is stolen or damaged beyond repair, the Customer shall be
            liable to pay CycleDepo the market price of the Product. Minor
            scratches shall be considered as normal wear and tear. The extent of
            damage will be ascertained by comparing against the document signed
            by the Customer and the photographs taken on delivery and return
            pickup day.
          </p>
          <p class="text-lg mb-4 break-words">
            CycleDepo shall ascertain the extent of the damage and the
            applicable penalty for such damage on the receipt of the Products
            from the Customer. In the event, that it is found that in any
            Product, any substandard parts are used, or repair carried out by
            any person not authorized by CycleDepo then a penalty may be levied
            as per the policy of CycleDepo. Any removal, alteration,
            disfiguring, or cover of any numbers, lettering, or insignia
            displayed on any Product shall be considered as damage to the
            Product and shall be chargeable against the Customer. A damage claim
            report shall be sent to the Customer’s registered e-mail address.
          </p>
          <p class="text-lg mb-3 break-words">
            A quality check report stating the damages if any or a clearance
            sheet will be created and a copy of the same will be provided to the
            Customer immediately.
          </p>
        </div>
        <div id="MAINTENANCE" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            MAINTENANCE POLICY:
          </h2>
          <p class="text-lg mb-3 break-words">
            CycleDepo shall provide periodic maintenance service of the Products
            delivered to the Customer, for the entire duration of the Agreement
            or on request from the Customer. CycleDepo shall provide cleaning
            and checkup (one time per quarter free of cost) of the bicycle only
            after completion of a minimum 6 months tenure by the Customer. The
            periodic maintenance shall not include any damage or breakdown due
            to mishandling by the Customer. CycleDepo shall carry out the
            maintenance/repair of the Product within seven working days after
            the request has been raised by the Customer. In the event, that the
            issue is not resolved during repair, CycleDepo shall arrange for a
            replacement of the Product. Any additional cost incurred by Cycle
            during the maintenance and cleaning due to damages shall be borne
            solely and paid by the Customer, either via online payment or as
            advised by the CycleDepo service representative.
          </p>
        </div>
        <div id="FLEXI" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            FLEXI TENURE POLICY
          </h2>
          <p class="text-lg mb-4 break-words">
            CycleDepo provides its Customers, an option to opt for flexible
            tenure ( “Flexi Tenure”) for early closure and/or extension of the
            term of the Agreement. In case of early closure, the Customer can
            request to close the order by informing CycleDepo 2 (two) weeks
            prior to the closure date. CycleDepo provides its Customers with
            different monthly rental rates depending on the tenure of the
            Agreement at the time of order placement. In the event of early
            closure, the difference in monthly rental amount shall be calculated
            as per the monthly rental rates between Agreement tenure (the tenure
            selected at order placement) and Actual tenure served (effective
            tenure at early closure) will have to be paid. The minimum tenure
            for the Product shall be calculated as 3 months.
          </p>
          <p class="text-lg mb-3 break-words">
            For extension of the term beyond the Agreement tenure by the
            Customer, the monthly rate applicable at the time of extension shall
            be followed for calculation of the rental amount for the extension
            period. Any extension or early closure shall be done only through
            the CycleDepo website and such extension or early closure shall be
            deemed to be pursuant to this Agreement. CycleDepo reserves the
            right to revise the rental rates at any time at its sole discretion.
          </p>
        </div>
        <div id="PROHIBITED" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">PROHIBITED USES</h2>
          <p class="text-lg mb-2 break-words">
            1. The use of products (i.e. cycle, bike, and e-bike) is prohibited
            under the following conditions:
          </p>
          <div class="ml-3 text-lg">
            <p>1. Any speed race or competition.</p>
            <p>
              2. for transporting goods or use for primary business purposes.
            </p>
            <p>
              3. by any person who is under the influence of (i) alcohol or (ii)
              any drug or medication under the effects of which the operation of
              a cycle, bike, or scooter is prohibited or not recommended.
            </p>
            <p>
              4. in the carrying out of any crime or any other illegal activity.
            </p>
            <p>
              5. in an imprudent, negligent, or abusive manner or for any
              abnormal use of the Products.
            </p>
            <p>
              6. by any person who has provided CycleDepo with false information
              or whose representations are determined to be false (including,
              without limitation, regarding his name, age, or address.)
            </p>
            <p>
              7. Driving and parking a cycle bike or e-bike at /in any
              prohibited areas.
            </p>
            <p>
              8. Driving a cycle/bike while using a mobile phone or any other
              mobile communication device in any manner whatsoever, including
              while sending an SMS, emailing, using a cell phone with or without
              a hands-free device, otherwise using a mobile communication device
              that may distract you from driving a cycle/bike or otherwise
              engaging in similar activities that may be prohibited by
              applicable law.
            </p>
            <p>
              9. Transporting objects with the cycle depo’s cycle that could –
              due to their size, shape, or weight – adversely impact the cycle’s
              handling safety or that could damage the cycle depo’s cycle /bike
              or, by virtue of such object/s protruding outside, affect the
              safety of cycle/bike /vehicles driving in the vicinity of the said
              cycle depo’s cycle/bike.
            </p>
            <p>
              10. Transporting more passengers than legally allowed on the cycle
              or bike.
            </p>
            <p>11. Driving a motorcycle, bike, or scooter without a helmet.</p>
            <p>
              12. More than one pillion rider, when driving a cycle, motorcycle,
              bike, or scooter.
            </p>
          </div>
          <p class="text-lg mb-2 break-words">
            2. The foregoing examples are not intended to be exhaustive. Any
            unreasonable or inappropriate use of the cycle, bike, or scooter, as
            determined by CycleDepo in its sole discretion, may be deemed a
            violation of these Terms and Conditions. CycleDepo may immediately
            suspend or terminate the use of its Service by any Customer for a
            violation of any of these Rules. Upon suspension or termination, any
            existing reservations for the Customer may be canceled by CycleDepo
            at its sole discretion. In addition, Customer will be responsible
            for any and all costs, charges, fees, and expenses incurred by
            CycleDepo as a result of a breach of any of these Terms and
            Conditions.
          </p>
        </div>
        <div id="RESERVATION" class="mt-8">
          <h2 class="mb-4 text-2xl md:text-3xl font-medium">
            RESERVATION TO HIRE PRODUCTS OF CYCLE DEPO:
          </h2>
          <p class="text-lg mb-2 break-words">
            1. Reservation is required for all potential bookings. Customers
            must always reserve a Product in advance of use and will be billed
            for usage fees at the time of reservation. The customer is required
            to fulfill the below-mentioned conditions at the time of taking
            possession of the Product for the scheduled Ride/Trip:
          </p>
          <p class="text-lg ml-4 mb-2 break-words">
            1. Customer must carry and support CycleDepo in capturing the image
            of any one out of the following documents in original, as an
            additional document to be placed on records of CycleDepo to justify
            his/her identity: <br />
            1. EPIC (Electoral Photo Identity Card), commonly known as Voter ID;
            or
            <br />
            2. Valid Passport; or
          </p>
          <p class="text-lg mb-2 break-words">
            <span class="font-extrabold">•</span> Aadhaar Card from the Unique
            Identification Authority of India; or
          </p>
          <p class="text-lg break-words">
            1. Government Department (Including Public Sector Undertakings)
            Identity Card; or
          </p>
          <p class="text-lg mb-4 break-words">
            2. Identity Card of Tier -1/2 Universities, Colleges, and
            Educational Institutions, which contains the photo and address.
          </p>
          <p class="text-lg mb-3 break-words">
            1. Customer must cooperate with the representative of cycleDepo in
            taking a photo of cycle/bike/product along with Customer, which is
            meant for the safety of Customer and record purposes of CycleDepo
          </p>
          <p class="text-lg mb-2 break-words">
            Customer shall be held solely responsible if any of the above
            conditions are not being completed successfully at the time when
            possession of Cycle/Bike/Product was supposed to be taken by
            Customer and CycleDepo reserves the right not to hand over the
            possession of Cycle/Bike/Product. Consequently, the booking will
            stand completed without any further onus of CycleDepo to refund or
            return the booking amount.
          </p>
          <p class="text-lg mb-3 break-words">
            2. If You wish to cancel a reservation or shorten the period for
            which a cycle/bike has been reserved, You must do so in advance, in
            pursuance of the Fee Policy
          </p>
          <p class="text-lg mb-3 break-words">
            3. If You wish to extend a reservation, You may do so only if the
            following conditions are fulfilled:
          </p>
          <div class="ml-4">
            <p class="text-lg break-words">
              1. The online payment for the extension has been made and
              successfully receipt by cycleDepo; and
            </p>
            <p class="text-lg break-words">
              2. The cycle/bike/product is available for use for the extension
              period (for example, it is not reserved by another Customer); and
            </p>
            <p class="text-lg mb-4 break-words">
              3. The request to extend is completed prior to the scheduled
              expiration time of Your existing reservation.
            </p>
            <p class="text-lg mb-4 break-words">
              In case, any of the above conditions are missing after the expiry
              of the booking (initial or immediate prior) then You will be
              deemed to possess the cycle/bike/product without authorization and
              must hand over the same to cycleDepo s representative without
              fail/ delay. A delay beyond one hour from the expected handover
              time of the cycle/bike/product or after the expiry of booking as
              the case may be, will be making You guilty of breach of trust and
              Your involvement in theft of the cycle/bike/product would be
              presumed without any doubt.
            </p>
          </div>
          <p class="text-lg mb-4 break-words">
            4. In the event Your reservation is not extended successfully (even
            if You have put in a request for extending the reservation but which
            has not been confirmed or has been denied by cycleDepo for any
            reason) and You do not return the vehicle to the correct and
            designated cycleDepo space by the end time of Your reservation,
            cycleDepo reserves the right to initiate the criminal proceedings
            against You and simultaneously will make all reasonable efforts to
            take possession of the cycle/bike/product from You immediately or
            any time thereafter, and at any location
          </p>
          <p class="text-lg mb-4 break-words">For the avoidance of doubt,</p>
          <p class="text-lg break-words">
            1. You will be charged a late fee, inconvenience fee, and any other
            charges applicable as per Our Fee Policy from the scheduled end time
            of Your booking till the time the vehicle is actually recovered by
            cycleDepo,
          </p>
          <p class="text-lg break-words">
            2. You will be charged any additional costs viz; legal attorney
            fees, consultants fees, travel costs, etc. incurred by cycleoDepoin
            taking possession of the vehicle from You, and
          </p>
          <p class="text-lg break-words">
            3. You will not be provided any compensation or alternate means of
            transportation in such case and will not have the right to ride in
            cycleDepo cycle/bike/product anymore. You shall hold cycleDepo, its
            officers, employees, agents, and affiliates harmless against any
            claim in connection with the cycle/bike/product and/or its
            re-possession and shall irrevocably and unconditionally indemnify
            cycleDepo against any cost, loss, or liability suffered by cycleDepo
            arising out of or in connection with any claim made by a third party
            against cycleDepo in relation to the cycle/bike/product and/or its
            re-possession.
          </p>
        </div>
      </div>
      <div class="w-full md:w-[30%] lg:w-[30%] mb-6">
        <div class="md:sticky md:top-[7.5rem] lg:sticky lg:top-[7.5rem]">
          <h2 class="mb-4 text-xl md:text-3xl font-medium">
            Table of contents
          </h2>
          <button @click="scrollToCycleDepo('1')" class="text-base font-medium">
            1. ACCEPTANCE
          </button>
          <br />
          <button @click="scrollToCycleDepo('2')" class="text-base font-medium">
            2. ACCOUNT, REGISTRATION AND ACCESS
          </button>
          <br />
          <button @click="scrollToCycleDepo('3')" class="text-base font-medium">
            3. PERMITTED USE</button
          ><br />
          <h1
            @click="scrollToCycleDepo('4')"
            class="text-base font-medium cursor-pointer">
            4. THIRD-PARTY LINKS
          </h1>
          <button @click="scrollToCycleDepo('5')" class="text-base font-medium">
            5. DISCLAIMER OF WARRANTIES</button
          ><br />
          <button @click="scrollToCycleDepo('6')" class="text-base font-medium">
            6. General Conditions of this Rental Agreements</button
          ><br />
          <button @click="scrollToCycleDepo('7')" class="text-base font-medium">
            7. TERM</button
          ><br />
          <button @click="scrollToCycleDepo('8')" class="text-base font-medium">
            8. PAYMENT POLICY
          </button>
          <br />
          <button @click="scrollToCycleDepo('9')" class="text-base font-medium">
            9. SECURITY DEPOSIT
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('10')"
            class="text-base font-medium">
            10. ORDER CONFIRMATION
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('11')"
            class="text-base font-medium">
            11. OWNERSHIP OF PRODUCTS
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('12')"
            class="text-base font-medium">
            12. TERMINATION
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('13')"
            class="text-base font-medium">
            13. Consequences of termination
          </button>
          <br />
          <button
            @click="scrollToCycleDepo('14')"
            class="text-base font-medium">
            14. INDEMNIFICATION
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToCycleDepo(elementId) {
      const cycleDepoDiv = document.getElementById(elementId);
      if (cycleDepoDiv) {
        cycleDepoDiv.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
