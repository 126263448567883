<template>
    <header class="block md:hidden lg:hidden">
      <div class="bg-[#36314C] p-3 flex gap-6 items-center">
        <svg
          @click="$router.push({ name: 'Profile' })"
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          class="cursor-pointer"
          height="17"
          viewBox="0 0 10 17"
          fill="none">
          <path
            d="M8.33333 16.6667L0 8.33333L8.33333 0L9.8125 1.47917L2.95833 8.33333L9.8125 15.1875L8.33333 16.6667Z"
            fill="white" />
        </svg>
        <h1 class="text-[20px] font-semibold text-white">Order History</h1>
      </div>
    </header>
    
    <LoadingSpinner2 v-if="loading" :style="{ minHeight: '400px' }"/>
    
    <div v-else class="bg-[#F4F4F4] p-4 lg:px-40">
      <div class="flex items-center justify-between">
        <div class="flex w-full md:w-1/2 lg:1/2 items-center gap-10">
          <h1 class="text-[36px] font-semibold hidden md:block lg:block">
            My Rental History
          </h1>
        </div>
        <div class="hidden md:block lg:block">
          <div
            @click="$router.push({ name: 'Profile' })"
            class="flex text-[12px] md:text-[26px] lg:text-[26px] font-bold cursor-pointer items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 30 30"
              fill="none">
              <path
                d="M9.78125 16.25L16.7813 23.25L15 25L5 15L15 5L16.7813 6.75L9.78125 13.75H25V16.25H9.78125Z"
                fill="#FF4A49" />
            </svg>
            <h1 class="font-bold text-[#FF4A49]">Back to my account</h1>
          </div>
        </div>
      </div>
      <!--  v-if="selectedOption === 'service'" -->
      <div
        v-for="booking in bookings"
        :key="booking.id"
        class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
        <div class="flex items-start justify-between">
          <div
            class="flex flex-col-reverse md:flex-row lg:flex-row items-start gap-2 md:gap-4 lg:gap-4">
            <div>
              <h1
                class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#867EA8]">
                Booking ID: {{ booking.id }}
              </h1>
              <h2
                class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
                Date: {{ $dayjs(booking.created_at).format('DD MMM YYYY, h:mm A')  }}
              </h2>
            </div>
          </div>
          <router-link
            :to="{ name: 'RentalOrderDetails', params: { id: booking.id } }"
            class="flex cursor-pointer items-center gap-2">
            <h1
              class="text-[16px] md:text-[24px] lg:text-[24px] font-medium text-[#E42724]">
              DETAILS
            </h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none">
              <path
                d="M1.50743 0.794067L10 9.28664L1.50743 17.7792L0 16.2718L6.98514 9.28664L0 2.3015L1.50743 0.794067Z"
                fill="#E42724" />
            </svg>
          </router-link>
        </div>
  
        <div class="mt-[5px]">
          <div class="flex items-center gap-3">
            <div class="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none">
                <path
                  d="M12.9562 15.1875L15.5625 12.6L14.775 11.8125L12.9562 13.5937L12.225 12.8625L11.4375 13.6687L12.9562 15.1875ZM4.5 6.75H13.5V5.25H4.5V6.75ZM13.5 17.25C12.4625 17.25 11.578 16.8842 10.8465 16.1527C10.115 15.4212 9.7495 14.537 9.75 13.5C9.75 12.4625 10.1157 11.578 10.8472 10.8465C11.5787 10.115 12.463 9.7495 13.5 9.75C14.5375 9.75 15.422 10.1157 16.1535 10.8472C16.885 11.5787 17.2505 12.463 17.25 13.5C17.25 14.5375 16.8842 15.422 16.1527 16.1535C15.4212 16.885 14.537 17.2505 13.5 17.25ZM2.25 16.5V2.25H15.75V8.75625C15.5125 8.64375 15.2687 8.55 15.0187 8.475C14.7687 8.4 14.5125 8.34375 14.25 8.30625V3.75H3.75V14.2875H8.30625C8.36875 14.675 8.46575 15.0437 8.59725 15.3937C8.72875 15.7437 8.9005 16.075 9.1125 16.3875L9 16.5L7.875 15.375L6.75 16.5L5.625 15.375L4.5 16.5L3.375 15.375L2.25 16.5ZM4.5 12.75H8.30625C8.34375 12.4875 8.4 12.2312 8.475 11.9812C8.55 11.7312 8.64375 11.4875 8.75625 11.25H4.5V12.75ZM4.5 9.75H9.825C10.3 9.2875 10.8532 8.92175 11.4847 8.65275C12.1162 8.38375 12.788 8.2495 13.5 8.25H4.5V9.75Z"
                  fill="#867EA8" />
              </svg>
            </div>

          </div>
        </div>
        <hr class="my-[10px] bg-[#CCC6E9]" />
        <div>
          <div class="flex items-center justify-between">
            <h1 class="text-[18px] font-bold text-[#36314C]">
              {{ booking.rental_listing.name }}
            </h1>
            <h2
              class="text-[14px] md:text-[18px] lg:text-[18px] font-bold text-[#605884]">
              ₹ {{ booking.total_amount }}
            </h2>
          </div>
          <div class="flex items-center justify-between">
            <div
              v-if="booking.amount_paid > 0"
              class="flex items-center gap-6">
              <h2
                class="text-[10px] md:text-[12px] lg:text-[12px] text-[#71AE0D] font-medium">
                PAID
              </h2>
              <h2
                class="text-[12px] md:text-[14px] lg:text-[14px] text-[#71AE0D] font-bold">
                ₹ {{ booking.amount_paid }}
              </h2>
            </div>
          </div>
          <div class="flex items-center justify-between">

            
            <h1 class="text-[12px] text-[#867EA8]">
              Booked For: <span class="font-medium">{{ $dayjs(booking.start_date).format('DD MMM YYYY') }}</span> - <span class="font-medium">{{ $dayjs(booking.end_date).format('DD MMM YYYY') }}</span>
            </h1>

            <div
              v-if="booking.total_amount - booking.amount_paid> 0"
              class="flex items-center gap-5">
              <h2
                class="text-[10px] md:text-[14px] lg:text-[14px] text-[#FF4A49] font-medium">
                PAYMENT PENDING
              </h2>
              <h2
                class="text-[14px] md:text-[16px] lg:text-[16px] text-[#FF4A49] font-bold">
                ₹ {{ booking.total_amount - booking.amount_paid }}
              </h2>
            </div>
          </div>
        </div>
        
      </div>
      <!-- v-if="selectedOption === 'rental'" -->
      <div>
        <div
          v-if="selectedOption === 'rental'"
          class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
          <div class="flex items-start justify-between">
            <div
              class="flex flex-col-reverse md:flex-row lg:flex-row items-start gap-2 md:gap-4 lg:gap-4">
              <div>
                <h1
                  class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#867EA8]">
                  Booking ID: XRT14556
                </h1>
                <h2
                  class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
                  Date: 2 Sep 2003
                </h2>
              </div>
              <button
                class="text-[13px] md:text-[15px] lg:text-[15px] text-[#71AE0D] font-medium w-[110px] md:w-[170px] lg:w-[170px] h-[25px] md:h-[30px] lg:h-[30px] border uppercase rounded-lg border-[#867EA8]">
                DELIVERED
              </button>
            </div>
            <div class="flex cursor-pointer items-center gap-2">
              <h1
                @click="$router.push({ name: 'RentalOrderDetails', params: { id: booking.id } })"
                class="text-[16px] md:text-[24px] lg:text-[24px] font-medium text-[#E42724]">
                DETAILS
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path
                  d="M1.50743 0.794067L10 9.28664L1.50743 17.7792L0 16.2718L6.98514 9.28664L0 2.3015L1.50743 0.794067Z"
                  fill="#E42724" />
              </svg>
            </div>
          </div>
          <div class="mt-[5px]">
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              Hero Cycles - (Non Gear) COLT IBC 26T
            </h1>
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              + 2 Accessories
            </h1>
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-[12px] text-[#959595]">Start Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">14 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">End Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">17 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">Duration</h1>
                <h2 class="text-[13px] font-medium text-[#71AE0D]">3 Days</h2>
              </div>
            </div>
          </div>
          <hr class="my-[10px] bg-[#CCC6E9]" />
          <div>
            <div class="flex items-start justify-between">
              <h1 class="text-[16px] font-bold text-[#36314C]">
                Payment Summary
              </h1>
              <div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#36314C] font-medium">
                    TOTAL
                  </h2>
                  <h2
                    class="text-[14px] md:text-[18px] lg:text-[18px] font-bold text-[#605884]">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#71AE0D] font-medium">
                    PAID
                  </h2>
                  <h2
                    class="text-[12px] md:text-[16px] lg:text-[16px] text-[#71AE0D] font-bold">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#FF4A49] font-medium">
                    PAYMENT PENDING
                  </h2>
                  <h2
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#FF4A49] font-bold">
                    ₹ 0
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="selectedOption === 'rental'"
          class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
          <div class="flex items-start justify-between">
            <div
              class="flex flex-col-reverse md:flex-row lg:flex-row items-start gap-2 md:gap-4 lg:gap-4">
              <div>
                <h1
                  class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#867EA8]">
                  Booking ID: XRT14556
                </h1>
                <h2
                  class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
                  Date: 2 Sep 2003
                </h2>
              </div>
              <button
                class="text-[13px] md:text-[15px] lg:text-[15px] text-[#6D3186] font-medium w-[150px] md:w-[170px] lg:w-[170px] h-[25px] md:h-[30px] lg:h-[30px] border uppercase rounded-lg border-[#867EA8]">
                DEPOSIT REFUNDED
              </button>
            </div>
            <div class="flex cursor-pointer items-center gap-2">
              <h1
                @click="$router.push({ name: 'RentalOrderDetails' })"
                class="text-[16px] md:text-[24px] lg:text-[24px] font-medium text-[#E42724]">
                DETAILS
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path
                  d="M1.50743 0.794067L10 9.28664L1.50743 17.7792L0 16.2718L6.98514 9.28664L0 2.3015L1.50743 0.794067Z"
                  fill="#E42724" />
              </svg>
            </div>
          </div>
          <div class="mt-[5px]">
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              Hero Cycles - (Non Gear) COLT IBC 26T
            </h1>
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              + 2 Accessories
            </h1>
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-[12px] text-[#959595]">Start Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">14 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">End Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">17 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">Duration</h1>
                <h2 class="text-[13px] font-medium text-[#71AE0D]">3 Days</h2>
              </div>
            </div>
          </div>
          <hr class="my-[10px] bg-[#CCC6E9]" />
          <div>
            <div class="flex items-start justify-between">
              <h1 class="text-[16px] font-bold text-[#36314C]">
                Payment Summary
              </h1>
              <div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#36314C] font-medium">
                    TOTAL
                  </h2>
                  <h2
                    class="text-[14px] md:text-[18px] lg:text-[18px] font-bold text-[#605884]">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#71AE0D] font-medium">
                    PAID
                  </h2>
                  <h2
                    class="text-[12px] md:text-[16px] lg:text-[16px] text-[#71AE0D] font-bold">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#FF4A49] font-medium">
                    DEPOSIT REFUNDED
                  </h2>
                  <h2
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#FF4A49] font-bold">
                    ₹ 2000
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="selectedOption === 'rental'"
          class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
          <div class="flex items-start justify-between">
            <div
              class="flex flex-col-reverse md:flex-row lg:flex-row items-start gap-2 md:gap-4 lg:gap-4">
              <div>
                <h1
                  class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#867EA8]">
                  Booking ID: XRT14556
                </h1>
                <h2
                  class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
                  Date: 2 Sep 2003
                </h2>
              </div>
              <button
                class="text-[13px] md:text-[15px] lg:text-[15px] text-[#71AE0D] font-medium w-[150px] md:w-[170px] lg:w-[170px] h-[25px] md:h-[30px] lg:h-[30px] border uppercase rounded-lg border-[#867EA8]">
                DELIVERY SCHEDULED
              </button>
            </div>
            <div class="flex cursor-pointer items-center gap-2">
              <h1
                @click="$router.push({ name: 'RentalOrderDetails' })"
                class="text-[16px] md:text-[24px] lg:text-[24px] font-medium text-[#E42724]">
                DETAILS
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path
                  d="M1.50743 0.794067L10 9.28664L1.50743 17.7792L0 16.2718L6.98514 9.28664L0 2.3015L1.50743 0.794067Z"
                  fill="#E42724" />
              </svg>
            </div>
          </div>
          <div class="mt-[5px]">
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              Hero Cycles - (Non Gear) COLT IBC 26T
            </h1>
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              + 2 Accessories
            </h1>
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-[12px] text-[#959595]">Start Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">14 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">End Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">17 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">Duration</h1>
                <h2 class="text-[13px] font-medium text-[#71AE0D]">3 Days</h2>
              </div>
            </div>
          </div>
          <hr class="my-[10px] bg-[#CCC6E9]" />
          <div>
            <div class="flex items-start justify-between">
              <h1 class="text-[16px] font-bold text-[#36314C]">
                Payment Summary
              </h1>
              <div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#36314C] font-medium">
                    TOTAL
                  </h2>
                  <h2
                    class="text-[14px] md:text-[18px] lg:text-[18px] font-bold text-[#605884]">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#71AE0D] font-medium">
                    PAID
                  </h2>
                  <h2
                    class="text-[12px] md:text-[16px] lg:text-[16px] text-[#71AE0D] font-bold">
                    ₹ 1000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#FF4A49] font-medium">
                    PAYMENT PENDING
                  </h2>
                  <h2
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#FF4A49] font-bold">
                    ₹ 5000
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="selectedOption === 'rental'"
          class="p-4 lg:p-8 bg-white mt-[20px] rounded-[5px]">
          <div class="flex items-start justify-between">
            <div
              class="flex flex-col-reverse md:flex-row lg:flex-row items-start gap-2 md:gap-4 lg:gap-4">
              <div>
                <h1
                  class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#867EA8]">
                  Booking ID: XRT14556
                </h1>
                <h2
                  class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
                  Date: 2 Sep 2003
                </h2>
              </div>
              <button
                class="text-[13px] md:text-[15px] lg:text-[15px] text-[#FF4A49] font-medium w-[150px] md:w-[170px] lg:w-[170px] h-[25px] md:h-[30px] lg:h-[30px] border uppercase rounded-lg border-[#867EA8]">
                CANCELED
              </button>
            </div>
            <div class="flex cursor-pointer items-center gap-2">
              <h1
                @click="$router.push({ name: 'RentalOrderDetails' })"
                class="text-[16px] md:text-[24px] lg:text-[24px] font-medium text-[#E42724]">
                DETAILS
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path
                  d="M1.50743 0.794067L10 9.28664L1.50743 17.7792L0 16.2718L6.98514 9.28664L0 2.3015L1.50743 0.794067Z"
                  fill="#E42724" />
              </svg>
            </div>
          </div>
          <div class="mt-[5px]">
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              Hero Cycles - (Non Gear) COLT IBC 26T
            </h1>
            <h1
              class="text-[12px] md:text-[14px] lg:text-[14px] font-bold text-[#36314C]">
              + 2 Accessories
            </h1>
            <div class="flex items-center justify-between">
              <div>
                <h1 class="text-[12px] text-[#959595]">Start Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">14 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">End Date</h1>
                <h2 class="text-[13px] font-medium text-[#000000]">17 Sep 23</h2>
              </div>
              <div>
                <h1 class="text-[12px] text-[#959595]">Duration</h1>
                <h2 class="text-[13px] font-medium text-[#71AE0D]">3 Days</h2>
              </div>
            </div>
          </div>
          <hr class="my-[10px] bg-[#CCC6E9]" />
          <div>
            <div class="flex items-start justify-between">
              <h1 class="text-[16px] font-bold text-[#36314C]">
                Payment Summary
              </h1>
              <div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#36314C] font-medium">
                    TOTAL
                  </h2>
                  <h2
                    class="text-[14px] md:text-[18px] lg:text-[18px] font-bold text-[#605884]">
                    ₹ 6000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#71AE0D] font-medium">
                    PAID
                  </h2>
                  <h2
                    class="text-[12px] md:text-[16px] lg:text-[16px] text-[#71AE0D] font-bold">
                    ₹ 1000
                  </h2>
                </div>
                <div class="flex items-center justify-between gap-9">
                  <h2
                    class="text-[10px] md:text-[14px] lg:text-[14px] text-[#FF4A49] font-medium">
                    REFUNDED
                  </h2>
                  <h2
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#FF4A49] font-bold">
                    ₹ 1000
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </template>
  
<script>
  import axios from "axios";
  import LoadingSpinner2 from "../components/LoadingSpinner2";
  
  export default {
    components: {
      LoadingSpinner2,
    },
    data() {
      return {
        bookings: [],
        brands: [],
        loading: false,
        cycleBrand: null,
        selectedOption: "service",
      };
    },
    methods: {
      formatDateString(dateString) {
        const dateParts = dateString.split(" "); // Split by space
        const datePortion = dateParts.slice(0, 3).join(" "); // Extract the first three parts
        return datePortion;
      },
      getBrandName(companyId) {
        const matchingBrand = this.brands.find((brand) => brand.id === companyId);
        return matchingBrand.name;
      },
      getRentalBookings() {
        this.loading = true;
        axios.get("/api/user/rp/rental-booking").
        then(response => {
          this.loading = false;
          if("bookings"in response.data) {
              this.bookings = response.data['bookings'];
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error)
        })
      }
    },
    async mounted() {
        this.getRentalBookings();
    //   try {
    //     this.isLoading = true;
    //     const response = await axios.get("/api/user/rp/rental-booking");
    //     this.serviceAppointments = response.data.service_appointments.data;
    //     console.log(this.serviceAppointments);
    //     const brandGetReq = await axios.get("api/user/company");
    //     this.brands = brandGetReq.data.companies;
    //   } catch (error) {
    //     console.error("API error:", error);
    //   } finally {
    //     this.isLoading = false;
    //   }
    },
  };
  </script>
  
  <style>
  .text-field_no-border {
    font-weight: 500;
    border: none;
    outline: none;
    padding: 6px;
  }
  .text-field_no-border::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500; /* Change the placeholder color */
  }
  </style>
  