<template>
  <div>
    <div class="bg-white py-[20px]">
      <v-container fluid>
        <!-- testing -->
        <footer className="footer p-1 mx-auto text-base-content">
          <div
            class="flex items-start mx-auto list-none gap-[34px] md:gap-16 lg:gap-16">
            <div>
              <h1 className="text-[17px] md:text-lg lg:text-lg font-bold">
                Offerings
              </h1>
              <li>
                <a
                  @click="$router.push({ name: 'rental-listing' })"
                  className="link link-hover"
                  >Rentals
                </a>
              </li>
              <li>
                <a
                  @click="$router.push({ name: 'ServiceRepair' })"
                  className="link link-hover"
                  >Services</a
                >
              </li>
              <li>
                <a
                  @click="$router.push({ name: 'Accessories' })"
                  className="link link-hover">
                  Accessories</a
                >
              </li>
            </div>
            <div>
              <h1 className="text-[17px] md:text-lg lg:text-lg font-bold">
                Information
              </h1>
              <li>
                <a className="link link-hover">Blog</a>
              </li>
              <li>
                <a className="link link-hover">Events</a>
              </li>
              <li>
                <a className="link link-hover">Careers</a>
              </li>
              <li>
                <a className="link link-hover">Site Map</a>
              </li>
              <li>
                <a className="link link-hover">Corporate Info</a>
              </li>
            </div>
            <div>
              <h1 className="text-[17px] md:text-lg lg:text-lg font-bold">
                Policies
              </h1>
              <li>
                <a
                  @click="$router.push({ name: 'ReturnPolicy' })"
                  className="link link-hover"
                  >Returns</a
                >
              </li>
              <li>
                <a className="link link-hover">Contact Us</a>
              </li>
              <li>
                <a
                  @click="$router.push({ name: 'CancellationPolicy' })"
                  className="link link-hover"
                  >Cancellation</a
                >
              </li>

              <li>
                <a
                  @click="$router.push({ name: 'TermsPolicy' })"
                  className="link link-hover"
                  >Terms Of Use</a
                >
              </li>

              <li>
                <a
                  @click="$router.push({ name: 'PrivacyPolicy' })"
                  className="link link-hover"
                  >Privacy policy</a
                >
              </li>
            </div>
          </div>
          <!-- <div class="flex mx-auto flex-col items-center">
            <span className="text-lg font-bold"
              >EXPERIENCE CYCLEDEPO APP ON MOBILE</span
            >
            <img
              class="w-[200px] cursor-pointer"
              :src="available_on_app_store"
              alt="" />
            <img
              class="w-[200px] cursor-pointer"
              :src="get_it_on_google_play"
              alt="" /> 
            <p class="text-lg font-bold">KEEP IN TOUCH</p>
            <div class="flex justify-center items-center gap-3 lg:ml-3">
              <a
                href="https://www.instagram.com/cycledepo/"
                target="_blank"
                rel="noopener noreferrer"
                ><img
                  class="w-10 cursor-pointer"
                  :src="facebook"
                  alt="facebook"
              /></a>
              <a
                href="https://www.instagram.com/cycledepo/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  class="w-10 cursor-pointer"
                  :src="instagram"
                  alt="instagram" />
              </a>
              <a
                href="https://twitter.com/cycledepo"
                target="_blank"
                rel="noopener noreferrer">
                <svg
                  viewBox="0 0 1200 1227"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="none"
                  class="u01b__icon-home w-10">
                  <path
                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                    fill="#36314C"></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@cycledepo"
                target="_blank"
                rel="noopener noreferrer"
                ><img class="w-10 cursor-pointer" :src="youtube" alt="youtube"
              /></a>
            </div>
          </div> -->
          <div class="flex mx-auto flex-col items-center">
            <span className="font-bold text-black text-3xl"
              >Have Questions ?</span
            >
            <a className="link link-hover text-[#FF4A49] text-2xl"
              >+91-8591642277</a
            >
            <a className="link link-hover">24/7 Customer Support</a>
            <a className="link link-hover font-bold">contact@cycledepo.com </a>
            <br />
            <a className="link link-hover text-lg" style="text-align: center"
              >A-304, 3rd floor, Eastern Business District,<br />
              LBS marg, Bhandup(W),<br />
              Mumbai - 400078</a
            >
          </div>
        </footer>

        <div class="mt-4">Copyright © 2023 Cycledepo. All Rights Reserved.</div>
      </v-container>
    </div>

    <div class="py-4" style="color: #787878; background-color: #eaeaf3">
      <v-container>
        Cycledepo is an online rental solution for your bicycle needs. It caters
        to an online platform for all the bicycle lovers and cycle riders in
        India and provides hassle free bicycle renting experience.
      </v-container>
    </div>
  </div>
</template>
<script>
import available_on_app_store from "@/assets/footer/available_on_app_store.png";
import get_it_on_google_play from "@/assets/footer/get_it_on_google_play.png";
import facebook from "@/assets/footer/facebook.png";
import twitter from "@/assets/footer/twitter.png";
import instagram from "@/assets/footer/instagram.png";
import youtube from "@/assets/footer/youtube.png";

export default {
  name: "NavBar",
  data() {
    return {
      available_on_app_store,
      get_it_on_google_play,
      facebook,
      instagram,

      twitter,
      youtube,
    };
  },
};
</script>
