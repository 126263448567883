<!-- <template>
  <div class="p-4 md:p-8 lg:p-8 bg-[#F4F4F4]">
    <div class="flex items-center justify-between mb-[20px]">
      <h1 class="text-[20px] text-[#36314C]">Bookmark Product</h1>
    </div>
    <v-row v-if="bookmarkedProducts.length > 0">
      <v-col v-for="accessory in bookmarkedProducts" cols="12" md="3">
        <v-card class="card-shop-accessories rounded-xl" elevation="0">
          <div class="cursor-pointer">
            <v-btn
              @click="handleFavoriteClick(accessory.id)"
              class="fav-icon"
              icon
              variant="text">
              <v-icon color="#FF4A49">
                {{
                  isFavorite(accessory.id) ? "mdi-heart" : "mdi-heart-outline"
                }}
              </v-icon>
            </v-btn>
            <span v-if="accessory.badge === 'Bestseller'" class="bestseller-tag"
              >Bestseller</span
            >
            <span v-if="accessory.badge === 'New Arrival'" class="New_Arrival"
              >New Arrival</span
            >

            <v-img
              class="p-6 mx-auto"
              :src="accessory.images[accessory.defaultImageIndex].image"
              aspect-ratio="1"></v-img>
            <div class="flex justify-center items-center gap-2">
              <div
                v-for="(color, index) in accessory.images"
                :key="color.code"
                @click="selectColor(accessory, index)"
                :class="[
                  'cursor-pointer rounded-full ',
                  {
                    'border-[2px] border-black p-[2px]':
                      accessory.defaultImageIndex === index,
                    'border-[1px] border-black':
                      accessory.defaultImageIndex !== index,
                  },
                ]">
                <div
                  :class="[
                    'cursor-pointer rounded-full',
                    {
                      'h-[20px] w-[20px] ':
                        accessory.defaultImageIndex === index,
                      'h-[15px] w-[15px] ':
                        accessory.defaultImageIndex !== index,
                    },
                  ]"
                  :style="{ backgroundColor: color.code }"></div>
              </div>
            </div>
            <v-card-text class="pt-2">
              <div
                class="text-center px-4"
                style="font-size: 16px; height: 42px">
                {{ accessory.name }}
              </div>
              <div class="flex justify-center my-2">
                <v-rating
                  v-model="accessory.rating"
                  color="#EAEAF3"
                  active-color="#F2AB3E"
                  density="compact"
                  full-icon="mdi-star"
                  empty-icon="mdi-star"
                  half-increments
                  readonly />
              </div>
              <div class="flex items-center px-4 gap-2">
                <div
                  class="flex-grow"
                  style="color: #211c37; font-size: 20px; font-weight: 600">
                  ₹{{ accessory.price }}
                </div>
                <v-btn
                  @click="$router.push({ name: 'AccessoriesDetails' })"
                  class="text-white bg-[#36314C]"
                  color="#36314C"
                  rounded
                  elevation="0"
                  >View Details</v-btn
                >
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <p v-else class="text-center font-bold text-[20px] my-10">
      No bookmarked products found!!
    </p>
  </div>
</template>

<script>
import slingBag from "../assets/temp/accessories/936359202231cf7f1f4f12e825dad71d.png";
import bag3 from "../assets/temp/accessories/bag3.png";
import bag2 from "../assets/temp/accessories/bag2.png";
import gloves2 from "../assets/temp/accessories/gloves2.png";
import gloves3 from "../assets/temp/accessories/gloves3.png";
import horn from "../assets/temp/accessories/horn.png";
import seat_cushion from "../assets/temp/accessories/seat_cushion.png";
import twoGloves from "../assets/temp/accessories/Rectangle 37 (6).png";
import Helmet from "../assets/temp/accessories/Helmet.png";
import bottle from "../assets/temp/accessories/bottle.png";
import YellowBottle from "../assets/temp/accessories/YellowBottle.png";
import DigitalLock from "../assets/temp/accessories/DigitalLock.png";
import meter from "../assets/temp/accessories/meter.png";
export default {
  name: "Bookmark",
  data() {
    return {
      shop_accessories: [
        {
          name: "Firefox Sling Bag",
          id: "1",
          badge: "Bestseller",
          images: [
            { code: "#00C2FF", image: bag2 },
            { code: "#E57E1E", image: slingBag },
            { code: "#5FD202", image: bag3 },
          ],
          defaultImageIndex: 1, // default image showing
          rating: 4,
          price: 540,
        },
        {
          name: "Digital Horn for Cycle with Batteries",
          id: "2",
          images: [{ code: "#0A0A0A", image: horn }],
          defaultImageIndex: 0,
          rating: 4.5,
          price: 150,
        },
        {
          name: "Extra Cushion Cycle Seat",
          id: "3",
          badge: "New Arrival",
          images: [{ code: "#0A0A0A", image: seat_cushion }],
          defaultImageIndex: 0,
          rating: 5,
          price: 399,
        },
        {
          name: "1 meter Bicycle Locking System with 2 keys",
          id: "4",
          images: [
            { code: "#818DFF", image: gloves3 },
            { code: "#F843A5", image: twoGloves },
            { code: "#2FD376", image: gloves2 },
          ],
          defaultImageIndex: 1,
          badge: "Bestseller",
          rating: 4,
          price: 740,
        },
        {
          name: "Cycle Helmet",
          id: "5",
          images: [{ code: "#71AE0D", image: Helmet }],
          defaultImageIndex: 0,
          rating: 4,
          price: 299,
        },
        {
          name: "Metallic Red Sipper Bottle ",
          id: "6",
          images: [
            { code: "#36314C", image: bottle },
            { code: "#E42724", image: bottle },
            { code: "#FFCD58", image: bottle },
          ],
          defaultImageIndex: 1,
          badge: "Bestseller",
          rating: 4,
          price: 420,
        },
        {
          name: "1 meter Bicycle Locking System with 2 keys",
          id: "7",
          images: [
            { code: "#564F53", image: YellowBottle },
            { code: "#E89A00", image: YellowBottle },
            { code: "#DED7E4", image: YellowBottle },
          ],
          defaultImageIndex: 1,
          badge: "Bestseller",
          rating: 4,
          price: 740,
        },
        {
          name: "Digital Horn for Cycle with Batteries",
          id: "8",
          images: [{ code: "#9886D0", image: DigitalLock }],
          defaultImageIndex: 0,
          badge: "Bestseller",
          rating: 4,
          price: 699,
        },
        {
          name: "Metallic Red Sipper Bottle ",
          id: "9",
          images: [{ code: "#000000", image: meter }],
          defaultImageIndex: 0,
          badge: "Bestseller",
          rating: 4,
          price: 720,
        },
      ],
    };
  },
  methods: {
    handleFavoriteClick(itemId) {
      this.$store.dispatch("toggleFavorite", itemId);
    },
    isFavorite(itemId) {
      return this.$store.state.favorites.includes(itemId);
    },
    selectColor(item, index) {
      item.defaultImageIndex = index;
    },
  },
  computed: {
    bookmarkedProducts() {
      const favorites = this.$store.state.favorites;

      // Filter products based on bookmarked IDs
      return this.shop_accessories.filter((product) =>
        favorites.includes(product.id)
      );
    },
  },
};
</script> -->

<template>
  <div class="min-h-screen flex flex-col justify-center items-center">
    <img
      src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
      alt="Logo"
      class="mb-8 h-40" />
    <h1
      class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">
      Bookmarks are coming soon
    </h1>
  </div>
</template>
