import axios from "axios";
import store from "./store/index";
import Swal from "sweetalert2";

axios.defaults.baseURL = "https://api.cycledepo.com/";

// axios.defaults.baseURL = "http://cycleonrent.test/";

axios.interceptors.request.use(
  function (config) {
    config.headers.Accept = "application/json";
    if (store.state.token) {
      config.headers.Authorization = `"Bearer ${store.state.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function showMessage(text, type) {
  Swal.fire({
    title: "",
    text: text,
    icon: type
  })
}

function showSuccessMessage(text) {
  showMessage(text, "success")
}

function showErrorMessage(text) {
  showMessage(text, "error")
}

function showWarningMessage() {
  showMessage(text, "warning")
}

axios.interceptors.response.use(function (response) {
  if("success"in response.data && "message" in response.data) {
    if(response.data["success"] == true) {
      showSuccessMessage(response.data["message"]);
    }else if(response.data["success"] == false) {
      showErrorMessage(response.data["message"]);
    }
  }
  return response;
}, function (error) {
  console.error(error);
  if(error?.response?.status == 422 && error?.response?.data?.message) { showErrorMessage(error.response.data.message); }
  if(error?.response?.status == 500) { showErrorMessage("There was some server side error."); }
  return Promise.reject(error);
});