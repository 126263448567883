<template>
  <v-app>
    <NavBar v-if="!hideHeader" />
    <v-main style="background-color: white">
      <router-view v-slot="{ Component }" :key="$route.fullPath">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>
    <Footer v-if="!hideFooter"></Footer>
  </v-app>
</template>
<script>
import NavBar from "./components/NavBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      isSmall: window.innerWidth < 768, // Initialize screen size check
    };
  },
  computed: {
    hideFooter() {
      // Get the current route's meta information
      const currentRoute = this.$route;
      const specificPagePath = "/service-repair";
      const specificPagePath2 = "/SlotPageVue";
      const specificPagePath3 = "/login";
      const specificPagePath4 = "/otp";
      const specificPagePath5 = "/payment-pending";
      const specificPagePath6 = "/payment-failed";
      const specificPagePath7 = "/rental-order-confirm";
      if (currentRoute.path.startsWith("/Success/")) {
        return true; // Hide header on this specific page
      }
      if (
        currentRoute.path === specificPagePath ||
        currentRoute.path === specificPagePath2 ||
        currentRoute.path === specificPagePath3 ||
        currentRoute.path === specificPagePath4 ||
        currentRoute.path === specificPagePath5 ||
        currentRoute.path === specificPagePath6 ||
        currentRoute.path === specificPagePath7
      ) {
        return true; // Hide header on the specific page
      }

      if (this.isSmall && currentRoute.meta && currentRoute.meta.hideFooter) {
        return true; // Hide footer if both conditions are met
      }

      return false;
    },
    hideHeader() {
      // Get the current route's meta information
      const currentRoute = this.$route;
      const specificPagePath = "/login";
      const specificPagePath1 = "/otp";
      const specificPagePath2 = "/payment-pending";
      const specificPagePath3 = "/payment-failed";
      const specificPagePath4 = "/rental-order-confirm";
      if (currentRoute.path.startsWith("/Success/")) {
        return true; // Hide header on this specific page
      }
      if (
        currentRoute.path === specificPagePath ||
        currentRoute.path === specificPagePath1 ||
        currentRoute.path === specificPagePath2 ||
        currentRoute.path === specificPagePath3 ||
        currentRoute.path === specificPagePath4
      ) {
        return true; // Hide header on the specific page
      }
      if (this.isSmall && currentRoute.meta && currentRoute.meta.hideHeader) {
        return true; // Hide footer if the hideHeader flag is set
      }
      return false;
    },
  },
  mounted() {
    // Add an event listener to update screen size when window is resized
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    updateScreenSize() {
      // Update the screen size check when window is resized
      this.isSmall = window.innerWidth < 768; // Adjust the breakpoint as needed
    },
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
