<template>
  <div
    @click="$router.push({ name: 'home' })"
    class="mb-10 cursor-pointer bg-[#36314C] p-4 flex items-center gap-2">
    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
          fill="#F5F5F5" />
      </g>
    </svg>
    <h1 class="font-semibold text-white text-lg lg:text-2xl">Login</h1>
  </div>
  <div class="p-6 mx-auto w-full md:w-1/2 lg:w-1/2">
    <div class="mb-8">
      <h2 class="text-4xl font-bold text-[#211C37]">LOGIN</h2>
    </div>

    <form v-if="!otpSent" @submit.prevent="sendOtp">
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="mobile-number">
          Mobile Number*
        </label>

        <v-text-field
          v-model="mobile_number"
          variant="outlined"
          type="number"
          @input="validateNumber"
          @blur="validateMobileNumber"
          placeholder="Enter your mobile number"
          hide-details></v-text-field>

        <p v-if="!isNumberValid" class="text-red-500 text-sm mt-1">
          Please enter valid Mobile Number.
        </p>

        <p class="text-sm text-gray-600 mt-3">
          OTP will be sent to this number
        </p>
      </div>
      <div class="flex items-center">
        <input class="cursor-pointer" type="checkbox" v-model="termsAgreed" />
        <label class="ml-2 text-gray-700 font-medium" for="terms-and-conditions"
          >I agree to the
          <span
            @click="$router.push({ name: 'TermsPolicy' })"
            class="text-[#FF4A49] cursor-pointer underline">
            terms and conditions
          </span>
        </label>
      </div>
      <p v-if="!termsAgreed" class="text-red-500 text-sm">
        Please agree to Terms & Conditions.
      </p>
      <div class="text-center mt-4">
        <button
          :disabled="!termsAgreed"
          class="bg-red-600 text-white text-lg font-bold w-[85%] md:w-[60%] lg:w-[60%] h-[50px] rounded-lg hover:bg-red-700 disabled:opacity-25">
          Submit
        </button>
      </div>
    </form>
    <form v-else @submit.prevent="login">
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="otp">
          OTP has been sent to {{ mobile_number }}
        </label>
        <v-otp-input
          required
          @blur="validateOTP"
          :maxlength="6"
          v-model="otp"
          type="number"
          variant="underlined"></v-otp-input>
        <p v-if="!isOTPValid" class="text-red-500 text-sm mt-1">
          OTP should be numeric and must be 6 digits.
        </p>
        <p v-if="errorMessage" class="text-red-500 text-sm mt-1">
          {{ errorMessage }}
        </p>
      </div>
      <div class="text-center mt-4">
        <button
          class="bg-red-600 text-white text-lg font-bold w-[85%] md:w-[60%] lg:w-[60%] h-[50px] rounded-lg hover:bg-red-700">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { VOtpInput } from "vuetify/labs/VOtpInput";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import app from "../firebase/firebase.config";

export default {
  name: "Login",
  components: {
    VOtpInput,
  },
  data() {
    return {
      user: null,
      mobile_number: "",

      isNumberValid: true,
      isOTPValid: true,

      otp: "",
      errorMessage: null,

      termsAgreed: true,

      otpSent: false,
    };
  },
  methods: {
    validateNumber() {
      const pattern = /^[6-9]\d{9}$/;
      this.isNumberValid = pattern.test(this.mobile_number);
    },
    validateOTP() {
      const pattern = /^[0-9]{6}$/;
      this.isOTPValid = pattern.test(this.otp);
    },
    async sendOtp() {
      this.validateNumber();

      if (!this.isNumberValid || !this.termsAgreed) return;

      try {
        const response = await axios.post("api/user/send-otp", {
          mobile_number: this.mobile_number,
        });

        this.otpSent = true;

        Swal.fire({
          position: "center",
          icon: "success",
          title: "OTP sent successfully!!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    },

    async login() {
      this.validateOTP();

      if (!this.isOTPValid) return;

      axios
        .post("api/user/login", {
          mobile_number: this.mobile_number,
          otp: this.otp,
        })
        .then((response) => {
          if ("success" in response.data) {
            if (response.data["success"] == true) {
              Promise.all([
                this.$store.dispatch("setToken", response.data.token),
                this.$store.dispatch("setUser", response.data.user),
              ]).then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Login successfully",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.$router.push("/profile");
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: response.data["message"],
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Some error occurred. Please try again later.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.error("Error login:", error.response.data.message);
          this.errorMessage = error.response.data.message;
        });
    },
  },
  computed: {
    validateMobileNumber() {
      if (this.mobile_number.length > 10) {
        this.mobile_number = this.mobile_number.slice(0, 10);
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
