import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Events from "../views/EventsView.vue";
import Blogs from "../views/BlogsView.vue";
import RentalListingView from "../views/Rental.vue";
import ServiceRepair from "../views/ServiceRepair.vue";
import SlotPageVue from "@/components/SlotPage.vue";
import AccessoriesDetails from "../components/AccessoriesDetails.vue";
import AboutView from "../views/AboutView.vue";
import AccessoriesView from "../views/Accessories.vue";
import Profile from "../components/MyProfile.vue";
import Login from "../components/Login.vue";
import OTP from "../components/OTP.vue";
import AddNewAddress from "../components/AddNewAddress.vue";
import Payment from "../components/Payment.vue";
import AddressUpdate from "../components/AddressUpdate.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import CancellationPolicy from "../components/CancellationPolicy.vue";
import ReturnPolicy from "../components/ReturnPolicy.vue";
import TermsPolicy from "../components/TermsPolicy.vue";
import store from "../store/index";
import OrderHistory from "../components/OrderHistory.vue";
import Review from "../components/Review.vue";
import Success from "../components/Success.vue";
import OrderDetails from "../components/OrderDetails.vue";
import Addresses from "../components/Addresses.vue";
import PaymentPending from "../components/PaymentPending.vue";
import PaymentFailed from "../components/PaymentFailed.vue";
import RentalDetails from "../components/RentalDetails.vue";
import BookingDetails from "../components/RentalBookingDetails.vue";
import RentalOrderDetails from "../components/RentalOrderDetails.vue";
import RentalBookingTransactions from "../components/RentalBookingTransactions.vue";
import RentalOrderConfirm from "../components/RentalOrderConfirm.vue";
import BlogDetails from "../components/BlogDetails.vue";
import EventDetails from "../components/EventDetails.vue";
import CartPage from "../components/CartPage.vue";
import Bookmark from "../components/Bookmark.vue";
import TransactionStatus from "../components/TransactionStatus.vue";

import RentalHistory from "./../views/RentalHistory.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/rental",
    name: "rental-listing",
    component: RentalListingView,
    meta: {
      title: "Rentals",
    },
  },
  {
    path: "/rental-history",
    name: "rental-history",
    component: RentalHistory,
    meta: {
      title: "Rental History",
    },
  },
  
  {
    path: "/slots",
    name: "SlotPageVue",
    component: SlotPageVue,
    meta: {
      title: "Slots",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
    meta: {
      title: "Cart",
      requiresAuth: true,
    },
  },
  {
    path: "/bookmark",
    name: "Bookmark",
    component: Bookmark,
    meta: {
      title: "Bookmark",
      requiresAuth: true,
    },
  },
  {
    path: "/rental-order-confirm",
    name: "RentalOrderConfirm",
    component: RentalOrderConfirm,
    meta: {
      title: "Rental Order Confirm",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/success/:id",
    name: "Success",
    component: Success,
    meta: {
      title: "Success",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/payment-pending",
    name: "PaymentPending",
    component: PaymentPending,
    meta: {
      title: "Payment Pending",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/payment-failed",
    name: "PaymentFailed",
    component: PaymentFailed,
    meta: {
      title: "Payment Failed",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/rental-order-details/:id",
    name: "RentalOrderDetails",
    component: RentalOrderDetails,
    meta: {
      title: "Rental Order Details",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/rental-order-details/:id/transactions",
    name: "RentalBookingTransactions",
    component: RentalBookingTransactions,
    meta: {
      title: "Rental Order Transactions",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/order-details/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      title: "Order Details",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/rental-details/:id",
    name: "RentalDetails",
    component: RentalDetails,
    meta: { title: "Rental Details", hideFooter: true },
  },
  {
    path: "/service-repair",
    name: "ServiceRepair",
    component: ServiceRepair,
    meta: { title: "Service Repair", requiresAuth: true, hideFooter: true },
  },
  {
    path: "/accessories",
    name: "Accessories",
    component: AccessoriesView,
    meta: { title: "Accessories" },
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: { title: "Events" },
  },
  {
    path: "/blog",
    name: "Blogs",
    component: Blogs,
    meta: { title: "Blog" },
  },
  {
    path: "/blog-details",
    name: "BlogDetails",
    component: BlogDetails,
    meta: { title: "Blog Details" },
  },
  {
    path: "/event-details",
    name: "EventDetails",
    component: EventDetails,
    meta: { title: "Event Details" },
  },
  {
    path: "/accessories-details",
    name: "AccessoriesDetails",
    component: AccessoriesDetails,
    meta: { title: "Accessories details" },
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
    meta: { title: "About" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true, title: "Profile" },
  },
  {
    path: "/addresses",
    name: "Addresses",
    component: Addresses,
    meta: {
      title: "Addresses",
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/order-history",
    name: "OrderHistory",
    component: OrderHistory,
    meta: {
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
      title: "Order history",
    },
  },
  {
    path: "/add-new-address",
    name: "AddNewAddress",
    component: AddNewAddress,
    meta: {
      requiresAuth: true,
      title: "Add new address",
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: { title: "Payment", requiresAuth: true },
  },
  {
    path: "/review",
    name: "Review",
    component: Review,
    meta: {
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
      title: "Review",
    },
  },
  {
    path: "/booking-details",
    name: "BookingDetails",
    component: BookingDetails,
    meta: {
      requiresAuth: true,
      hideFooter: true,
      hideHeader: true,
      title: "Booking Details",
    },
  },
  {
    path: "/address-update/:id",
    name: "AddressUpdate",
    component: AddressUpdate,
    meta: { title: "Address update", requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Login", hideFooter: true, hideHeader: true },
  },
  {
    path: "/otp",
    name: "OTP",
    component: OTP,
    meta: { title: "OTP", hideFooter: true, hideHeader: true },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { title: "Privacy policy" },
  },
  {
    path: "/cancellation-policy",
    name: "CancellationPolicy",
    component: CancellationPolicy,
    meta: { title: "Cancellation policy" },
  },
  {
    path: "/return-policy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
    meta: { title: "Return policy" },
  },
  {
    path: "/terms-policy",
    name: "TermsPolicy",
    component: TermsPolicy,
    meta: { title: "Terms policy" },
  },
  {
    path: "/transaction-status/:id",
    name: "transaction_status",
    component: TransactionStatus,
    meta: { title: "Transaction status" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) { return savedPosition; }
    if (to.hash) { 
      return { 
        el: to.hash,
        top: 50,
        behavior: "smooth"
      };
    }
    else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !store.getters["isAuthenticated"]) {
    // If user is not authenticated and trying to access a protected route,
    // save the target route in a cookie or localStorage
    localStorage.setItem("redirectRoute", to.fullPath);

    next("/login"); // Redirect to login page
  } else if (requiresAuth && store.getters["isAuthenticated"]) {
    // If user is authenticated and trying to access a protected route,
    // redirect them to the originally requested route (if stored)
    const redirectRoute = localStorage.getItem("redirectRoute");
    if (redirectRoute) {
      localStorage.removeItem("redirectRoute");
      next(redirectRoute);
    } else {
      next(); // Continue navigation
    }
  } else {
    next(); // Continue navigation for public routes
  }
});

export default router;
