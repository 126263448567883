<template>
  <div class="my-10">
    <h1 class="text-4xl md:text-7xl lg:text-7xl font-medium text-center">
      RETURN/REFUND POLICY
    </h1>
    <div class="p-8 md:p-24 lg:p-24">
      <p class="text-lg break-words">
        Our policy lasts 30 days. If 30 days have gone by since your purchase,
        unfortunately, we can’t offer you a refund or exchange. To be eligible
        for a return, your item must be unused and in the same condition that
        you received it. It must also be in the original packaging. Several
        types of goods are exempt from being returned. Perishable goods such as
        food, flowers, newspapers, or magazines cannot be returned. We also do
        not accept products that are intimate or sanitary goods, hazardous
        materials, or flammable liquids or gases.
        <br />
        <br />
        Additional non-returnable items:
      </p>
      <ul class="ml-4 mt-4">
        <li><span class="font-extrabold">•</span> Gift cards</li>
        <li>
          <span class="font-extrabold">•</span> Downloadable software products
        </li>
        <li>
          <span class="font-extrabold">•</span> Some health and personal care
          items
        </li>
      </ul>
      <p class="text-lg mt-4">
        To complete your return, we require a receipt or proof of purchase.
        Please do not send your purchase back to the manufacturer.
      </p>
      <p class="text-lg mt-2">
        There are certain situations where only partial refunds are granted: (if
        applicable)
      </p>
      <p class="text-lg mt-2">Book with obvious signs of use</p>
      <p class="text-lg mt-2">
        CD, DVD, VHS tape, software, video game, cassette tape, or vinyl
      </p>
      <p class="text-lg mt-2">record that has been opened.</p>
      <p class="text-lg mt-2">
        Any item not in its original condition is damaged or missing parts for
        reasons not due to our error.
      </p>
      <p class="text-lg mt-2">
        Any item that is returned more than 30 days after delivery
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">
        Refunds (if applicable)
      </h1>
      <p class="text-lg break-words mt-2">
        Once your return is received and inspected, we will send you an email to
        notify you that we have received your returned item. We will also notify
        you of the approval or rejection of your refund. If you are approved,
        then your refund will be processed, and a credit will automatically be
        applied to your credit card or original method of payment, within a
        certain amount of days.
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">
        Late or missing refunds (if applicable)
      </h1>
      <p class="text-lg mt-2 break-words">
        If you haven’t received a refund yet, first check your bank account
        again.
      </p>
      <p class="text-lg mt-2 break-words">
        Then contact your credit card company, it may take some time before your
        refund is officially posted.
      </p>
      <p class="text-lg mt-2 break-words">
        Next, contact your bank. There is often some processing time before a
        refund is posted.
        <span class="underline font-semibold">
          If you’ve done all of this and you still have not received your refund
          yet, please contact us at contact@cycledepo.com
        </span>
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">
        Sale items (if applicable)
      </h1>
      <p class="text-lg mt-1 break-words">
        Only regular-priced items may be refunded, unfortunately, sale items
        cannot be refunded.
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">
        Exchanges (if applicable)
      </h1>
      <p class="text-lg mt-1 break-words">
        We only replace items if they are defective or damaged. If you need to
        exchange it for the same item, send us an email at
        <span class="font-semibold underline">contact@cycledepo.com</span>
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">Gifts</h1>
      <p class="text-lg mt-1 break-words">
        If the item was marked as a gift when purchased and shipped directly to
        you, you’ll receive a gift credit for the value of your return. Once the
        returned item is received, a gift certificate will be mailed to you.
      </p>
      <p class="text-lg mt-1 break-words">
        If the item wasn’t marked as a gift when purchased, or the gift giver
        had the order shipped to themselves to give to you later, we will send a
        refund to the gift giver and he will find out about your return.
      </p>
      <h1 class="text-2xl md:text-3xl mt-4 font-medium">Shipping</h1>
      <p class="text-lg mt-1 break-words">
        To return your product, you should mail your product A-304, 3rd floor,
        Eastern Business District, LBS Marg, Bhandup(W),Mumbai – 400078
      </p>
      <p class="text-lg mt-2 break-words">
        You will be responsible for paying for your own shipping costs for
        returning your item. Shipping costs are non-refundable. If you receive a
        refund, the cost of return shipping will be deducted from your refund.
      </p>
      <p class="text-lg mt-2 break-words">product to reach you may vary.</p>
      <p class="text-lg mt-2 break-words">
        If you are shipping an item over INR 500, you should consider using a
        trackable shipping service or purchasing shipping insurance. We don’t
        guarantee that we will receive your returned item.
      </p>
    </div>
  </div>
</template>
