<template>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div
      v-else-if="transaction"
      className="flex flex-col p-4 justify-center items-center h-[calc(100vh-68px)]">
      
      <template v-if="transaction.status == 'PAYMENT_SUCCESS'">
        <img :src="successLogo" alt="" />
        <h1 class="text-[24px] font-medium text-[#36314C] mb-5">Order Confirmed !</h1>
      </template>
      <template v-else-if="transaction.status == 'PAYMENT_PENDING' || transaction.status == null || transaction.status == ''">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="67"
            viewBox="0 0 68 67"
            fill="none">
            <path
                d="M30.6 43.55H37.4V50.25H30.6V43.55ZM30.6 16.75H37.4V36.85H30.6V16.75ZM34 0C15.198 0 0 15.075 0 33.5C0 42.3848 3.58213 50.9056 9.95837 57.1881C13.1156 60.2988 16.8637 62.7664 20.9888 64.45C25.1138 66.1335 29.5351 67 34 67C43.0174 67 51.6654 63.4705 58.0416 57.1881C64.4179 50.9056 68 42.3848 68 33.5C68 29.1007 67.1206 24.7445 65.4119 20.6801C63.7033 16.6157 61.1988 12.9227 58.0416 9.81192C54.8844 6.70116 51.1363 4.23357 47.0112 2.55004C42.8862 0.866503 38.4649 0 34 0ZM34 60.3C26.7861 60.3 19.8677 57.4764 14.7667 52.4505C9.66571 47.4245 6.8 40.6078 6.8 33.5C6.8 26.3922 9.66571 19.5755 14.7667 14.5495C19.8677 9.52356 26.7861 6.7 34 6.7C41.2139 6.7 48.1323 9.52356 53.2333 14.5495C58.3343 19.5755 61.2 26.3922 61.2 33.5C61.2 40.6078 58.3343 47.4245 53.2333 52.4505C48.1323 57.4764 41.2139 60.3 34 60.3Z"
                fill="#FF4A49" />
        </svg>
        <h1 class="text-[24px] font-medium text-[#36314C] mt-5">
            Payment Confirmation Pending
        </h1>
        <h1 class="text-[14px] text-center mt-[10px] text-[#36314C] mb-5">
            We can take up to 20 minutes for Payment Confirmation. In case not able to
            complete the payment; you can try again.
        </h1>
      </template>
      <template v-else>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none">
            <path
                d="M79.5837 20.4167C63.3337 4.16675 36.667 4.16675 20.417 20.4167C4.16699 36.6667 4.16699 63.3334 20.417 79.5834C36.667 95.8334 62.917 95.8334 79.167 79.5834C95.417 63.3334 95.8337 36.6667 79.5837 20.4167ZM61.667 67.5001L50.0003 55.8334L38.3337 67.5001L32.5003 61.6667L44.167 50.0001L32.5003 38.3334L38.3337 32.5001L50.0003 44.1667L61.667 32.5001L67.5003 38.3334L55.8337 50.0001L67.5003 61.6667L61.667 67.5001Z"
                fill="#FF4A49" />
        </svg>
        <h1 class="text-[24px] font-medium text-[#36314C] mt-5">Payment Failed</h1>
        <h1 class="text-[14px] text-center mt-[10px] text-[#36314C] mb-5">
            We cant process your payment. <br/>
            Check the error and try again.
        </h1>
      </template>

      <h1 class="text-[16px] font-bold text-[#36314C]">
        Service ID: {{ transaction.service_appointment.uid }}
      </h1>
      <h1 class="text-[16px] my-2 text-[#36314C]">
        {{ transaction.service_appointment.service_plan.name }} ()
      </h1>
      <h1 class="text-[12px] text-[#36314C]">
        {{
            $dayjs(transaction.service_appointment.date, 'YYYY-MM-DD').format('ddd, MMM DD, YYYY')
        }}
        {{
            $dayjs(transaction.service_appointment.time, 'HH:mm:ss').format('h:mm A')
        }}
      </h1>
      <button
        @click="ok"
        class="bg-[#FF4A49] mt-4 font-medium text-[24px] text-white rounded-lg w-[142px] h-[45px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
        {{ transaction.status == 'PAYMENT_SUCCESS' || transaction.status == 'PAYMENT_PENDING' ? 'ok' : 'TRY AGAIN' }}
      </button>
    </div>
  </template>
  
  <script>
  
  import successLogo from "../assets/HomeView/successLogo.png";
  import axios from "axios";
  import LoadingSpinner from "../components/LoadingSpinner";
  import Swal from "sweetalert2";

  export default {
    name: "TransactionStatus",
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        successLogo,
        isLoading: false,
        transaction: null,
      };
    },
    methods: {
      ok() {
        this.$store.dispatch("setSearchValue", {
          cycleOption: "",
          cyclePin: "",
          companyId: "",
          cycleModel: "",
          cycleBrand: "",
        });
        this.$store.dispatch("setPackageData", null);
        this.$store.dispatch("setBookingAddress", null);
        if(this.transaction.status == 'PAYMENT_SUCCESS' || this.transaction.status == 'PAYMENT_PENDING') {
            this.$router.push({ name: "OrderDetails", params: {
                id: this.transaction.service_appointment.uid
            }});
        }else{
            this.retryPayment();
        }
      },
      retryPayment() {
        this.isLoading = true;
        axios.post(`api/user/service-appointment/initiate-payment/${ this.transaction.service_appointment.uid }`, {
            price: this.transaction.price,
            source: 'TransactionStatus'
        }).then(response => {
            console.log("Response", response)
            this.isLoading = false;
            if("succcess" in response.data && response.data["succcess"] == true) {
                window.location.href = response.data["url"];
            }else{
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Some error occurred. Please try again later.",
                    timer: 2500,
                });
            }
        }).catch(error => {

            console.log(error)
            this.isLoading = false;
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Some error occurred. Please try again later.",
                timer: 2500,
            });
        });
      }
    },
    async mounted() {
      try {
        this.isLoading = true;
  
        const response = await axios.get(
          `api/user/transaction/${this.$route.params.id}`
        );
        this.transaction = response.data.transaction;
        console.log(response.data);
      } catch (error) {
        console.error("API error:", error);
      } finally {
        this.isLoading = false;
      }
    },
  };
  </script>
  