<template>
    <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center'  }">
        <v-progress-circular indeterminate :size="size" :width="width" :color="color"></v-progress-circular>
    </div>
</template>
<script> 
export default {
    name: 'LoadingSpinner2',
    props: {
        size: {
            type: Number,
            default: 70
        },
        width: {
            type: Number,
            default: 7
        },
        color: {
            type: String,
            default: "#6d3186"
        },
    }
}
</script>