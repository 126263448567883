<template>
  <div
    className="flex flex-col p-2 justify-center items-center h-[calc(100vh-68px)]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="67"
      viewBox="0 0 68 67"
      fill="none">
      <path
        d="M30.6 43.55H37.4V50.25H30.6V43.55ZM30.6 16.75H37.4V36.85H30.6V16.75ZM34 0C15.198 0 0 15.075 0 33.5C0 42.3848 3.58213 50.9056 9.95837 57.1881C13.1156 60.2988 16.8637 62.7664 20.9888 64.45C25.1138 66.1335 29.5351 67 34 67C43.0174 67 51.6654 63.4705 58.0416 57.1881C64.4179 50.9056 68 42.3848 68 33.5C68 29.1007 67.1206 24.7445 65.4119 20.6801C63.7033 16.6157 61.1988 12.9227 58.0416 9.81192C54.8844 6.70116 51.1363 4.23357 47.0112 2.55004C42.8862 0.866503 38.4649 0 34 0ZM34 60.3C26.7861 60.3 19.8677 57.4764 14.7667 52.4505C9.66571 47.4245 6.8 40.6078 6.8 33.5C6.8 26.3922 9.66571 19.5755 14.7667 14.5495C19.8677 9.52356 26.7861 6.7 34 6.7C41.2139 6.7 48.1323 9.52356 53.2333 14.5495C58.3343 19.5755 61.2 26.3922 61.2 33.5C61.2 40.6078 58.3343 47.4245 53.2333 52.4505C48.1323 57.4764 41.2139 60.3 34 60.3Z"
        fill="#FF4A49" />
    </svg>
    <h1 class="text-[24px] font-medium text-[#36314C] mt-5">
      Payment Confirmation Pending
    </h1>
    <h1 class="text-[14px] text-center mt-[10px] text-[#36314C]">
      We can take up to 20 minutes for Payment Confirmation. In case not able to
      complete the payment; you can try again.
    </h1>
    <h1 class="text-[14px] my-[18px] text-[#FF4A49]">Connectivity issue</h1>
    <h1 class="text-[16px] text-[#36314C]">Standard Service (Non Gear)</h1>
    <h1 class="text-[12px] mt-[10px] text-[#36314C]">
      Mon, 4 Sept 2023, 7.30 PM
    </h1>
    <button
      class="bg-[#FF4A49] mt-[40px] font-medium text-[24px] text-white rounded-lg w-[175px] h-[45px] hover:bg-[#fa3b3b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF4A49]">
      OK
    </button>
  </div>
</template>
