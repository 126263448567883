let currencyFormat = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 2, 
    minimumFractionDigits: 0
});
  
let currency = function(value) {
    return currencyFormat.format(value)
}

let rentalPricing = (days, data, getTotal = false) => {
    if(days > 30) {
        return (data?.monthly_pricing ?? 0) * (getTotal ? days : 0);
    }else{
        return (data?.weekly_pricing ?? 0) * (getTotal ? days : 0);
    }
}

let stringifyAddress = (value) => {
    return `${ value.address }. ${ value.city } - ${ value.pincode }. ${ value.state }.`;
}

export { currency, rentalPricing, stringifyAddress };