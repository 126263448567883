<template>
  
    <LoadingSpinner2 v-if="loading" :style="{ minHeight: '500px' }"/>
  
    <div v-if="rental_booking">
      <div class="bg-[#F4F4F4] p-0 md:p-16 lg:p-16">
        <div class="flex items-center justify-between p-4">
          <div>
            <h1
              class="text-[16px] md:text-[20px] lg:text-[20px] font-bold text-[#36314C]">
              Booking ID: {{ rental_booking.id }}
            </h1>
            <h2 class="text-[12px] md:text-[14px] lg:text-[14px] text-[#867EA8]">
              Booked on : {{ $dayjs(rental_booking.created_at).format('DD MMM YYYY, h:mm A')  }}
            </h2>
          </div>
          <h1 class="text-[13px] cursor-pointer md:text-[19px] lg:text-[19px] font-semibold text-[#FF4A49]">Report Issue</h1>
        </div>
  
        
        <div class="bg-white p-4">
            <v-table>
                <thead>
                    <tr>
                      <th class="text-left">ID</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Amount</th>
                      <th class="text-left">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in transactions" :key="item.id">
                        <td>{{ item.transaction_id }}</td>
                        <td>
                            <div v-if="item.status">{{ item.status }}</div>
                            <i v-else>No status yet</i>
                        </td>
                        <td>₹ {{ item.price }}</td>
                        <td>
                          {{ $dayjs(item.created_at).format('DD MMM YYYY, h:mm A') }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import LoadingSpinner2 from "./../components/LoadingSpinner2.vue";
  export default {
    components: {
      LoadingSpinner2
    },
    data() {
      return {
        loading: false,
        rental_booking: null,
        transactions: null,
      };
    },
    methods: {
      getRentalBookingDetails() {
        this.loading = true;
        axios.get(`api/user/rp/rental-booking/${ this.$route.params.id }/transactions`)
        .then(response => {
          this.loading = false;
          console.log(response.data)
          if("rental_booking" in  response.data) {
            this.rental_booking = response.data["rental_booking"];
            this.transactions = response.data["transactions"];
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error)
        })
      }
    },
    mounted(){
      this.getRentalBookingDetails();
    }
  };
  </script>
  